import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { ProdutoEntity } from '../../entities/produto.entity';
import { SolicitacaoEstoquePessoaDivisaoEntity } from '../../entities/solicitacao-estoque-pessoa-divisao.entity';
import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { ProdutoService } from '../produto.service';

@Component({
  selector: 'app-produto-solicitacao-estoque',
  templateUrl: './produto-solicitacao-estoque.component.html',
  styleUrls: ['./produto-solicitacao-estoque.component.css']
})
export class ProdutoSolicitacaoEstoqueComponent extends ComponentBase implements OnInit {
  quantidade: number;
  observacao: string;
  modal: NgbModalRef;
  produtoSelecionado: ProdutoEntity

  @ViewChild("modalSolicitarProduto", { static: true }) modalSolicitarProduto: any;

  constructor(
    private produtoService: ProdutoService,
    protected alert: AlertService,
    protected modalService: NgbModal,
  ) {
    super();
  }

  ngOnInit() {

  }

  onAddRemove(produto: ProdutoEntity) {
    produto.selecionado = false;
    this.produtoSelecionado = produto;
    this.modal = this.modalService.open(this.modalSolicitarProduto, { ariaLabelledBy: 'modal-dest', windowClass: "fichaModal", size: 'sm' });
    this.quantidade = 1
    this.observacao = "";
  }

  salvar() {
    if (!this.quantidade || this.quantidade <= 0) {
      this.alert.warning("Informe a quantidade do produto");
      return;
    }

    this.alert.confirm("Deseja solicitar o produto?").then((result) => {
      if (result.value) {
        const solicitacao: SolicitacaoEstoquePessoaDivisaoEntity = new SolicitacaoEstoquePessoaDivisaoEntity();
        solicitacao.quantidade = this.quantidade;
        solicitacao.idProduto = this.produtoSelecionado.idInterno;
        solicitacao.idCliente = this.produtoSelecionado.idCliente;
        solicitacao.idPessoaDivisao = this.produtoSelecionado.idDivisao;
        solicitacao.observacao = this.observacao;

        this.loading2<void>(() => this.produtoService.inserirSolicitacaoEstoquePessoaDivisao(solicitacao))
          .pipe(take(1))
          .subscribe(() => {
            this.alert.success("Solicitação realizada com sucesso!");
            this.modal.close();
          });
      }
    });
  }
}
