import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { CategoriaEntity } from '../../../entities/catetoria.entity';
import { RelatorioFiltroEntity } from '../../../entities/relatorio-filtro.entity';
import { RelatorioEntity } from '../../../entities/relatorio.entity';
import { AlertService } from '../../../shared/alert.service';
import { ComponentBase } from '../../../shared/component-base';
import { RelatorioCategoriaService } from '../../categoria/relatorio-categoria.service';
import { RelatorioService } from '../../relatorios.service';
import { RecursoEntity } from '../../../entities/recurso.entity';

@Component({
  selector: 'app-configurar-relatorio-form',
  templateUrl: './configurar-relatorio-form.component.html',
  styleUrls: ['./configurar-relatorio-form.component.css']
})
export class ConfigurarRelatorioFormComponent extends ComponentBase implements OnInit {
  filtros: RelatorioFiltroEntity[] = [];
  categorias: CategoriaEntity[] = [];
  recursos: RecursoEntity[] = [];

  tiposCampo = [
    { id: "Data", text: "Data" },
    { id: "Inteiro", text: "Numérico" },
    { id: "Texto", text: "Texto" },
    { id: "Decimal", text: "Valor" },
  ];

  @Input() relatorio: RelatorioEntity = new RelatorioEntity();
  @Output() onCancelar: EventEmitter<void> = new EventEmitter();
  @Output() onSalvar: EventEmitter<void> = new EventEmitter();

  constructor(
    private categoriaService: RelatorioCategoriaService,
    private relatorioService: RelatorioService,
    protected alert: AlertService
  ) {
    super();
  }

  ngOnInit() {
    this.loading2<CategoriaEntity[]>(() => this.categoriaService.listarCategorias())
      .pipe(take(1))
      .subscribe(resp => {
        this.categorias = resp;
      });

    this.loading2<RecursoEntity[]>(() => this.relatorioService.listarRecursosRelatorio())
      .pipe(take(1))
      .subscribe(resp => {
        this.recursos = resp;
      });
  }

  capturarFiltros() {
    const regex = /(?:^|\s)(?:@)([a-zA-Z\d]+)/gm;
    const oldFiltros = this.filtros;
    this.filtros = [];
    let match;

    while ((match = regex.exec(this.relatorio.queryConsulta))) {
      let filtro: RelatorioFiltroEntity = oldFiltros.find(x => x.nomeCampo === '@' + match[1]);

      if (!filtro) {
        filtro = new RelatorioFiltroEntity();
        filtro.codigoRelatorio = this.relatorio.codigoRelatorio;
        filtro.nomeCampo = "@" + match[1];
        filtro.tipoCampo = "Texto";
        filtro.ordem = this.filtros.length + 1;
        filtro.labelCampo = "";
      }
      this.filtros.push(filtro);

    }
  }

  pesquisar() {
    let filtroValor = [];
    for (let x = 0; x < this.filtros.length; x++)
      filtroValor.push({ nomeCampo: this.filtros[x].nomeCampo, valorCampo: this.filtros[x].valor });

    const entity = {
      filtros: filtroValor
    };

    this.loading2<any>(() => this.relatorioService.consultarDadosRelatorio(this.relatorio.codigoRelatorio, filtroValor))
      .pipe(take(1))
      .subscribe(resp => {
        if (resp.length === 0) {
          this.alert.success("Sua query está OK, mas nenhum registro foi localizado.");
          return;
        }

        this.alert.success("Sua query está OK.");
      });
  }

  salvar() {
    if (this.validarCampos()) {
      if (this.relatorio.codigoRelatorio && this.relatorio.codigoRelatorio > 0)
        this.alterarRelatorio();
      else
        this.inserirRelatorio();
    }
  }

  inserirRelatorio() {
    this.relatorio.filtros = this.filtros;
    this.loading2<void>(() => this.relatorioService.inserirRelatorio(this.relatorio))
      .pipe(take(1))
      .subscribe(() => {
        this.alert.success("Relatório inserido com sucesso.")
          .then(() => {
            this.onSalvar.emit();
          });
      });
  }

  alterarRelatorio() {
    this.relatorio.filtros = this.filtros;
    this.loading2<void>(() => this.relatorioService.alterarRelatorio(this.relatorio))
      .pipe(take(1))
      .subscribe(() => {
        this.alert.success("Relatório alterado com sucesso.")
          .then(() => {
            this.onSalvar.emit();
          });
      });
  }

  validarCampos(): boolean {
    if (!this.relatorio.nomeRelatorio) {
      this.alert.warning("Informe o nome do relatório.");
      return false;
    }

    if (!this.relatorio.codigoCategoria) {
      this.alert.warning("Informe a categoria do relatório.");
      return false;
    }

    if (!this.relatorio.queryConsulta) {
      this.alert.warning("Informe a query para consulta.");
      return false;
    }

    return this.validarFiltros();
  }

  validarFiltros(): boolean {
    for (let x = 0; x < this.filtros.length; x++) {
      if (!this.filtros[x].labelCampo) {
        this.alert.warning("Informe o label de todos os filtros.");
        return false;
      }

      if (!this.filtros[x].tipoCampo) {
        this.alert.warning("Informe o tipo de campo de todos os filtros.");
        return false;
      }
    }

    return true;
  }

  cancelar() {
    this.onCancelar.emit();
  }
}
