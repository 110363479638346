import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { CidadeEntity } from '../../entities/CidadeEntity';
import { ConsultarPessoaRequestEntity } from '../../entities/Requests/consultar-pessoa-request.entity';
import { PessoaEntity } from '../../entities/pessoa.entity';
import { UFEntity } from '../../entities/uf.entity';
import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { DestinatarioService } from '../destinatario.service';


@Component({
  selector: 'app-filtro-destinatario',
  templateUrl: './filtro-destinatario.component.html',
  styleUrls: ['./filtro-destinatario.component.css']
})
export class FiltroDestinatarioComponent extends ComponentBase implements OnInit {
  uf$: Observable<UFEntity[]>;
  ufs: UFEntity[];
  cidades: CidadeEntity[];
  pessoa: ConsultarPessoaRequestEntity = new ConsultarPessoaRequestEntity();
  loadingCidades: boolean = false;

  @Output() onFilterPessoas: EventEmitter<PessoaEntity[]> = new EventEmitter();

  constructor(
    private destinatarioService: DestinatarioService,
    public session: SessionService,
    protected alert: AlertService
  ) { super(); }

  ngOnInit() {
    super.ngOnInit();
    let empresas = null;
    if (this.session.empresa)
      empresas = this.session.empresa.idCliente;
    else
      empresas = this.session.getIdsEmpresa();

    this.uf$ = this.destinatarioService.listarUFs(empresas);
    this.loadingGestores<any[]>(this.destinatarioService, "listarCidades", [this.session.usuario.idCliente])
      .pipe(take(1))
      .subscribe(response => {
        this.cidades = [];

        for (var x = 0; x < response.length; x++)
          for (var y = 0; y < response[x].cidades.length; y++)
            this.cidades.push(response[x].cidades[y]);

      });
  }

  changeUF(idEstado: number) {
    this.loadingCidades = true;
    let empresas = null;
    if (this.session.empresa)
      empresas = this.session.empresa.idCliente;
    else
      empresas = 0;
    if (!idEstado) {
      this.call<any[]>(this.destinatarioService, "listarCidades", [this.session.usuario.idCliente])
        .pipe(take(1))
        .subscribe(response => {
          this.cidades = [];
          for (var x = 0; x < response.length; x++)
            for (var y = 0; y < response[x].cidades.length; y++)
              this.cidades.push(response[x].cidades[y]);

          this.loadingCidades = false;
        });
    } else {
      this.call<any[]>(this.destinatarioService, "consultarCidades", [this.session.usuario.idCliente,
        idEstado])
        .pipe(take(1))
        .subscribe(response => {
          this.cidades = response;
          this.loadingCidades = false;
        });
    }
  }

  pesquisarPessoa(btn: HTMLButtonElement) {

    let empresas = null;
    if (this.session.empresa)
      empresas = this.session.empresa.idCliente.toString();
    else
      empresas = this.session.getIdsEmpresa();

    // if (this.pessoa.nome && this.pessoa.nome.length < 4) {
    //   if (this.possuiAcessoRecurso(76))
    //     this.alert.warning("O código SAP deve ter no mínimo 4 caracteres.");
    //   else
    //     this.alert.warning("O nome deve ter no mínimo 4 caracteres.");
    //   return;
    // }

    this.pessoa.empresas = empresas;

    this.loading2<PessoaEntity[]>(() => this.session.usuario.acessoNovo ? this.destinatarioService.filtrarPessoa(this.pessoa) : this.destinatarioService.consultarPessoa(this.pessoa))
      .pipe(take(1))
      .subscribe(response => {
        if (response.length === 0) {
          this.alert.warning("Nenhum destinatário foi localizado com base nos parâmetros informados.");
        }
        console.info(response);
        this.onFilterPessoas.emit(response);
      });
  }

  acessoSAP(): boolean {
    return this.possuiAcessoRecurso(76) && this.exibirFiltro();
  }
}
