import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from "rxjs";

import { ServiceBase } from "../shared/service-base";
import { RelatorioRecebimentosRequestEntity } from '../entities/Requests/relatorio-recebimentos-request.entity';
import { RelatorioRecebimentosResponseEntity } from '../entities/Responses/relatorio-recebimentos-response.entity';
import { RelatorioEnvioRequestEntity } from '../entities/Requests/relatorio-envio-request.entity';
import { RelatorioEnvioResponseEntity } from '../entities/Responses/relatorio-envio-response.entity';
import { RelatorioCategoriaEntity } from '../entities/relatorio-categoria.entity';
import { RelatorioFiltroEntity } from '../entities/relatorio-filtro.entity';
import { RelatorioEntity } from '../entities/relatorio.entity';
import { environment } from '../../environments/environment';
import { RecursoEntity } from '../entities/recurso.entity';


@Injectable({
  providedIn: 'root'
})
export class RelatorioService extends ServiceBase {

  constructor(
    private http: HttpClient
  ) { super(); }

  relatorioRecebimentos(request: RelatorioRecebimentosRequestEntity): Observable<RelatorioRecebimentosResponseEntity[]> {
    return this.http.post<RelatorioRecebimentosResponseEntity[]>(`${environment.baseUrl}api/relatorio/Recebimentos`, request);
  }

  relatorioEnvios(request: RelatorioEnvioRequestEntity): Observable<RelatorioEnvioResponseEntity[]> {
    return this.http.post<RelatorioEnvioResponseEntity[]>(`${environment.baseUrl}api/relatorio/envios`, request);
  }

  listarRelatoriosPorCategoria(): Observable<RelatorioCategoriaEntity[]> {
    return this.http.get<RelatorioCategoriaEntity[]>(`${environment.baseUrl}api/relatorio/listarRelatoriosPorCategoria/`);
  }

  listarRelatorios(): Observable<RelatorioEntity[]> {
    return this.http.get<RelatorioEntity[]>(`${environment.baseUrl}api/relatorio/listarRelatorios/`);
  }

  consultarFiltros(codigoRelatorio: number): Observable<RelatorioFiltroEntity[]> {
    return this.http.get<RelatorioFiltroEntity[]>(`${environment.baseUrl}api/relatorio/consultarFiltros/${codigoRelatorio}`);
  }

  consultarDadosRelatorio(codigoRelatorio: number, filtroValor: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}api/relatorio/ConsultarDadosRelatorio/${codigoRelatorio}`, filtroValor);
  }

  gerarPlanilha(codigoRelatorio: number, filtroValor: any): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}api/relatorio/gerarPlanilha/${codigoRelatorio}`, filtroValor, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Application-Type': 'application/octet-stream',
        'Accept': 'application/octet-stream',
      }),
      responseType: 'blob' as 'json'
    });
  }

  inserirRelatorio(relatorio: RelatorioEntity): Observable<void> {
    return this.http.post<void>(`${environment.baseUrl}api/relatorio/InserirRelatorio`, relatorio);
  }

  alterarRelatorio(relatorio: RelatorioEntity): Observable<void> {
    return this.http.post<void>(`${environment.baseUrl}api/relatorio/AlterarRelatorio`, relatorio);
  }

  excluirRelatorio(codigoRelatorio: number): Observable<void> {
    return this.http.get<void>(`${environment.baseUrl}api/relatorio/ExcluirRelatorio/${codigoRelatorio}`);
  }

  listarRecursosRelatorio(): Observable<RecursoEntity[]> {
    return this.http.get<RecursoEntity[]>(`${environment.baseUrl}api/relatorio/ListarRecursosRelatorio/`);
  }
}
