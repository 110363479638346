import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';
import { AlterarPedidoRequestEntity } from '../entities/Requests/alterar-pedido-request.entity';
import { AlterarStatusPedidoRequestEntity } from '../entities/Requests/alterar-status-pedido.entity';
import { ConsultarPedidoRequestEntity } from '../entities/Requests/consultar-pedido-request.entity';
import { ConsultarPedidoDeclaracaoTransporteResponseEntity } from '../entities/Responses/consultar-pedido-declaracao-transporte-response.entity';
import { ConsultarPedidoResponseEntity } from '../entities/Responses/consultar-pedido.response.entity';
import { CampanhaEntity } from '../entities/campanha.entity';
import { DetalhePedidoEntity } from '../entities/detalhe-pedido.entity';
import { HistoricoPedidoEntity } from '../entities/historico-pedido.entity';
import { ImportacaoPlanilhaEntity } from '../entities/importacao-planilha.entity';
import { OcorrenciaPedidoEntity } from '../entities/ocorrencia-pedido.entity';
import { PessoaEntity } from '../entities/pessoa.entity';
import { ProdutoPedidoEntity } from '../entities/produto-pedido.entity';
import { SolicitantePedidoEntity } from '../entities/solicitante-pedido.entity';
import { ServiceBase } from "../shared/service-base";

@Injectable({
  providedIn: 'root'
})
export class PedidoService extends ServiceBase {

  constructor(
    private http: HttpClient
  ) { super(); }

  consultarPedido(request: ConsultarPedidoRequestEntity): Observable<ConsultarPedidoResponseEntity[]> {
    return this.http.post<ConsultarPedidoResponseEntity[]>(`${environment.baseUrl}api/pedido/ConsultarPedido`, request);
  }

  consultarSolicitantePedido(idCliente: number, codigoPedido: number): Observable<SolicitantePedidoEntity> {
    return this.http.get<SolicitantePedidoEntity>(`${environment.baseUrl}api/pedido/ConsultarSolicitantePedido/${idCliente}/${codigoPedido}`);
  }

  consultarDadosPedido(idCliente: number, codigoPedido: number): Observable<DetalhePedidoEntity> {
    return this.http.get<DetalhePedidoEntity>(`${environment.baseUrl}api/pedido/ConsultarDadosPedido/${idCliente}/${codigoPedido}`);
  }

  consultarDestinatarioPedido(idCliente: number, codigoPedido: number): Observable<PessoaEntity> {
    return this.http.get<PessoaEntity>(`${environment.baseUrl}api/pedido/ConsultarDestinatarioPedido/${idCliente}/${codigoPedido}`);
  }

  consultarProdutosPedido(idCliente: number, codigoPedido: number): Observable<ProdutoPedidoEntity[]> {
    return this.http.get<ProdutoPedidoEntity[]>(`${environment.baseUrl}api/pedido/ConsultarProdutosPedido/${idCliente}/${codigoPedido}`);
  }

  consultarKitsPedido(idCliente: number, codigoPedido: number): Observable<ProdutoPedidoEntity[]> {
    return this.http.get<ProdutoPedidoEntity[]>(`${environment.baseUrl}api/pedido/ConsultarKitsPedido/${idCliente}/${codigoPedido}`);
  }

  consultarHistoricoPedido(idCliente: number, codigoPedido: number): Observable<HistoricoPedidoEntity[]> {
    return this.http.get<HistoricoPedidoEntity[]>(`${environment.baseUrl}api/pedido/ConsultarHistoricoPedido/${idCliente}/${codigoPedido}`);
  }

  consultarOcorrenciasPedido(idCliente: number, codigoPedido: number): Observable<OcorrenciaPedidoEntity[]> {
    return this.http.get<OcorrenciaPedidoEntity[]>(`${environment.baseUrl}api/pedido/ConsultarOcorrenciasPedido/${idCliente}/${codigoPedido}`);
  }

  aprovarPedido(status: AlterarStatusPedidoRequestEntity): Observable<OcorrenciaPedidoEntity[]> {
    return this.http.post<OcorrenciaPedidoEntity[]>(`${environment.baseUrl}api/pedido/aprovarPedido/`, status);
  }

  reprovarPedido(status: AlterarStatusPedidoRequestEntity): Observable<OcorrenciaPedidoEntity[]> {
    return this.http.post<OcorrenciaPedidoEntity[]>(`${environment.baseUrl}api/pedido/reprovarPedido/`, status);
  }

  cancelarPedido(status: AlterarStatusPedidoRequestEntity): Observable<OcorrenciaPedidoEntity[]> {
    return this.http.post<OcorrenciaPedidoEntity[]>(`${environment.baseUrl}api/pedido/cancelarPedido/`, status);
  }

  alterarPedido(request: AlterarPedidoRequestEntity): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}api/pedido/alterarPedido/`, request);
  }

  consultarImportacao(idCliente: number, idUsuario: number): Observable<ImportacaoPlanilhaEntity[]> {
    return this.http.get<ImportacaoPlanilhaEntity[]>(`${environment.baseUrl}api/ImportacaoPlanilha/ConsultarImportacao/${idCliente}/${idUsuario}`);
  }

  alterarStatusImportacao(codigoImportacao: number, status: string): Observable<ProdutoPedidoEntity[]> {
    return this.http.get<ProdutoPedidoEntity[]>(`${environment.baseUrl}api/ImportacaoPlanilha/AlterarStatusImportacao/${codigoImportacao}/${status}`);
  }

  consultarCampanhas(empresas: string): Observable<CampanhaEntity[]> {
    return this.http.get<CampanhaEntity[]>(`${environment.baseUrl}api/campanha/ConsultarCampanhas/?empresas=${empresas}`);
  }

  consultarPedidoDeclaracaoTransporte(idDeclaracao: string): Observable<ConsultarPedidoDeclaracaoTransporteResponseEntity> {
    return this.http.get<ConsultarPedidoDeclaracaoTransporteResponseEntity>(`${environment.baseUrl}api/v2/pedido/declaracao-conteudo/${idDeclaracao}`);
  }

  listarSituacaoPedido(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}api/v2/pedido/situacao`);
  }

  alterarStatusPedido(entity: AlterarStatusPedidoRequestEntity): Observable<void> {
    return this.http.put<void>(`${environment.baseUrl}api/v2/pedido/situacao`, entity);
  }
}
