import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

import { environment } from '../../../environments/environment';
import { StorageService } from '../../shared/storage.service';
import { ImagemEntity } from '../entities/imagem.entity';
import { PessoaJuridicaEntity } from '../entities/pessoa-juridica.entity';
import { PessoaService } from './pessoa.service';

@Injectable({
  providedIn: 'root'
})
export class PessoaJuridicaService extends PessoaService {

  constructor(
    private storage: StorageService,
    protected http: HttpClient
  ) { super(); }

  listar(): Observable<PessoaJuridicaEntity[]> {
    return this.http.get<PessoaJuridicaEntity[]>(`${environment.baseUrl}api/v2/${this.basePessoa}/`);
  }

  listarPorCliente(idCliente: number): Observable<PessoaJuridicaEntity[]> {
    return this.http.get<PessoaJuridicaEntity[]>(`${environment.baseUrl}api/v2/${this.basePessoa}/cliente/${idCliente}`);
  }

  listarPorTipo(tipo: string): Observable<PessoaJuridicaEntity[]> {
    return this.http.get<PessoaJuridicaEntity[]>(`${environment.baseUrl}api/v2/${tipo}/`);
  }

  incluir(pessoa: PessoaJuridicaEntity,): Observable<PessoaJuridicaEntity> {

    if (this.storage.data.tipoPessoa)
      return this.http.post<PessoaJuridicaEntity>(`${environment.baseUrl}api/v2/${this.basePessoa}/pessoa${this.storage.data.tipoPessoa}`, pessoa);

    return this.http.post<PessoaJuridicaEntity>(`${environment.baseUrl}api/v2/${this.basePessoa}`, pessoa);
  }

  alterar(pessoa: PessoaJuridicaEntity): Observable<PessoaJuridicaEntity> {

    if (this.storage.data.tipoPessoa)
      return this.http.put<PessoaJuridicaEntity>(`${environment.baseUrl}api/v2/${this.basePessoa}/pessoa${this.storage.data.tipoPessoa}`, pessoa);

    return this.http.put<PessoaJuridicaEntity>(`${environment.baseUrl}api/v2/${this.basePessoa}/`, pessoa);
  }

  excluir(id: number): Observable<any> {
    return this.http.delete<any>(`${environment.baseUrl}api/v2/${this.basePessoa}/${id}`);
  }

  incluirImagem(frmPessoa: File, idPessoa: number): Observable<any> {
    const formData = new FormData();
    formData.append('frmPessoa', frmPessoa, frmPessoa.name);

    return this.http.post<any>(`${environment.baseUrl}api/v2/PessoaJuridica/imagem/${idPessoa}`, formData, { observe: "events" });
  }

  consultarImagem(idPessoa: number): Observable<ImagemEntity[]> {
    return this.http.get<ImagemEntity[]>(`${environment.baseUrl}api/v2/PessoaJuridica/Imagem/${idPessoa}`);
  }

  consultar(idPessoa: number): Observable<PessoaJuridicaEntity[]> {
    return this.http.get<PessoaJuridicaEntity[]>(`${environment.baseUrl}api/v2/PessoaJuridica/${idPessoa}`);
  }
}
