import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AjudaComponent } from './ajuda/ajuda.component';
import { AlterarDadosPessoaisComponent } from './anonynoums/alterar-dados-pessoais/alterar-dados-pessoais.component';
import { AlterarSenhaComponent } from './anonynoums/alterar-senha/alterar-senha.component';
import { LoginComponent } from './anonynoums/login/login.component';
import { RecuperarSenhaComponent } from './anonynoums/recuperar-senha/recuperar-senha.component';
import { AprovarPedidoComponent } from './aprovar-pedido/aprovar-pedido.component';
import { AprovarPedidoModule } from './aprovar-pedido/aprovar-pedido.module';
import { ConsultaPedidoComponent } from './consulta-pedido/consulta-pedido.component';
import { ConsultaPedidoModule } from './consulta-pedido/consulta-pedido.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { SelecionarEmpresaComponent } from './empresa/selecionar-empresa/selecionar-empresa.component';
import { FinalizarPedidoComponent } from './finalizar-pedido/finalizar-pedido.component';
import { FinalizarPedidoModule } from './finalizar-pedido/finalizar-pedido.module';
import { IndicadorComponent } from './indicador/indicador.component';
import { IndicadorModule } from './indicador/indicador.module';
import { DeclaracaoTransporteProdutoComponent } from './pedidos/declaracao-produto/declaracao-transporte-produto.component';
import { DetalhePedidoComponent } from './pedidos/detalhe-pedido/detalhe-pedido.component';
import { ImportarPlanilhaComponent } from './pedidos/importar-planilha/importar-planilha.component';
import { PedidoImpressaoComponent } from './pedidos/pedido-impressao/pedido-impressao.component';
import { PedidosModule } from './pedidos/pedidos.module';
import { VisualizarDeclaracaoProdutoComponent } from './pedidos/visualizar-declaracao-produto/visualizar-declaracao-produto.component';
import { ProdutosModule } from './produtos/produtos.module';
import { RelatoriosModule } from './relatorios/relatorios.module';


const routes: Routes = [
  { path: '', component: LoginComponent, pathMatch: 'full' },
  { path: 'recuperarsenha', component: RecuperarSenhaComponent },
  { path: 'alterar-senha/:id', component: AlterarSenhaComponent },
  { path: 'alterar-dados-pessoais', component: AlterarDadosPessoaisComponent },
  {
    path: 'dashboards',
    children: [{
      path: 'dashboard',
      component: DashboardComponent,
      data: { label: 'Dashboard', url: '/', middlePath: 'Dashboards' }
    }, {
      path: 'indicadores',
      component: IndicadorComponent,
      data: { label: 'Indicadores', url: '/indicadores', middlePath: 'Dashboards' }
    }]
  },
  {
    path: 'produtos', loadChildren: () => import('./produto/produto.module')
      .then(m => m.ProdutoModule)
  },
  {
    path: 'seguranca', loadChildren: () => import('./seguranca/seguranca.module')
      .then(m => m.SegurancaModule)
  },
  {
    path: 'relatorios',
    loadChildren: () => import('./relatorios/relatorios.module')
      .then(m => m.RelatoriosModule)
  },
  {
    path: 'cadastro',
    loadChildren: () => import('./cadastro/cadastro.module')
      .then(m => m.CadastroModule)
  },
  {
    path: 'cadastro/depositante',
    loadChildren: () => import('./pessoa/pessoa.module')
      .then(m => m.PessoaModule)
  },
  {
    path: 'cadastro/destinatario',
    loadChildren: () => import('./pessoa/pessoa.module')
      .then(m => m.PessoaModule)
  },
  {
    path: 'cadastro/fornecedor',
    loadChildren: () => import('./pessoa/pessoa.module')
      .then(m => m.PessoaModule)
  },
  {
    path: 'saldo-virtual',
    loadChildren: () => import('./saldo-virtual/saldo-virtual.module')
      .then(m => m.SaldoVirtualModule)
  },

  {
    path: 'destinatarios',
    loadChildren: () => import('./pessoa/pessoa.module')
      .then(m => m.PessoaModule)
  },
  {
    path: 'fornecedores',
    loadChildren: () => import('./pessoa/pessoa.module')
      .then(m => m.PessoaModule)
  },
  {
    path: 'pedidos',
    children: [{
      path: 'aprovarpedidos',
      component: AprovarPedidoComponent,
      data: { label: 'Aprovação de Pedidos', url: '/aprovarpedidos', middlePath: 'Pedidos' }
    }, {
      path: 'consultarpedidos',
      component: ConsultaPedidoComponent,
      data: { label: 'Consulta de Pedidos', url: '/pedidos', middlePath: 'Pedidos' }
    },
    {
      path: 'detalhepedido/:codigo/:tipo',
      component: DetalhePedidoComponent,
      data: { label: 'Detalhes do Pedido', url: '/detalhepedido', middlePath: 'Pedidos / Consulta de Pedidos' }
    },
    {
      path: 'finalizarpedido',
      component: FinalizarPedidoComponent,
      data: { label: 'Finalizar Pedido', url: '/finalizarpedido', middlePath: 'Pedidos' }
    },
    {
      path: 'planilhas',
      component: ImportarPlanilhaComponent,
      data: { label: 'Importação de Planilha de Pedidos', url: '/importarplanilha', middlePath: 'Pedidos' }
    },
    {
      path: 'declaracao/:id',
      component: VisualizarDeclaracaoProdutoComponent,
      data: { label: 'Visualizar Declaração para Transporte', url: '/declaracao', middlePath: 'Pedidos' }
    },
    {
      path: 'pedido/:id',
      component: PedidoImpressaoComponent,
      data: { label: 'Pedido', url: '/declaracao', middlePath: 'Pedidos' }
    },
    {
      path: 'imprimir-declaracao/:id',
      component: DeclaracaoTransporteProdutoComponent,
      data: { label: 'Declaração para Transporte', url: '/imprimir-declaracao', middlePath: 'Pedidos' }
    },
    {
      path: 'imprimir-pedido/:id',
      component: DeclaracaoTransporteProdutoComponent,
      data: { label: 'Declaração para Transporte', url: '/imprimir-declaracao', middlePath: 'Pedidos' }
    },
    ]
  },

  {
    path: 'ajuda',
    children: [{
      path: 'manual',
      component: AjudaComponent,
      data: { label: 'Manual de Uso', url: '/manual', middlePath: 'Ajuda' }
    }]
  },
  {
    path: 'empresa',
    children: [{
      path: 'selecionar',
      component: SelecionarEmpresaComponent,
      data: { label: 'Selecionar empresa', url: '/', middlePath: 'Empresa' }
    }]
  },
];


//const routes: Routes = [
//  { path: '', component: LoginComponent, pathMatch: 'full' },
//  { path: 'dashboard', component: DashboardComponent, data: { label: 'Dashboard', url: '/' } },
//  { path: 'finalizarpedido', component: FinalizarPedidoComponent, data: { label: 'Finalizar pedido', url:'/finalizarpedido' } },
//  { path: 'produtos', component: ProdutosComponent, data: { label: 'Produtos', url: '/produtos' /*middlePath: 'Teste'*/ } },
//  { path: 'pedidos', component: ConsultaPedidoComponent, data: { label: 'Pedidos', url: '/pedidos' } },
//  { path: 'detalhepedido/:codigo/:tipo', component: DetalhePedidoComponent, data: { label: 'Detalhe do Pedido', url: '/detalhepedido', midlePath: 'Pedidos' } },
//  { path: 'aprovarpedidos', component: AprovarPedidoComponent, data: { label: 'Aprovação de Pedidos', url: '/aprovarpedidos' } },
//  { path: 'importarplanilha', component: ImportarPlanilhaComponent, data: { label: 'Importação de Planilha de Pedidos', url: '/importarplanilha' } },
//  { path: 'relatoriorecebimentos', component: RecebimentosComponent, data: { label: 'Relatório de Recebimentos', url: '/relatoriorecebimentos' } },
//  { path: 'relatorioenvios', component: EnviosComponent, data: { label: 'Relatório de Envios', url: '/relatorioenvios' } },
//  { path: 'indicadores', component: IndicadorComponent, data: { label: 'Indicadores', url: '/indicadores' } },
//  { path: 'recuperarsenha', component: RecuperarSenhaComponent},
//];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    DashboardModule,
    FinalizarPedidoModule,
    ProdutosModule,
    PedidosModule,
    ConsultaPedidoModule,
    AprovarPedidoModule,
    RelatoriosModule,
    IndicadorModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
