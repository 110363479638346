import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { ConfiguracaoKitEntity } from '../../entities/configuracao-kit.entity';
import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { ProdutoService } from '../produto.service';

@Component({
  selector: 'app-configuracao-kit-produto',
  templateUrl: './configuracao-kit-produto.component.html',
  styleUrls: ['./configuracao-kit-produto.component.css']
})
export class ConfiguracaoKitProdutoComponent extends ComponentBase implements OnInit {

  configuracoes: ConfiguracaoKitEntity[];

  constructor(
    private produtoService: ProdutoService,
    protected alert: AlertService,
    protected router: Router,
    private session: SessionService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.hideTableButtons = true;
    this.carregarConfiguracoesKit();
  }

  carregarConfiguracoesKit() {
    if (!this.session.empresa) {
      this.alert.warning("Para configurar um Kit é necessário ter selecionado uma empresa.")
        .then(() => {
          this.session.urlReturn = window.location.href;
          this.router.navigate(['/empresa/selecionar']);
        });
      return;
    }
    let empresas = "";
    if (this.session.empresa)
      empresas = this.session.empresa.idCliente.toString();

    this.loadingGestores<ConfiguracaoKitEntity[]>(this.produtoService, "listarConfiguracaoKit", [empresas])
      .pipe(take(1))
      .subscribe(resp => {

        this.configuracoes = resp;
      });
  }

  remover(config: ConfiguracaoKitEntity, idx: number) {
    this.alert.confirm(`Tem certeza que deseja remover o Kit ${config.descricao}?`)
      .then((val) => {
        if (val.value) {
          this.loadingGestores<ConfiguracaoKitEntity[]>(this.produtoService, "excluirConfiguracaoKit", [config.codigoConfiguracaoKit])
            .pipe(take(1))
            .subscribe(resp => {
              this.configuracoes.splice(idx, 1);
            });
        }
      });
  }

  novoKit() {
    this.router.navigate(['/produtos/configurar-kit/novo']);
  }
}
