import { Component, OnInit, forwardRef, Output, EventEmitter } from '@angular/core';
import { ComponentBase } from '../shared/component-base';
import { Observable } from 'rxjs';
import { UFEntity } from '../entities/uf.entity';
import { DestinatarioService } from '../destinatario/destinatario.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SessionService } from '../shared/session.service';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => UfComponent),
  multi: true
};

@Component({
  selector: 'app-uf',
  templateUrl: './uf.component.html',
  styleUrls: ['./uf.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})

export class UfComponent extends ComponentBase implements ControlValueAccessor, OnInit {

  uf$: Observable<UFEntity[]>;
  @Output() onChangeUF: EventEmitter<any> = new EventEmitter();

  constructor(
    private destinatario: DestinatarioService,
    private session: SessionService
  ) { super(); }

  ngOnInit() {
    let empresas = null;
    if (this.session.empresa)
      empresas = this.session.empresa.idCliente;
    else
      empresas = this.session.getIdsEmpresa();

    this.uf$ = this.destinatario.listarUFs(empresas);
  }

  changeUF(args: any) {
    this.onChangeUF.emit(args);
  }

  //The internal data model
  private innerSelectValue: any = '';

  //Placeholders for the callbacks which are later providesd
  //by the Control Value Accessor
  private onTouchedCallback: () => {};
  private onChangeCallback: (_: any) => {};

  //get accessor
  get selectValue(): any {
    return this.innerSelectValue;
  }

  //set accessor including call the onchange callback
  set selectValue(value: any) {
    if (value !== this.innerSelectValue) {
      this.innerSelectValue = value;
      this.onChangeCallback(value);
    }
    this.onTouchedCallback();
  }

  //From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this.innerSelectValue)
      this.innerSelectValue = value;
  }

  //From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

}
