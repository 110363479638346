import { UsuarioLogadoEntity } from "./usuario-logado.entity";
import { PedidoEntity } from "./pedido.entity";

export class AgrupadorPedidoEntity {
  codigoAgrupador: number;
  data: Date;
  usuario: number;
  ativo: boolean;
  solicitante: UsuarioLogadoEntity;
  pedidos: PedidoEntity[];
}
