import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PainelInformacaoComponent } from './painel-informacao.component';


@NgModule({
  declarations: [PainelInformacaoComponent],
  imports: [
    CommonModule,
    FontAwesomeModule
  ],
  exports: [
    PainelInformacaoComponent
  ]
})
export class PainelInformacaoModule { }
