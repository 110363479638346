export class RelatorioEnvioRequestEntity {
  agrupamento: number;
  cliente: number;
  divisoes: number[];
  dtIni: Date;
  dtFim: Date;
  solicitante: string;
  uf: string;
  cidade: string;
  destinatario: string;
}
