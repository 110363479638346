import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

import { environment } from '../../environments/environment';
import { PessoaSaldoVirtualResponseEntity } from '../entities/Responses/pessoa-saldo-virtual-response.entity';
import { ProdutoSaldoVirtualResponseEntity } from '../entities/Responses/produto-saldo-virtual-response.entity';
import { ServiceBase } from "../shared/service-base";
import { SessionService } from "../shared/session.service";


@Injectable({
  providedIn: 'root'
})
export class SaldoVirtualService extends ServiceBase {

  constructor(
    private http: HttpClient,
    private session: SessionService
  ) { super(); }

  listarPessoasDeUmaDivisao(idDivisao: Number): Observable<PessoaSaldoVirtualResponseEntity[]> {
    return this.http.get<PessoaSaldoVirtualResponseEntity[]>(`${environment.baseUrl}api/pessoa/ListarPessoasDeUmaDivisao/${idDivisao}`);
  }

  listarPessoasDeUmaDivisaoComSaldoProduto(idDivisao: Number, idProduto: number): Observable<PessoaSaldoVirtualResponseEntity[]> {
    return this.http.get<PessoaSaldoVirtualResponseEntity[]>(`${environment.baseUrl}api/pessoa/ListarPessoasDeUmaDivisaoComSaldoProduto/${idDivisao}/${idProduto}`);
  }

  consultarEstoquePessoa(idCliente: Number, idDivisao: Number, idPessoa: number): Observable<ProdutoSaldoVirtualResponseEntity[]> {
    return this.http.get<ProdutoSaldoVirtualResponseEntity[]>(`${environment.baseUrl}api/produto/ConsultarEstoquePessoa/${idCliente}/${idDivisao}/${idPessoa}`);
  }

  transferirSaldoVirtual(entity: any): Observable<void> {
    return this.http.put<void>(`${environment.baseUrl}api/produto/TransferirSaldoVirtual`, entity);
  }
}
