import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { ItemCarrinhoEntity } from '../../../../../entities/item-carrinho.entity';
import { ProdutoEntity } from '../../../../../entities/produto.entity';
import { AlertService } from '../../../../../shared/alert.service';
import { SessionService } from '../../../../../shared/session.service';

@Injectable({
  providedIn: 'root'
})
export class CarrinhoService {
  constructor(
    private toastr: ToastrService,
    private session: SessionService,
    protected alert: AlertService
  ) { }

  get itens(): ItemCarrinhoEntity[] {
    var carrinho = JSON.parse(localStorage.getItem('carrinho'));
    if (!carrinho)
      carrinho = [];

    return carrinho;
  }

  set itens(itens: ItemCarrinhoEntity[]) {
    localStorage.setItem('carrinho', JSON.stringify(itens));
  }

  adicionarProduto(produto: ProdutoEntity, qtd: number = 1) {
    if (!produto)
      return;



    const empresa = this.session.usuario.empresas.find(x => x.idCliente === produto.idCliente);
    if (empresa) {
      if (empresa.bloquearCarrinho) {
        this.alert.warning("Essa função encontra-se temporariamente indisponível, para maiores esclarecimentos, favor contatar o setor de Marketing.");
        return;
      }

      if (empresa.mensagemBloqueioCarrinho) {
        this.alert.warning(empresa.mensagemBloqueioCarrinho)
        return;
      }
    }

    if (produto.estoque <= 0 || (produto.saldoVirtual === 0)) {
      this.toastr.warning('Produto sem saldo disponível.', 'Atenção!', {
        progressBar: true,
        enableHtml: true,
        easing: "10000",
        positionClass: 'toast-top-left'
      });

      return;
    }

    if (!this.itens)
      this.itens = [];

    let result = this.itens.find(obj => {
      return obj.produto.codigoProduto === produto.codigoProduto && obj.produto.idDivisao === produto.idDivisao;
    });

    if (!result) {
      if (produto.estoque <= 0 && produto.saldoVirtual === 0) {
        this.toastr.warning('Produto sem estoque disponível', 'Estoque indisponível');
        return;
      }

      let item = new ItemCarrinhoEntity();
      item.produto = produto;
      item.quantidade = qtd;

      let prods = this.itens;
      prods.push(item);
      this.itens = prods;

      this.toastr.success('Produto adicionado com sucesso.', 'Produtos', {
        progressBar: true,
        positionClass: 'toast-top-left'
      });

    } else {
      let prods = this.itens;
      prods.forEach(obj => {
        if (obj.produto.codigoProduto === produto.codigoProduto && obj.produto.idDivisao === produto.idDivisao) {
          if (obj.produto.saldoVirtual > -1) {
            if (obj.quantidade + qtd > produto.estoque) {
              this.toastr.warning('Produto sem estoque disponível', 'Estoque indisponível');
              return;
            } else {
              obj.quantidade = Number(obj.quantidade) + qtd;
              this.toastr.success('Produto adicionado com sucesso.', 'Produtos', {
                progressBar: true,
                positionClass: 'toast-top-left'
              });
            }
          } else {
            if (obj.quantidade + qtd > produto.saldoVirtual) {
              this.toastr.warning('Produto sem estoque disponível', 'Estoque indisponível');
              return;
            } else {
              obj.quantidade = Number(obj.quantidade) + qtd;
              this.toastr.success('Produto adicionado com sucesso.', 'Produtos', {
                progressBar: true,
                positionClass: 'toast-top-left'
              });
            }
          }
        }
      });
      this.itens = prods;
    }
  }

  getValorCarrinho(): number {
    if (this.itens.length === 0)
      return 0;

    return this.itens.map(item => item.produto.valorUnitario * item.quantidade).reduce((prev, next) => prev + next);
  }

  clear(): void {
    this.itens = [];
  }

  possuiProdutosMultiplasEmpresa(): boolean {
    if (this.itens.length === 0)
      return false;

    let ids = this.obterIdsEmpresasProdutos();
    return ids.split(',').length > 1;
  }

  obterIdsEmpresasProdutos(): string {
    let result = this.itens[0].produto.idCliente.toString();
    for (let x = 0; x < this.itens.length; x++) {
      if (result.indexOf(this.itens[x].produto.idCliente.toString()) < 0)
        result += `,${this.itens[x].produto.idCliente.toString()}`;
    }

    return result;
  }

}
