export class ProdutoPedidoEntity {
  idProduto: number;
  nomeProduto: string;
  codigoProduto: string;
  idPessoaDivisao: number;
  nomePessoaDivisao: string;
  valorUnitario: number;
  quantidadeSolicitada: number;
  quantidadeEnviada: number;
  novaQuantidade: number;
  total: number;
  urlImagemKit: string;
  idCliente: number;
  localizacaoProduto: string;
  unidadeMedida: string;
}
