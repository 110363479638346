import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { SolicitacaoEstoquePessoaDivisaoEntity } from '../../entities/solicitacao-estoque-pessoa-divisao.entity';
import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { ProdutoService } from '../produto.service';

@Component({
  selector: 'app-produto-solicitacao-estoque-gestao',
  templateUrl: './produto-solicitacao-estoque-gestao.component.html',
  styleUrls: ['./produto-solicitacao-estoque-gestao.component.css']
})
export class ProdutoSolicitacaoEstoqueGestaoComponent extends ComponentBase implements OnInit {
  gestor: boolean = false;
  modal: NgbModalRef;
  solicitacoes: SolicitacaoEstoquePessoaDivisaoEntity[] = [];
  observacao: string;
  acao: string;
  solicitacaoSelecionada: SolicitacaoEstoquePessoaDivisaoEntity;
  modoLeitura: boolean;
  tituloModal: string;
  funcionarioImperium: boolean = false;

  @ViewChild("modalObservacao", { static: true }) modalObservacao: any;

  constructor(
    private produtoService: ProdutoService,
    private session: SessionService,
    protected modalService: NgbModal,
    protected alert: AlertService
  ) {
    super();
  }

  ngOnInit() {
    this.gestor = window.location.href.indexOf("gerenciar") > -1;
    this.funcionarioImperium = this.session.usuario.funcionario;
    if (this.gestor) {
      this.listarSolicitacoes();
    } else {
      this.listarMinhasSolicitacoes();
    }
  }

  listarMinhasSolicitacoes() {
    this.loading2<SolicitacaoEstoquePessoaDivisaoEntity[]>(() => this.produtoService.consultarSolicitacoesEstoquePessoaDivisao(this.session.usuario.idCliente, this.session.usuario.idPessoa))
      .pipe(take(1))
      .subscribe(resp => {
        console.log(resp);
        this.solicitacoes = resp;
      });
  }
  listarSolicitacoes() {
    if (this.session.empresa) {
      this.loading2<SolicitacaoEstoquePessoaDivisaoEntity[]>(() => this.produtoService.consultarSolicitacoesEstoquePessoaDivisao(this.session.empresa.idCliente))
        .pipe(take(1))
        .subscribe(resp => {
          console.log(resp);
          this.solicitacoes = resp;
        });
    }
    else {
      this.loading2<SolicitacaoEstoquePessoaDivisaoEntity[]>(() => this.produtoService.consultarSolicitacoesEstoquePessoaDivisao())
        .pipe(take(1))
        .subscribe(resp => {
          console.log(resp);
          this.solicitacoes = resp;
        });
    }
  }

  salvar() {
    if (this.acao === "AP")
      this.aprovar();
    else
      this.reprovar();
  }

  aprovar() {
    this.solicitacaoSelecionada.observacao = this.observacao;
    this.solicitacaoSelecionada.situacao = "FI";
    this.finalizarSolicitacao();
  }

  reprovar() {
    this.solicitacaoSelecionada.observacao = this.observacao;
    this.solicitacaoSelecionada.situacao = this.gestor ? "RP" : "CA";
    this.finalizarSolicitacao();
  }

  abrirModalObservacao(solicitacao: SolicitacaoEstoquePessoaDivisaoEntity, acao: string, modoLeitura: boolean = false) {

    this.acao = acao;
    this.observacao = "";
    this.solicitacaoSelecionada = solicitacao;
    this.modal = this.modalService.open(this.modalObservacao, { ariaLabelledBy: 'modal-dest', windowClass: "fichaModal", size: 'sm' });
    this.modoLeitura = modoLeitura;
  }

  finalizarSolicitacao() {

    this.solicitacaoSelecionada.situacao = this.acao;

    if (this.funcionarioImperium)
      this.solicitacaoSelecionada.observacao += `\nSolicitação ${this.acao === 'AP' ? 'aprovada' : this.acao === 'RP' ? 'reprovada' : 'finalizada'} por: ` + this.session.usuario.nomePessoa;


    this.loading2(() => this.produtoService.atualizarSituacaoSolicitacaoEstoquePessoaDivisao(this.solicitacaoSelecionada))
      .pipe(take(1))
      .subscribe(() => {
        this.alert.success("Solicitação atualizada com sucesso!");
        this.modal.close();
        this.ngOnInit();
      });
  }
}
