import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { DivisaoESLEntity } from '../entities/divisao-esl.entity';
import { FichaProdutoEntity } from '../entities/ficha-produto.entity';
import { ProdutoEntity } from '../entities/produto.entity';
import { AlertService } from '../shared/alert.service';
import { ComponentBase } from '../shared/component-base';
import { SessionService } from '../shared/session.service';
import { StorageService } from '../shared/storage.service';
import { ConsultarProdutosRelacionadosResponseEntity } from './entities/response/consultar-produto-relacionados-response.entity';
import { TagProdutoEntity } from './entities/tag-produto.emtity';
import { TransferenciaProdutoDivisaoRequestEntity } from './entities/transferencia-produto-divisao-request.entity';
import { ProdutoService } from './produto.service';

@Component({
  selector: 'app-produto',
  templateUrl: './produto.component.html',
  styleUrls: ['./produto.component.css']
})
export class ProdutoComponent extends ComponentBase implements OnInit {

  @Input() pesquisarSaldoZerado: boolean = false;
  @Input() idDivisaoPadrao: number = 0;
  @Input() controleVisualizacao: string = "card";
  @Input() addProdutos: boolean = false;
  @Input() showControleVisualizacao: boolean = false;
  @Input() simpleView: boolean = true;
  @Input() exibirSaldoVirtual: boolean = false;
  @Input() carregamentoAutomatico: boolean = false;

  @Output() onAddRemove: EventEmitter<ProdutoEntity> = new EventEmitter();

  @Input() produtos: ProdutoEntity[];
  produtoSelecionado: ProdutoEntity;
  divisaoOrigem: DivisaoESLEntity = new DivisaoESLEntity();
  transferenciaProdutoDivisaoRequestEntity: TransferenciaProdutoDivisaoRequestEntity = new TransferenciaProdutoDivisaoRequestEntity();

  private modal: NgbModalRef;

  @ViewChild('modalProduto', { static: true }) modalProdutoContentRef: TemplateRef<any>;
  @ViewChild('modalLocalizacao', { static: true }) modalLocalizacaoRef: TemplateRef<any>;
  @ViewChild('modalDivisao', { static: true }) modalDivisaoRef: TemplateRef<any>;
  @ViewChild('modalAjusteEstoque', { static: true }) modalAjusteEstoqueRef: TemplateRef<any>;

  constructor(
    private session: SessionService,
    protected modalService: NgbModal,
    private produtoService: ProdutoService,
    protected alert: AlertService,
    protected router: Router,
    private storage: StorageService
  ) { super(); }

  ngOnInit() {

    if (this.addProdutos || window.location.href.indexOf("estoque/transferencia-divisao") > -1) {
      this.showControleVisualizacao = false;
      this.controleVisualizacao = "table";

      this.redirecionarEmpresa(`Antes de acessar a transferência entre divisões é necessário selecionar uma empresa.`);
    }

    if (this.session.usuario.acessoNovo)
      this.controleVisualizacao = "table";
  }

  changeViewer(tipo: string) {
    this.controleVisualizacao = tipo;
  }

  onFilterProducts(obj: any) {

    if (obj.item1) {
      this.session.facetProduto = obj.item1;
      this.onSearchProducts(obj.item2);
    } else {
      this.onSearchProducts(obj);
    }
  }

  onSearchProducts(produtos: ProdutoEntity[]) {

    if (produtos.length === 0) {
      if (window.location.href.indexOf('saldo-virtual/distribuir-produto') > -1)
        this.alert.warning("Não existem produtos com saldo para distribuição entre as contas!");
      // else
      //   this.alert.warning("Nenhum produto foi localizado!");
    }


    this.produtos = produtos;
  }

  abrirModal(modal: any) {
    this.modal = this.modalService.open(modal, { ariaLabelledBy: 'modal-dest', windowClass: "medium-modal" });
    this.modal.result.then((result) => {

    }, (reason) => {

    });
  }

  carregarProdutosRelacionados(produto: ProdutoEntity) {
    this.loading2<ConsultarProdutosRelacionadosResponseEntity[]>(() => this.produtoService.consultarProdutosRelacionados(produto.idInterno))
      .pipe(take(1))
      .subscribe((resp) => {
        this.session.produtosRelacionados = resp;
      });
  }

  detail(produto: ProdutoEntity) {
    this.produtoSelecionado = produto;
    this.loading2<FichaProdutoEntity>(() => this.produtoService.consultarFichaProduto(this.session.usuario['idCliente'], produto.idInterno))
      .pipe(take(1))
      .subscribe(resp => {
        this.session.fichaProdutoDetalhe = resp;

        this.carregarTags(produto);
        this.carregarProdutosRelacionados(produto);
      });
  }

  addRemoveProduto(produto: ProdutoEntity) {
    this.onAddRemove.emit(produto);
  }

  onTransferirDivisao(produto: ProdutoEntity) {
    this.produtoSelecionado = produto;
    this.abrirModal(this.modalDivisaoRef);
  }

  onTransferirLocalizacao(produto: ProdutoEntity) {
    this.produtoSelecionado = produto;
    this.abrirModal(this.modalLocalizacaoRef);
  }

  onMovimentacao(produto: ProdutoEntity) {
    this.produtoSelecionado = produto;
    this.router.navigate(['/movimentacao', produto.idCliente, produto.idInterno]);
  }

  carregarTags(produto: ProdutoEntity) {
    this.loading2<TagProdutoEntity[]>(() => this.produtoService.consultarTagsPorProduto(produto.idInterno))
      .pipe(take(1))
      .subscribe(resp => {
        produto.tags = resp;
        this.session.produtoDetalhe = produto;
      });
  }

  transferir() {
    this.transferenciaProdutoDivisaoRequestEntity.idCliente = this.session.empresa.idCliente;
    this.transferenciaProdutoDivisaoRequestEntity.idDivisaoOrigem = this.produtoSelecionado.idDivisao;
    this.transferenciaProdutoDivisaoRequestEntity.idProduto = this.produtoSelecionado.idProduto;

    this.loading2(() => this.produtoService.transferirProdutoEntreDivisoes(this.transferenciaProdutoDivisaoRequestEntity))
      .pipe(take(1))
      .subscribe(() => {
        this.alert.success("Produto transferido com sucesso!");
        this.produtoSelecionado.estoque = this.produtoSelecionado.estoque - this.transferenciaProdutoDivisaoRequestEntity.quantidade;
        this.produtoSelecionado.estoqueVirtual = this.produtoSelecionado.estoqueVirtual - this.transferenciaProdutoDivisaoRequestEntity.quantidade;
        //$("#btnPesquisarProdutos").click();
        this.modal.close();
      });
  }

  finalizar() {
    $("#btnPesquisarProdutos").click();
    this.modal.close();
  }

  onAjustarEstoque(produto: ProdutoEntity) {
    this.produtoSelecionado = produto;
    this.storage.data.produtoAjusteEstoque = produto;
    this.abrirModal(this.modalAjusteEstoqueRef);
  }
}
