import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { take } from 'rxjs/operators';
import { ProdutoConstant } from '../../Constants/produto.constant';
import { CadastroService } from '../../cadastro/cadastro.service';
import { CategoriaProdutoEntity } from '../../cadastro/entities/categoria-produto.entity';
import { DivisaoComponent } from '../../divisao/divisao.component';
import { ConsultarProdutoRequestEntity } from '../../entities/Requests/consultar-produto-request.entity';
import { GrupoProdutoEntity } from '../../entities/grupo-produto.entity';
import { ProdutoEntity } from '../../entities/produto.entity';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { ProdutoService } from '../produto.service';


@Component({
  selector: 'app-filtro-produto',
  templateUrl: './filtro-produto.component.html',
  styleUrls: ['./filtro-produto.component.css']
})
export class FiltroProdutoComponent extends ComponentBase implements OnInit {
  divisoesSelecionadas: number[];
  statusProdutoSelecionado: number;
  estoqueMinimo: boolean;
  dtVenctoIni: Date;
  dtVenctoFim: Date;
  dtMovtoIni: Date;
  dtMovtoFim: Date;
  semMovtoDtIni: Date;
  semMovtoDtFim: Date;
  codigoProduto = "";
  nomeProduto = "";
  categoriaProduto: number;
  categorias: GrupoProdutoEntity[] = [];
  produtoSemDivisao: boolean;
  familias: CategoriaProdutoEntity[] = [];
  transferenciaDivisao: boolean = false;

  @Input() idDivisaoPadrao: number = 0;
  @Input() exibirSaldoVirtual: boolean = false;
  @Input() carregamentoAutomatico: boolean = false;
  @Input() mensagemProdutoNaoLocalizado: string;
  @Input() saldoZerado: boolean = false;

  @Output() onFilterProducts: EventEmitter<any> = new EventEmitter();
  @ViewChild(DivisaoComponent, { static: true }) refDivisao: DivisaoComponent;

  constructor(
    private produtoService: ProdutoService,
    public session: SessionService,
    private cadastroService: CadastroService,
  ) {
    super();
  }

  statusProduto = ProdutoConstant.dropdownStatusProduto;

  ngOnInit() {
    this.transferenciaDivisao = window.location.href.indexOf('estoque/transferencia-divisao') > -1;
    this.consultarGruposProduto();
    this.listarCategoriasProdutosMarca();

    if (this.carregamentoAutomatico)
      this.pesquisarProdutoComFiltro();
  }

  consultarGruposProduto() {
    let empresa = "";
    if (this.session.empresa)
      empresa = this.session.empresa.idCliente.toString();
    else if (this.refDivisao && this.refDivisao.obterEmpresaSelecionada())
      empresa = this.refDivisao.obterEmpresaSelecionada();
    else
      empresa = this.session.getIdsEmpresa();


    this.call<GrupoProdutoEntity[]>(this.produtoService, "consultarGruposProduto", [empresa])
      .pipe(take(1))
      .subscribe(resp => {
        this.categorias = resp;
      });
  }

  changeSaldoZerado() {
    this.saldoZerado = !this.saldoZerado;
  }

  pesquisarProduto() {

    let request = new ConsultarProdutoRequestEntity();
    request.acessoProdutoBackOffice = this.session.usuario.acessoNovo;

    if (this.idDivisaoPadrao > 0)
      request.divisoes = [this.idDivisaoPadrao];
    else if (this.divisoesSelecionadas == null || this.divisoesSelecionadas.length == 0) {
      request.divisoes = this.session.getIdDivisoes();
    } else {
      if (Object.prototype.toString.call(this.divisoesSelecionadas) !== '[object Array]') {
        request.divisoes = [];
        request.divisoes.push(<any>this.divisoesSelecionadas);
      } else {
        request.divisoes = this.divisoesSelecionadas;
      }
    }
    request.saldoZerado = true;//this.transferenciaDivisao ? true : this.saldoZerado;
    request.codigoProduto = this.codigoProduto;
    request.descricaoProduto = this.nomeProduto;
    request.produtoSemDivisao = this.produtoSemDivisao;

    if (this.refDivisao.obterEmpresaSelecionada())
      request.empresas = this.refDivisao.obterEmpresaSelecionada();
    else
      request.empresas = this.session.getIdsEmpresa();

    request.idUsuario = this.session.usuario['idPessoa'];
    request.status = this.statusProdutoSelecionado ? this.statusProdutoSelecionado : 0;
    request.estoqueMinimo = this.estoqueMinimo;
    request.dtValidadeIni = this.dtVenctoIni;
    request.dtValidadeFim = this.dtVenctoFim;
    request.dtMovimentoIni = this.dtMovtoIni;
    request.dtMovimentoFim = this.dtMovtoFim;
    request.dtSemMovimentoIni = this.semMovtoDtIni;
    request.dtSemMovimentoFim = this.semMovtoDtFim;
    request.grupoProduto = this.categoriaProduto;
    request.somenteComEstoqueVirtual = this.exibirSaldoVirtual;


    this.loading2<ProdutoEntity[]>(() => this.produtoService.consultarProdutosComFiltro(request))
      .pipe(take(1))
      .subscribe(response => {
        this.onFilterProducts.emit(response);
      });
  }

  pesquisarProdutoComFiltro() {
    let request = new ConsultarProdutoRequestEntity();

    if (this.idDivisaoPadrao > 0)
      request.divisoes = [this.idDivisaoPadrao];
    else if (this.divisoesSelecionadas == null || this.divisoesSelecionadas.length == 0) {
      request.divisoes = this.session.getIdDivisoes();
    } else {
      if (Object.prototype.toString.call(this.divisoesSelecionadas) !== '[object Array]') {
        request.divisoes = [...this.divisoesSelecionadas];
      } else {
        request.divisoes = this.divisoesSelecionadas;
      }
    }
    request.saldoZerado = this.saldoZerado;
    request.codigoProduto = this.codigoProduto;
    request.descricaoProduto = this.nomeProduto;
    request.produtoSemDivisao = this.produtoSemDivisao;

    if (this.refDivisao.obterEmpresaSelecionada())
      request.empresas = this.refDivisao.obterEmpresaSelecionada();
    else
      request.empresas = this.session.getIdsEmpresa();

    request.idUsuario = this.session.usuario['idPessoa'];
    request.status = this.statusProdutoSelecionado ? this.statusProdutoSelecionado : 0;
    request.estoqueMinimo = this.estoqueMinimo;
    request.dtValidadeIni = this.dtVenctoIni;
    request.dtValidadeFim = this.dtVenctoFim;
    request.dtMovimentoIni = this.dtMovtoIni;
    request.dtMovimentoFim = this.dtMovtoFim;
    request.dtSemMovimentoIni = this.semMovtoDtIni;
    request.dtSemMovimentoFim = this.semMovtoDtFim;
    request.grupoProduto = this.categoriaProduto;
    request.somenteComEstoqueVirtual = this.exibirSaldoVirtual;
    request.pesquisaGenerica = this.session.termoPesquisa;

    this.loading2<any>(() => this.produtoService.consultarProdutosComFiltro(request))
      .pipe(take(1))
      .subscribe(response => {
        response.item1.forEach(x => {
          x.isOpen = true;
        });

        this.onFilterProducts.emit(response);
      });
  }

  listarCategoriasProdutosMarca() {
    if (this.session.empresa) {
      this.call2<CategoriaProdutoEntity[]>(() => this.cadastroService.listarCategoriasProdutosMarca(this.session.empresa.idCliente, "familia"))
        .pipe(take(1))
        .subscribe(resp => {
          this.familias = resp;
        });
    }
  }

  pesquisarProduto2() {

    let request = new ConsultarProdutoRequestEntity();
    request.acessoProdutoBackOffice = this.session.usuario.acessoNovo;
    if (this.idDivisaoPadrao > 0)
      request.divisoes = [this.idDivisaoPadrao];
    else if (this.divisoesSelecionadas == null || this.divisoesSelecionadas.length == 0) {
      request.divisoes = this.session.getIdDivisoes();
    } else {
      request.divisoes = this.divisoesSelecionadas;
    }
    request.saldoZerado = this.saldoZerado;
    request.codigoProduto = this.codigoProduto;
    request.descricaoProduto = this.nomeProduto;
    request.produtoSemDivisao = this.produtoSemDivisao;

    if (this.refDivisao.obterEmpresaSelecionada())
      request.empresas = this.refDivisao.obterEmpresaSelecionada();
    else
      request.empresas = this.session.getIdsEmpresa();

    request.idUsuario = this.session.usuario['idPessoa'];
    request.status = this.statusProdutoSelecionado ? this.statusProdutoSelecionado : 0;
    request.estoqueMinimo = this.estoqueMinimo;
    request.dtValidadeIni = this.dtVenctoIni;
    request.dtValidadeFim = this.dtVenctoFim;
    request.dtMovimentoIni = this.dtMovtoIni;
    request.dtMovimentoFim = this.dtMovtoFim;
    request.dtSemMovimentoIni = this.semMovtoDtIni;
    request.dtSemMovimentoFim = this.semMovtoDtFim;
    request.grupoProduto = this.categoriaProduto;

    this.loading2<ProdutoEntity[]>(() => this.produtoService.consultarProdutos(request))
      .pipe(take(1))
      .subscribe(response => {
        this.onFilterProducts.emit(response);
      });
  }

}
