import { Component, Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-painel-informacao',
  templateUrl: './painel-informacao.component.html',
  styleUrls: ['./painel-informacao.component.css']
})
export class PainelInformacaoComponent {
  @Input() icone: string;
  @Input() titulo: string;
  @Input() texto: string;
  @Input() recursosAdicionais: string[] = [];
}
