export class FacetProdutoEntity {
  idCliente: number;
  idCategoria: number;
  nomeCategoria: string;
  quantidade: number;
  categoriaPai: boolean;
  subCategorias: FacetProdutoEntity[];
  isOpen: boolean;
  idLink: number;
  selected: boolean;

}
