import { PipeTransform, Pipe } from "@angular/core";
import { CommonService } from "../shared/common.service";

@Pipe({
  name: 'cpfCnpj'
})
export class CpfCnpjPipe implements PipeTransform {

  constructor(
    private common: CommonService
  ) { }

  transform(value: any, args?: any): any {
    return this.common.formatarCpfCnpj(value);
  }
}
