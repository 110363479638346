import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlterarSenhaComponent } from './alterar-senha/alterar-senha.component';
import { AnonynoumsComponent } from './anonynoums.component';
import { LoginComponent } from './login/login.component';
import { RecuperarSenhaComponent } from './recuperar-senha/recuperar-senha.component';
import { LayoutModule } from '../layout/layout.module';
import { MatIconModule, MatCardModule, MatInputModule, MatCheckboxModule, MatButtonModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { AlterarDadosPessoaisComponent } from './alterar-dados-pessoais/alterar-dados-pessoais.component';
import { NgxMaskModule, IConfig } from 'ngx-mask'

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LayoutModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxMaskModule.forRoot()
  ],
  declarations: [
    AnonynoumsComponent,
    LoginComponent,
    RecuperarSenhaComponent,
    AlterarSenhaComponent,
    AlterarDadosPessoaisComponent]
})
export class AnonynoumsModule { }
