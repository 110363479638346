import { Component, OnInit, ViewChild } from '@angular/core';
import { NodeClickEventArgs, TreeViewComponent } from '@syncfusion/ej2-angular-navigations';
import { take } from 'rxjs/operators';
import { RelatorioCategoriaEntity } from '../../entities/relatorio-categoria.entity';
import { RelatorioFiltroEntity } from '../../entities/relatorio-filtro.entity';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';

import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { AlertService } from '../../shared/alert.service';
import { CommonService } from '../../shared/common.service';
import { RelatorioService } from '../relatorios.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Component({
  selector: 'app-report-dynamic',
  templateUrl: './report-dynamic.component.html',
  styleUrls: ['./report-dynamic.component.css']
})
export class ReportDynamicComponent extends ComponentBase implements OnInit {



  dataSource: Object[] = [];
  @ViewChild('treevalidate', { static: true }) treevalidate: TreeViewComponent;
  field: Object = { dataSource: this.dataSource, id: 'id', parentID: 'pid', text: 'name', hasChildren: 'hasChild' };
  filtros: RelatorioFiltroEntity[];
  titulo: string = "FILTROS";
  dados: [] = [];
  columns: [] = [];
  codigoRelatorio: number;
  total: any = [];
  totalRegistros: number;
  observacao: string;
  relatorios: RelatorioCategoriaEntity[];

  constructor(
    private relatorioServices: RelatorioService,
    private session: SessionService,
    protected alert: AlertService,
    private common: CommonService

  ) {
    super();
  }

  ngOnInit() {
    this.loading2<RelatorioCategoriaEntity[]>(() => this.relatorioServices.listarRelatoriosPorCategoria())
      .pipe(take(1))
      .subscribe(resp => {
        this.relatorios = resp;
        this.convertToTreeViewDataSource(resp);
      });
  }

  convertToTreeViewDataSource(relatorios: RelatorioCategoriaEntity[]) {
    let result = [];
    for (let x = 0; x < relatorios.length; x++) {
      let categoria = relatorios[x];
      let hasChild = false;

      for (let y = 0; y < categoria.relatorios.length; y++) {
        let relatorio = categoria.relatorios[y];
        debugger;
        if (relatorio.idRecurso > 0) {
          if (this.possuiAcessoRecurso(relatorio.idRecurso)) {
            if (relatorio.usoInterno) {
              if (this.session.usuario.funcionario) {
                result.push({
                  id: `${relatorio.codigoRelatorio}`, pid: categoria.codigoCategoria, name: relatorio.nomeRelatorio
                });

                hasChild = true;
              }
            } else {
              result.push({
                id: `${relatorio.codigoRelatorio}`, pid: categoria.codigoCategoria, name: relatorio.nomeRelatorio
              });

              hasChild = true;
            }
          }
        }
      }

      if (hasChild)
        result.push({ id: categoria.codigoCategoria, name: categoria.nomeCategoria, hasChild: true });

    }
    // for (let x = 0; x < relatorios.length; x++) {
    //   let categoria = relatorios[x];
    //   let hasChild = false;

    //   for (let y = 0; y < categoria.relatorios.length; y++) {

    //     let relatorio = categoria.relatorios[y];
    //     if (this.session.usuario.funcionario) {
    //       result.push({
    //         id: `${relatorio.codigoRelatorio}`, pid: categoria.codigoCategoria, name: relatorio.nomeRelatorio
    //       });

    //       hasChild = true;
    //     }
    //     else {
    //       if (!relatorio.usoInterno) {
    //         result.push({
    //           id: `${relatorio.codigoRelatorio}`, pid: categoria.codigoCategoria, name: relatorio.nomeRelatorio
    //         });

    //         hasChild = true;
    //       }
    //     }

    //     if (hasChild)
    //       result.push({ id: categoria.codigoCategoria, name: categoria.nomeCategoria, hasChild: true });
    //   }
    // }

    this.dataSource = result;
    this.field = { dataSource: this.dataSource, id: 'id', parentID: 'pid', text: 'name', hasChildren: 'hasChild' };
  }

  nodeCheck(args: NodeClickEventArgs): void {
    let getNodeDetails: any = this.treevalidate.getNode(args.node);
    if (getNodeDetails.parentID) {
      this.carregarFiltrosConsulta(getNodeDetails.id);
      this.codigoRelatorio = getNodeDetails.id;
      this.carregarDadosRelatorio(getNodeDetails.id);
      this.dados = [];
      this.columns = [];
    } else {
      this.treevalidate.collapseAll();
    }
  }

  carregarDadosRelatorio(codigoRelatorio: number) {
    for (let x = 0; x < this.relatorios.length; x++) {
      for (let y = 0; y < this.relatorios[x].relatorios.length; y++) {
        if (this.relatorios[x].relatorios[y].codigoRelatorio === Number(codigoRelatorio)) {
          this.titulo = this.relatorios[x].relatorios[y].nomeRelatorio;
          this.observacao = this.relatorios[x].relatorios[y].observacao;
        }
      }
    }
    return "";
  }

  carregarFiltrosConsulta(codigoRelatorio: number) {
    this.loadingGestores<RelatorioFiltroEntity[]>(this.relatorioServices, "consultarFiltros", [codigoRelatorio])
      .pipe(take(1))
      .subscribe(resp => {

        this.filtros = this.carregarDefaultMasks(resp);
      });
  }

  pesquisar() {
    this.total = [];
    let filtroValor = [];
    for (let x = 0; x < this.filtros.length; x++)
      filtroValor.push({ nomeCampo: this.filtros[x].nomeCampo, valorCampo: this.filtros[x].valor });

    this.loadingGestores<any>(this.relatorioServices, "consultarDadosRelatorio", [this.codigoRelatorio, filtroValor])
      .pipe(take(1))
      .subscribe(resp => {
        this.columns = resp[0];
        this.dados = resp;
        this.totalRegistros = resp.length;

        if (resp.length === 0) {
          this.alert.info("Nenhum registro foi localizado.");
          return;
        }

        for (let x = 0; x < resp.length; x++) {
          for (let y = 0; y < resp[x].length; y++) {
            if (resp[x][y].nomeCampo.indexOf('QTD') > -1 || resp[x][y].nomeCampo.indexOf('VLR') > -1)
              this.setTotal(resp[x][y].nomeCampo, resp[x][y].valorCampo);
          }
        }
      });
  }

  carregarDefaultMasks(filtros: RelatorioFiltroEntity[]): RelatorioFiltroEntity[] {

    for (let x = 0; x < filtros.length; x++) {
      if (filtros[x].nomeCampo.toLowerCase() === '@idcliente')
        filtros[x].valor = this.session.getIdsEmpresa();

      if (filtros[x].nomeCampo.toLowerCase() === '@iddivisao')
        filtros[x].valor = this.session.getIdsDivisoes();

      filtros[x].tipoCampo = filtros[x].tipoCampo.toLowerCase();
      if (filtros[x].tipoCampo === 'texto') {
        if (filtros[x].mascara)
          filtros[x].defaultMask = filtros[x].mascara;
      }
      else {
        if (filtros[x].tipoCampo === 'inteiro') {
          if (filtros[x].mascara)
            filtros[x].defaultMask = filtros[x].mascara;
          else
            filtros[x].defaultMask = "0000000000";
        } else {
          filtros[x].defaultMask = filtros[x].tipoCampo;
        }
      }
    }

    return filtros;
  }

  exportar() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(document.getElementById('dtResultado'));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Gerar buffer binário
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Chamar o método saveAs do file-saver para salvar o arquivo
    this.saveAsExcelFile(excelBuffer, this.titulo);
    // let filtroValor = [];
    // for (let x = 0; x < this.filtros.length; x++)
    //   filtroValor.push({ nomeCampo: this.filtros[x].nomeCampo, valorCampo: this.filtros[x].valor });

    // this.loadingGestores<any>(this.relatorioServices, "gerarPlanilha", [this.codigoRelatorio, filtroValor])
    //   .pipe(take(1))
    //   .subscribe((res) => {
    //     this.common.downloadFile(res, `${this.titulo}.xlsx`);
    //   });
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    saveAs(data, `${fileName}${EXCEL_EXTENSION}`);
  }

  setTotal(field: string, value: string) {
    if (!this.total[field] || isNaN(this.total[field]))
      this.total[field] = 0;

    if (value)
      this.total[field] += value;
  }
}
