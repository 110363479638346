export class ProdutoConstant {
  static statusProduto = [
    { id: 0, name: '--Todos os status--' },
    { id: 1, name: 'Abaixo do Estoque Mínimo' },
    { id: 2, name: 'Inferior a 30 dias' },
    { id: 3, name: 'Entre 30 e 90 dias' },
    { id: 4, name: 'Acima de 90 dias' },
    { id: 5, name: 'Não Aplicável' }
  ];

  static dropdownStatusProduto = [
    { id: 2, name: 'Inferior a 30 dias' },
    { id: 3, name: 'Entre 30 e 90 dias' },
    { id: 4, name: 'Acima de 90 dias' }
  ];

  static coresStatusProduto = [
    { id: 1, name: 'label label-lg font-weight-bold  label-light-danger label-inline' },
    { id: 2, name: 'label label-lg font-weight-bold  label-light-success label-inline' },
    { id: 3, name: 'label label-lg font-weight-bold  label-light-info label-inline' },
    { id: 4, name: 'label label-lg font-weight-bold  label-light-warning label-inline' },
    { id: 5, name: 'label label-lg font-weight-bold  label-light-warning label-inline' },
  ];

  static tipoMovimentacaoEstoque = [
    { id: 'E', name: 'Entrada' },
    { id: 'S', name: 'Saída' }
  ]
}
