export class LocalizacaoProdutoEntity {
  id: number;
  armazem: string;
  rua: string;
  baia: string;
  nivel: string;
  areaArmazem: string;
  tamanhoLocalizacao: string;
  selecionado: boolean;
  qtdProduto: number;

  idArmazem: number;
  idRua: number;
  idBaia: number;
  idNivel: number;
}
