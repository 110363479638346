import { Component, OnInit } from '@angular/core';
import { ComponentBase } from '../../shared/component-base';
import { DivisaoEntity } from '../../cadastro/entities/divisao.entity';
import { DivisaoService } from '../divisao.service';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-divisao-configuracao',
  templateUrl: './divisao-configuracao.component.html',
  styleUrls: ['./divisao-configuracao.component.css']
})
export class DivisaoConfiguracaoComponent extends ComponentBase implements OnInit {

  divisoes: DivisaoEntity[] = [];

  constructor(
    private divisaoService: DivisaoService,
    private toastService: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    this.loading2<DivisaoEntity[]>(() => this.divisaoService.listarAgrupada())
      .pipe(take(1))
      .subscribe(divisoes => this.divisoes = divisoes);
  }

  marcarDesmarcarIgnorarSaldoVirtual(divisao: DivisaoEntity) {
    divisao.ignorarSaldoVirtual = !divisao.ignorarSaldoVirtual;

    this.divisaoService.atualizarDivisaoUtilizaSaldoVirtual(divisao)
      .pipe(take(1))
      .subscribe(() => {
        if (divisao.ignorarSaldoVirtual)
          this.toastService.success('Divisão incluída com sucesso!', 'Configuração de Divisões');
        else
          this.toastService.success('Divisão removida com sucesso!', 'Configuração de Divisões');

    
      });

  }

}
