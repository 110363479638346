export class SolicitacaoEstoquePessoaDivisaoEntity {
  idSolicitacaoProdutoSaldoVirtual: number;
  idUsuario: number;
  idProduto: number;
  quantidade: number;
  situacao: string;
  dataSolicitacao: string;
  observacao: string;
  idPessoaDivisao: number;
  idCliente: number;
  idPessoaDivisaoDestino: number;
  nomeUsuario: string;
  nomeDivisao: string;
  nomeDivisaoDestino: string;
  status: string;
  nomeProduto: string;
}
