import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartComponent, ChartTheme, ILoadedEventArgs } from '@syncfusion/ej2-angular-charts';
import { take } from 'rxjs/operators';
import { ConsultarDadosDashboardRequestEntity } from '../../entities/Requests/consultar-dados-dashboard.request.entity';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-pedidos-marca',
  templateUrl: './pedidos-marca.component.html',
  styleUrls: ['./pedidos-marca.component.css']
})
export class PedidosMarcaComponent extends ComponentBase implements OnInit {
  dataset = [];
  novo: boolean = true;
  @ViewChild('chart', { static: true }) chart: ChartComponent;

  constructor(
    private session: SessionService,
    private dashboardService: DashboardService
  ) {
    super();
  }

  ngOnInit() {
    let entity = new ConsultarDadosDashboardRequestEntity();
    const currentDate = new Date();
    entity.clientes = this.session.getIdsEmpresa();
    entity.divisoes = this.session.getIdDivisoes();
    entity.dataInicial = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0);
    entity.dataFinal = currentDate;

    this.filtrarDados(entity);
  }

  filtrarDados(entity: ConsultarDadosDashboardRequestEntity) {
    this.novo = true;
    setTimeout(() => {
      this.loading2<any[]>(() => this.dashboardService.obterPedidosMarca(entity))
        .pipe(take(1))
        .subscribe(resp => {
          this.novo = false;
          this.dataset = resp;

        });
    }, 100);
  }

  public loaded(args: ILoadedEventArgs): void {
    if (this.novo)
      args.chart.refresh();
  }

  public legend: Object = {
    position: 'Auto'
  }

  public width: string = '99%';

  public marker1: Object = {
    dataLabel: {
      name: 'text',
      visible: true,
      position: 'Top',
      font: {
        fontWeight: '600'
      }
    }
  }

  //Initializing Primary X Axis
  public primaryXAxis: Object = {
    majorGridLines: { width: 0 },
    minorGridLines: { width: 0 },
    majorTickLines: { width: 0 },
    minorTickLines: { width: 0 },
    interval: 1,
    setRange: true,
    lineStyle: { width: 0 },
    labelIntersectAction: 'Rotate45',
    valueType: 'Category'
  };
  //Initializing Primary Y Axis
  public primaryYAxis: Object = {
    title: 'Pedidos',
    setRange: true,
    lineStyle: { width: 0 },
    majorTickLines: { width: 0 },
    majorGridLines: { width: 1 },
    minorGridLines: { width: 1 },
    minorTickLines: { width: 0 },
    labelFormat: '{value}',
  };
  public tooltip: Object = {
    enable: true
  };
  // custom code start
  public load(args: ILoadedEventArgs): void {
    let selectedTheme: string = location.hash.split('/')[1];
    selectedTheme = selectedTheme ? selectedTheme : 'Material';
    args.chart.theme = <ChartTheme>(selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark");
  };
  // custom code end
  public title: string = 'Pedidos por Marca x Mês';
  public chartArea: Object = {
    border: {
      width: 0
    }
  };
}
