import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { ConsultarProdutoOportunidadeResponseEntity } from '../../entities/Responses/consultar-produto-oportunidade-response.entity';
import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { ProdutoService } from '../produto.service';

@Component({
  selector: 'app-produto-baixa-utilizacao',
  templateUrl: './produto-oportunidade.component.html',
  styleUrls: ['./produto-oportunidade.component.css']
})
export class ProdutoOportunidadeComponent extends ComponentBase implements OnInit {
  produtos: ConsultarProdutoOportunidadeResponseEntity[] = [];

  constructor(
    private session: SessionService,
    private produtoService: ProdutoService,
    protected alert: AlertService
  ) {
    super();
  }

  ngOnInit() {
    if (!this.session.empresa) {
      this.alert.warning("Antes de prosseguir é necessário selecionar uma empresa.")
        .then(value => {
          this.session.urlReturn = window.location.href;
          window.location.href = '/empresa/selecionar';
        });

      return;
    }

    this.loading2<ConsultarProdutoOportunidadeResponseEntity[]>(() => this.produtoService.listarOportunidadesMDPV(this.session.empresa.idCliente, 3366))
      .pipe(take(1))
      .subscribe((resp) => {
        this.produtos = resp;
      });
  }
}
