import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { ConsultarDadosDashboardRequestEntity } from '../../entities/Requests/consultar-dados-dashboard.request.entity';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'app-pedidos-vendedor-coordenador',
  templateUrl: './pedidos-vendedor-coordenador.component.html',
  styleUrls: ['./pedidos-vendedor-coordenador.component.css']
})
export class PedidosVendedorCoordenadorComponent extends ComponentBase implements OnInit {

  dataset = [];

  constructor(
    private session: SessionService,
    private dashboardService: DashboardService
  ) {
    super();
  }

  ngOnInit() {
    let entity = new ConsultarDadosDashboardRequestEntity();
    const currentDate = new Date();
    entity.clientes = this.session.getIdsEmpresa();
    entity.divisoes = this.session.getIdDivisoes();
    entity.dataInicial = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0);
    entity.dataFinal = currentDate;

    this.filtrarDados(entity);
  }

  filtrarDados(entity:ConsultarDadosDashboardRequestEntity){
    this.loading2<any[]>(() => this.dashboardService.obterVendedoresPorRegional(entity))
      .pipe(take(1))
      .subscribe(resp => {
        this.dataset = resp;
      });
  }
}
