import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loadding',
  templateUrl: './loadding.component.html',
  styleUrls: ['./loadding.component.css']
})
export class LoaddingComponent implements OnInit {

  showLoading: boolean = true;

  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      this.showLoading = false;
    }, 5000)
  }

}
