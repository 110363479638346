import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { FichaProdutoEntity } from "../entities/ficha-produto.entity";
import { DistribuirSaldoVirtualRequestEntity } from './../entities/Requests/distrbuir-produto-saldo-virtual-request.entity';

import { environment } from '../../environments/environment';
import { ConsultarLocalizacaoProdutoRequestEntity } from '../entities/Requests/consultar-localizacao-produto-request.entity';
import { ConsultarProdutoRequestEntity } from "../entities/Requests/consultar-produto-request.entity";
import { DevolverProdutoGestorRequestEntity } from '../entities/Requests/devolver-produto-gestor-response.entity';
import { IncluirEntradaProdutoSemNfRequestEntity } from '../entities/Requests/incluir-entrada-produto-sem-nf-request.entity';
import { ConsultarProdutoOportunidadeResponseEntity } from '../entities/Responses/consultar-produto-oportunidade-response.entity';
import { ArmazenLocalizacaoProdutoEntity } from '../entities/armazem-localizacao-produto.entity';
import { BaiaLocalizacaoProdutoEntity } from '../entities/baia-localizacao-produto.entity';
import { ConfiguracaoKitEntity } from '../entities/configuracao-kit.entity';
import { FaixaKitEntity } from '../entities/faixa-kit.entity';
import { GrupoProdutoEntity } from '../entities/grupo-produto.entity';
import { LocalizacaoProdutoEntity } from '../entities/localizacao-produto.entity';
import { NivelLocalizacaoProdutoEntity } from '../entities/nivel-localizacao-produto.entity';
import { ProdutoKitEntity } from '../entities/produto-kit.entity';
import { ProdutoEntity } from "../entities/produto.entity";
import { RuaLocalizacaoProdutoEntity } from '../entities/rua-localizacao-produto.entity';
import { SolicitacaoEstoquePessoaDivisaoEntity } from '../entities/solicitacao-estoque-pessoa-divisao.entity';
import { SolicitacaoProdutoSaldoVirtualEntity } from '../entities/solicitacao-produto-saldo-virtual.entity';
import { ServiceBase } from "../shared/service-base";
import { SessionService } from "../shared/session.service";
import { CodigoFiscalEntity } from './entities/codigo-fiscal.entity';
import { FacetProdutoEntity } from './entities/facet-produto.entity';
import { ImagemProdutoEntity } from './entities/imagem-produto.entity';
import { MovimentoProdutoEntity } from './entities/movimento-produto.entity';
import { ProdutoClienteEntity } from './entities/produto-cliente.entity';
import { AtualizarImagemProdutoRequestEntity } from './entities/requests/atualizar-imagem-produto-request.entity';
import { ConsultarEntradaProdutoRequestEntity } from './entities/requests/consultar-entrada-produto-request.entity';
import { ConsultarTagsFacetRequestEntity } from './entities/requests/consultar-facet-request.entity';
import { TransferirProdutoLocalizacaoRequestEntity } from './entities/requests/transferir-produto-localizacao-request.entity';
import { ConsultarEntradaProdutoResponseEntity } from './entities/response/consultar-entrada-produto-response.entity';
import { ConsultarProdutoEstoqueVirtualResponseEntity } from './entities/response/consultar-produto-estoque-virtual-response.entity';
import { ConsultarProdutosRelacionadosResponseEntity } from './entities/response/consultar-produto-relacionados-response.entity';
import { ConsultarSaldoProdutoResponseEntity } from './entities/response/consultar-saldo-produto-response.entity';
import { ListarUnidadesMedidasResponseEntity } from './entities/response/listar-unidades-medidas-response.entity';
import { MovimentoProdutoResponseEntity } from './entities/response/movimento-produto-response.entity';
import { TagProdutoEntity } from './entities/tag-produto.emtity';
import { TransferenciaProdutoDivisaoRequestEntity } from './entities/transferencia-produto-divisao-request.entity';

@Injectable({
  providedIn: 'root'
})
export class ProdutoService extends ServiceBase {

  constructor(
    private http: HttpClient,
    private session: SessionService
  ) { super(); }

  consultarProdutos(request: ConsultarProdutoRequestEntity): Observable<ProdutoEntity> {
    return this.http.post<ProdutoEntity>(`${environment.baseUrl}api/produto/ConsultarProduto`, request);
  }

  consultarFichaProduto(idCliente: number, idProduto: number): Observable<FichaProdutoEntity> {
    return this.http.get<FichaProdutoEntity>(`${environment.baseUrl}api/produto/ConsultarFichaProduto/${idCliente}/${idProduto}`);
  }

  consultarGruposProduto(empresas: string): Observable<GrupoProdutoEntity[]> {
    return this.http.get<GrupoProdutoEntity[]>(`${environment.baseUrl}api/produto/ConsultarGrupoProduto/?empresas=${empresas}`);
  }

  listarConfiguracaoKit(empresas: string): Observable<ConfiguracaoKitEntity[]> {
    return this.http.get<ConfiguracaoKitEntity[]>(`${environment.baseUrl}api/produto/ListarConfiguracaoKit/?empresas=${empresas}`);
  }

  consultarConfiguracaoKit(codigo: number): Observable<ConfiguracaoKitEntity[]> {
    return this.http.get<ConfiguracaoKitEntity[]>(`${environment.baseUrl}api/produto/consultarConfiguracaoKit/${codigo}`);
  }

  consultarFaixaKit(codigoConfiguracaoKit: number): Observable<FaixaKitEntity[]> {
    return this.http.get<FaixaKitEntity[]>(`${environment.baseUrl}api/produto/consultarFaixaKit/${codigoConfiguracaoKit}`);
  }

  consultarProdutoKit(codigoFaixaKit: number): Observable<ProdutoEntity[]> {
    return this.http.get<ProdutoEntity[]>(`${environment.baseUrl}api/produto/ConsultarProdutosKit/${codigoFaixaKit}`);
  }

  inserirConfiguracaoKit(request: ConfiguracaoKitEntity): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}api/produto/inserirConfiguracaoKit`, request);
  }

  alterarConfiguracaoKit(request: ConfiguracaoKitEntity): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}api/produto/alterarConfiguracaoKit`, request);
  }

  excluirConfiguracaoKit(codigo: number): Observable<any> {
    return this.http.delete<any>(`${environment.baseUrl}api/produto/excluirConfiguracaoKit/${codigo}`);
  }

  consultarFaixaKitPorCodigo(codigoFaixaKit: number): Observable<FaixaKitEntity> {
    return this.http.get<FaixaKitEntity>(`${environment.baseUrl}api/produto/consultarFaixaKitPorCodigo/${codigoFaixaKit}`);
  }

  associarProdutos(request: FaixaKitEntity): Observable<any> {
    return this.http.post<any>(`${environment.baseUrl}api/produto/AssociarProdutos`, request);
  }

  consultarProdutosKitProdutoSelecionado(codigoFaixaKit: number, codigoProduto: number, codigoFaixaKitAnterior: number): Observable<ProdutoKitEntity[]> {
    return this.http.get<ProdutoKitEntity[]>(`${environment.baseUrl}api/produto/ConsultarProdutosKitProdutoSelecionado/${codigoFaixaKit}/${codigoProduto}/${codigoFaixaKitAnterior}`);
  }

  consultarProdutoKitPorCodigoPai(codigoFaixaKit: number, codigoProduto: number): Observable<ProdutoKitEntity[]> {
    return this.http.get<ProdutoKitEntity[]>(`${environment.baseUrl}api/produto/ConsultarProdutoKitPorCodigoPai/${codigoFaixaKit}/${codigoProduto}`);
  }

  listarArmazemLocalizacaoProduto(): Observable<ArmazenLocalizacaoProdutoEntity[]> {
    return this.http.get<ArmazenLocalizacaoProdutoEntity[]>(`${environment.baseUrl}api/produto/ListarArmazemLocalizacaoProduto/`);
  }

  listarRuaLocalizacaoProduto(): Observable<RuaLocalizacaoProdutoEntity[]> {
    return this.http.get<RuaLocalizacaoProdutoEntity[]>(`${environment.baseUrl}api/produto/ListarRuaLocalizacaoProduto/`);
  }

  listarBaiaLocalizacaoProduto(): Observable<BaiaLocalizacaoProdutoEntity[]> {
    return this.http.get<BaiaLocalizacaoProdutoEntity[]>(`${environment.baseUrl}api/produto/ListarBaiaLocalizacaoProduto/`);
  }

  listarNivelLocalizacaoProduto(): Observable<NivelLocalizacaoProdutoEntity[]> {
    return this.http.get<NivelLocalizacaoProdutoEntity[]>(`${environment.baseUrl}api/produto/listarNivelLocalizacaoProduto/`);
  }

  inserirProdutosEstoqueSemNfEntrada(itens: IncluirEntradaProdutoSemNfRequestEntity[]): Observable<void> {
    return this.http.post<void>(`${environment.baseUrl}api/produto/inserirProdutosEstoqueSemNfEntrada/`, itens);
  }

  consultarLocalizacaoProduto(request: ConsultarLocalizacaoProdutoRequestEntity): Observable<LocalizacaoProdutoEntity[]> {
    let url = `${environment.baseUrl}api/produto/ConsultarLocalizacaoProduto/?t=1`;
    if (request.idArmazem)
      url += `&IdArmazem=${request.idArmazem}`;

    if (request.idRua)
      url += `&idRua=${request.idRua}`;

    if (request.idBaia)
      url += `&idBaia=${request.idBaia}`;

    if (request.idNivel)
      url += `&idNivel=${request.idNivel}`;

    return this.http.get<LocalizacaoProdutoEntity[]>(url);
  }

  getImagemProduto(codigoProduto: string): string {
    let url = this.session.pathImages;
    return `${url}/${codigoProduto}/0.jpg`;
  }

  listarCodigosFiscais(): Observable<CodigoFiscalEntity[]> {
    return this.http.get<CodigoFiscalEntity[]>(`${environment.baseUrl}api/produto/ListarCodigosFiscais/`);
  }

  consultarSaldoProduto(codigoProduto: number): Observable<ConsultarSaldoProdutoResponseEntity> {
    return this.http.get<ConsultarSaldoProdutoResponseEntity>(`${environment.baseUrl}api/produto/ConsultarSaldoProduto/${codigoProduto}`);
  }

  consultarMovimentoProduto(codigoProduto: number): Observable<MovimentoProdutoResponseEntity[]> {
    return this.http.get<MovimentoProdutoResponseEntity[]>(`${environment.baseUrl}api/produto/ConsultarMovimentoProduto/${codigoProduto}`);
  }

  inserirProduto(request: ProdutoClienteEntity): Observable<number> {
    return this.http.post<number>(`${environment.baseUrl}api/Produto/InserirProduto`, request);
  }

  alterarProduto(request: ProdutoClienteEntity): Observable<void> {
    return this.http.put<void>(`${environment.baseUrl}api/Produto/AtualizarProduto`, request);
  }

  consultarProdutosCliente(idCliente: number): Observable<ProdutoClienteEntity[]> {
    return this.http.get<ProdutoClienteEntity[]>(`${environment.baseUrl}api/Produto/ConsultarProdutos/${idCliente}`);
  }

  importarImagemProduto(imagemProduto: File, codigoProduto: number): Observable<any> {
    const formData = new FormData();
    formData.append('imagemProduto', imagemProduto, imagemProduto.name);

    return this.http.post<any>(`${environment.baseUrl}api/Produto/ImportarImagemProduto/${codigoProduto}`, formData, { observe: "events" });
  }

  consultarImagensProduto(codigoProduto: number): Observable<ImagemProdutoEntity[]> {
    return this.http.get<ImagemProdutoEntity[]>(`${environment.baseUrl}api/Produto/ConsultarImagensProduto/${codigoProduto}`);
  }

  excluirImagemProduto(request: AtualizarImagemProdutoRequestEntity): Observable<void> {
    return this.http.put<void>(`${environment.baseUrl}api/Produto/excluirImagemProduto`, request);
  }

  ordenarImagensProduto(request: AtualizarImagemProdutoRequestEntity[]): Observable<void> {
    return this.http.put<void>(`${environment.baseUrl}api/Produto/ordenarImagensProduto`, request);
  }

  consultarImagemProduto(codigoProduto: number): Observable<string[]> {
    return this.http.get<string[]>(`${environment.baseUrl}api/Produto/ConsultarImagemProduto/${codigoProduto}`);
  }

  listarUnidadesMedidas(): Observable<ListarUnidadesMedidasResponseEntity[]> {
    return this.http.get<ListarUnidadesMedidasResponseEntity[]>(`${environment.baseUrl}api/Produto/ListarUnidadesMedidas`);
  }

  incluirEntradaProduto(itens: IncluirEntradaProdutoSemNfRequestEntity[]): Observable<void> {
    return this.http.post<void>(`${environment.baseUrl}api/produto/IncluirEntradaProduto/`, itens);
  }

  incluirSaldoVirtual(entity: DistribuirSaldoVirtualRequestEntity): Observable<void> {
    return this.http.post<void>(`${environment.baseUrl}api/produto/IncluirSaldoVirtual/`, entity);
  }

  devolverProdutoDivisao(entity: DevolverProdutoGestorRequestEntity[]): Observable<void> {
    return this.http.post<void>(`${environment.baseUrl}api/produto/DevolverProdutoDivisao/`, entity);
  }

  devolverProdutoGestor(entity: any): Observable<void> {
    return this.http.post<void>(`${environment.baseUrl}api/produto/DevolverProdutoGestor/`, entity);
  }

  inserirSolicitacaoProdutoSaldoVirtual(entity: SolicitacaoProdutoSaldoVirtualEntity): Observable<void> {
    return this.http.post<void>(`${environment.baseUrl}api/produto/InserirSolicitacaoProdutoSaldoVirtual/`, entity);
  }

  inserirSolicitacaoEstoquePessoaDivisao(entity: SolicitacaoEstoquePessoaDivisaoEntity): Observable<void> {
    return this.http.post<void>(`${environment.baseUrl}api/produto/InserirSolicitacaoEstoquePessoaDivisao/`, entity);
  }

  consultarSolicitacoesEstoquePessoaDivisao(idCliente: number = null, idUsuario: number = null): Observable<SolicitacaoEstoquePessoaDivisaoEntity[]> {
    let params = "";
    if (idCliente)
      params += `&idCliente=${idCliente}`;
    if (idUsuario)
      params += `&idUsuario=${idUsuario}`;

    return this.http.get<SolicitacaoEstoquePessoaDivisaoEntity[]>(`${environment.baseUrl}api/produto/ConsultarSolicitacaoEstoquePessoaDivisao?x=1${params}`);
  }

  consultarSolicitacoesProdutoSaldoVirtual(divisoes:string, idCliente: number = null, idUsuario: number = null): Observable<SolicitacaoProdutoSaldoVirtualEntity[]> {
    let params = "";
    if (idCliente)
      params += `&idCliente=${idCliente}`;

      if (idUsuario)
      params += `&idUsuario=${idUsuario}`;

      if (divisoes)
        params += `&divisoes=${divisoes}`;

    return this.http.get<SolicitacaoProdutoSaldoVirtualEntity[]>(`${environment.baseUrl}api/produto/ConsultarSolicitacaoProdutoSaldoVirtual?x=1${params}`);
  }

  atualizarSituacaoSolicitacaoSaldoVirtual(entity: SolicitacaoProdutoSaldoVirtualEntity): Observable<void> {
    return this.http.put<void>(`${environment.baseUrl}api/produto/AtualizarSituacaoSolicitacaoSaldoVirtual/`, entity);
  }

  atualizarSituacaoSolicitacaoEstoquePessoaDivisao(entity: SolicitacaoEstoquePessoaDivisaoEntity): Observable<void> {
    return this.http.put<void>(`${environment.baseUrl}api/produto/AtualizarSituacaoSolicitacaoEstoquePessoaDivisao/`, entity);
  }

  consultarProdutosRelacionados(idProduto: number): Observable<ConsultarProdutosRelacionadosResponseEntity[]> {
    return this.http.get<ConsultarProdutosRelacionadosResponseEntity[]>(`${environment.baseUrl}api/Produto/ConsultarProdutosRelacionados/${idProduto}`);
  }

  inserirTagProduto(tagProduto: TagProdutoEntity): Observable<ConsultarProdutosRelacionadosResponseEntity[]> {
    return this.http.post<ConsultarProdutosRelacionadosResponseEntity[]>(`${environment.baseUrl}api/Produto/InserirTagProduto`, tagProduto);
  }

  excluirTagProduto(tagProduto: TagProdutoEntity): Observable<ConsultarProdutosRelacionadosResponseEntity[]> {
    let params = new HttpParams();
    params = params.append('idProduto', tagProduto.idProduto.toString());
    params = params.append('idTag', tagProduto.idTag.toString());
    params = params.append('idCliente', tagProduto.idCliente.toString());

    return this.http.delete<ConsultarProdutosRelacionadosResponseEntity[]>(`${environment.baseUrl}api/Produto/ExcluirTagProduto`, { params: params });
  }

  consultarTagsPorProduto(idProduto: number): Observable<TagProdutoEntity[]> {
    return this.http.get<TagProdutoEntity[]>(`${environment.baseUrl}api/Produto/ConsultarTagsPorProduto/${idProduto}`);
  }

  consultarTagsFacet(request: ConsultarTagsFacetRequestEntity): Observable<FacetProdutoEntity[]> {
    return this.http.post<FacetProdutoEntity[]>(`${environment.baseUrl}api/Produto/ConsultarTagsFacet`, request);
  }

  consultarProdutosComFiltro(request: ConsultarProdutoRequestEntity): Observable<ProdutoEntity> {
    return this.http.post<ProdutoEntity>(`${environment.baseUrl}api/produto/ConsultarProdutoComFiltro`, request);
  }

  transferirProdutoEntreDivisoes(request: TransferenciaProdutoDivisaoRequestEntity): Observable<void> {
    return this.http.put<void>(`${environment.baseUrl}api/produto/TransferirProdutoEntreDivisoes`, request);
  }

  consultarProdutoEstoqueVirtual(idCliente: number, idPessoaDivisao: number): Observable<ConsultarProdutoEstoqueVirtualResponseEntity[]> {
    return this.http.get<ConsultarProdutoEstoqueVirtualResponseEntity[]>(`${environment.baseUrl}api/produto/ConsultarProdutoEstoqueVirtual/${idCliente}/${idPessoaDivisao}`);
  }

  devolverProdutoEstoqueVirtual(idCliente: number, idPessoaDivisao: number, idProduto: number, quantidade: number): Observable<ConsultarProdutoEstoqueVirtualResponseEntity[]> {
    return this.http.put<ConsultarProdutoEstoqueVirtualResponseEntity[]>(`${environment.baseUrl}api/produto/devolverProdutoEstoqueVirtual/${idCliente}/${idPessoaDivisao}/${idProduto}/${quantidade}`, null);
  }

  listarOportunidadesMDPV(idCliente: number, idPessoaDivisao: number): Observable<ConsultarProdutoOportunidadeResponseEntity[]> {
    return this.http.get<ConsultarProdutoOportunidadeResponseEntity[]>(`${environment.baseUrl}api/produto/ListarOportunidadesMDPV/${idCliente}/${idPessoaDivisao}`);
  }

  consultarEntradaProdutos(entity: ConsultarEntradaProdutoRequestEntity): Observable<ConsultarEntradaProdutoResponseEntity[]> {
    return this.http.get<ConsultarEntradaProdutoResponseEntity[]>(`${environment.baseUrl}api/v2/produto/entrada/${entity.toQueryString()}`);
  }

  estornarEntradaProduto(idEntrada: number): Observable<void> {
    return this.http.delete<void>(`${environment.baseUrl}api/v2/produto/entrada/${idEntrada}`);
  }

  consultarLocalizacaoPorCodigoProduto(idProduto: number): Observable<LocalizacaoProdutoEntity[]> {
    return this.http.get<LocalizacaoProdutoEntity[]>(`${environment.baseUrl}api/v2/produto/localizacao/${idProduto}`);
  }

  transferirProdutoLocalizacao(entity: TransferirProdutoLocalizacaoRequestEntity): Observable<void> {
    return this.http.put<void>(`${environment.baseUrl}api/v2/produto/localizacao/`, entity);
  }

  incluirMovimento(entity: MovimentoProdutoEntity): Observable<void> {
    return this.http.post<void>(`${environment.baseUrl}api/v2/produto/movimento/`, entity);
  }
}
