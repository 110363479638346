import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DestinatarioService } from '../../destinatario/destinatario.service';
import { PessoaEntity } from '../../entities/pessoa.entity';
import { ComponentBase } from '../../shared/component-base';


@Component({
  selector: 'app-selecionar-destinatarios',
  templateUrl: './selecionar-destinatarios.component.html',
  styleUrls: ['./selecionar-destinatarios.component.css']
})
export class SelecionarDestinatariosComponent extends ComponentBase implements OnInit {

  @Output() onAddRemoveItem: EventEmitter<any> = new EventEmitter();

  constructor(
    private destinatario: DestinatarioService
  ) { super(); }

  ngOnInit() {
  }

  addRemoveItem(pessoa: PessoaEntity) {
    console.log(pessoa);
    this.onAddRemoveItem.emit(pessoa);
  }
}
