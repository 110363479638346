import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { PessoaJuridicaEntity } from '../../pessoa/entities/pessoa-juridica.entity';
import { PessoaJuridicaService } from '../../pessoa/services/pessoa-juridica.service';
import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { ConsultarEntradaProdutoRequestEntity } from '../entities/requests/consultar-entrada-produto-request.entity';
import { ConsultarEntradaProdutoResponseEntity } from '../entities/response/consultar-entrada-produto-response.entity';
import { ProdutoService } from '../produto.service';

@Component({
  selector: 'app-entrada-produto',
  templateUrl: './entrada-produto.component.html',
  styleUrls: ['./entrada-produto.component.css']
})
export class EntradaProdutoComponent extends ComponentBase implements OnInit {

  depositantes: PessoaJuridicaEntity[] = [];
  depositante: number;
  request: ConsultarEntradaProdutoRequestEntity = new ConsultarEntradaProdutoRequestEntity();
  entradas: ConsultarEntradaProdutoResponseEntity[] = [];
  constructor(
    public session: SessionService,
    private pessoaJuridicaService: PessoaJuridicaService,
    private produtoService: ProdutoService,
    protected alert: AlertService,
    protected router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.listarDepositantes();
  }

  listarDepositantes() {
    this.loading2<PessoaJuridicaEntity[]>(() => this.pessoaJuridicaService.listarPorTipo('depositante'))
      .pipe(take(1))
      .subscribe(resp => {
        this.depositantes = resp;
      }
      );
  }

  pesquisar() {
    if (this.session.empresa)
      this.request.idCliente = this.session.empresa.idCliente;

    this.loading2<ConsultarEntradaProdutoResponseEntity[]>(() => this.produtoService.consultarEntradaProdutos(this.request))
      .pipe(take(1))
      .subscribe((resp) => {
        if (resp.length === 0)
          this.alert.warning('Nenhuma entrada foi encontrada com os parametros informados');
        this.entradas = resp;
        console.log(resp);
      });
  }

  incluir() {
    this.router.navigate(['/produtos/nova-entrada-produto']);
  }

  estornar(entrada: ConsultarEntradaProdutoResponseEntity, idx: number) {
    this.alert.confirm("Tem certeza que deseja estornar essa entrada?")
      .then((resp) => {
        if (resp.value) {
          this.loading2<ConsultarEntradaProdutoResponseEntity[]>(() => this.produtoService.estornarEntradaProduto(entrada.idEntradaProduto))
            .pipe(take(1))
            .subscribe(() => {
              this.alert.success("Entrada de produtos estornada com sucesso.");
              this.entradas.splice(0, idx);
            });
        }
      });
  }
}
