import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-collapse',
  templateUrl: './menu-collapse.component.html',
  styleUrls: ['./menu-collapse.component.css']
})
export class MenuCollapseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
