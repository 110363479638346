import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-material-estoque-marca',
  templateUrl: './material-estoque-marca.component.html',
  styleUrls: ['./material-estoque-marca.component.css']
})
export class MaterialEstoqueMarcaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
