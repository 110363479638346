import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ChartTheme, ILoadedEventArgs } from '@syncfusion/ej2-angular-charts';
import { EmpresaEntity } from '../entities/empresa.entity';
import { ConsultarDadosDashboardRequestEntity } from '../entities/Requests/consultar-dados-dashboard.request.entity';
import { AlertService } from '../shared/alert.service';
import { ComponentBase } from '../shared/component-base';
import { SessionService } from '../shared/session.service';
import { KmRodadoFreteEstadoComponent } from './km-rodado-frete-estado/km-rodado-frete-estado.component';
import { KmRodadoMesComponent } from './km-rodado-mes/km-rodado-mes.component';
import { PedidosCoordenadorComponent } from './pedidos-coordenador/pedidos-coordenador.component';
import { PedidosEstadoComponent } from './pedidos-estado/pedidos-estado.component';
import { PedidosMarcaCoordenadorComponent } from './pedidos-marca-coordenador/pedidos-marca-coordenador.component';
import { PedidosMarcaPercentualComponent } from './pedidos-marca-percentual/pedidos-marca-percentual.component';
import { PedidosMarcaComponent } from './pedidos-marca/pedidos-marca.component';
import { PedidosVendedorCoordenadorComponent } from './pedidos-vendedor-coordenador/pedidos-vendedor-coordenador.component';
import { Top5PedidosEstadoComponent } from './top5-pedidos-estado/top5-pedidos-estado.component';
import { TotalizadoresComponent } from './totalizadores/totalizadores.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent extends ComponentBase implements OnInit {

  @ViewChild(TotalizadoresComponent, { static: false }) totalizadoresRef: TotalizadoresComponent;
  @ViewChild(KmRodadoFreteEstadoComponent, { static: false }) kmRodadoFreteEstadoComponentRef: KmRodadoFreteEstadoComponent;
  @ViewChild(KmRodadoMesComponent, { static: false }) kmRodadoMesComponentRef: KmRodadoMesComponent;
  @ViewChild(PedidosCoordenadorComponent, { static: false }) pedidosCoordenadorComponentRef: PedidosCoordenadorComponent;
  @ViewChild(PedidosEstadoComponent, { static: false }) pedidosEstadoComponentRef: PedidosEstadoComponent;
  @ViewChild(PedidosMarcaComponent, { static: false }) pedidosMarcaComponentRef: PedidosMarcaComponent;
  @ViewChild(PedidosMarcaCoordenadorComponent, { static: false }) pedidosMarcaCoordenadorComponentRef: PedidosMarcaCoordenadorComponent;
  @ViewChild(PedidosMarcaPercentualComponent, { static: false }) pedidosMarcaPercentualComponentRef: PedidosMarcaPercentualComponent;
  @ViewChild(PedidosVendedorCoordenadorComponent, { static: false }) pedidosVendedorCoordenadorComponentRef: PedidosVendedorCoordenadorComponent;
  @ViewChild(Top5PedidosEstadoComponent, { static: false }) top5PedidosEstadoComponentRef: Top5PedidosEstadoComponent;

  empresas: EmpresaEntity[] = [];
  empresaSelecionada: string[];
  permiteSelecionarEmpresa: boolean = false;
  dataInicio: Date;
  dataFim: Date;
  processando: boolean = false;

  constructor(
    private session: SessionService,
    protected alert: AlertService
  ) {
    super();
  };

  ngOnInit() {
    this.empresas = this.session.usuario.empresas;
    this.permiteSelecionarEmpresa = this.session.empresa == null;

    const currentDate = new Date();
    this.dataInicio = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0, 0);
    this.dataFim = currentDate;
  }

  filtrar() {

    if (!this.dataInicio) {
      this.alert.warning('Informe uma data de início')
      return;
    }

    if (!this.dataFim) {
      this.alert.warning('Informe uma data final')
      return;
    }

    this.processando = true;

    let entity = new ConsultarDadosDashboardRequestEntity();

    if (!this.empresaSelecionada || this.empresaSelecionada.length === 0)
      entity.clientes = this.session.getIdsEmpresa();
    else
      entity.clientes = this.empresaSelecionada.join();

    entity.dataInicial = this.dataInicio
    entity.dataFinal = this.dataFim;
    entity.divisoes = this.session.getIdDivisoes();
    

    this.totalizadoresRef.filtrarDados(entity);
    this.kmRodadoFreteEstadoComponentRef.filtrarDados(entity);
    this.kmRodadoMesComponentRef.filtrarDados(entity);
    this.pedidosCoordenadorComponentRef.filtrarDados(entity);
    this.pedidosEstadoComponentRef.filtrarDados(entity);
    this.pedidosMarcaComponentRef.filtrarDados(entity);
    this.pedidosMarcaCoordenadorComponentRef.filtrarDados(entity);
    this.pedidosMarcaPercentualComponentRef.filtrarDados(entity);
    this.pedidosVendedorCoordenadorComponentRef.filtrarDados(entity);
    this.top5PedidosEstadoComponentRef.filtrarDados(entity);

    this.processando = false;
  }
}
