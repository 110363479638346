import { AfterViewInit, OnDestroy, OnInit, QueryList, ViewChildren } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DataTableDirective } from "angular-datatables";
import * as $ from 'jquery';
import { Observable, Subject } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";
import { AlertService } from "./alert.service";
import { Dictionary } from "./dictionary";
import { ServiceBase } from "./service-base";
import { SessionService } from './session.service';

export abstract class ComponentBase implements OnInit, AfterViewInit, OnDestroy {
  protected unsubscribe$: Subject<void> = new Subject<void>();
  dtTriggers: Dictionary<Subject<void>> = {};
  dtOptions: any = {};
  hideTableButtons: boolean = false;
  @ViewChildren(DataTableDirective)
  dataTables: QueryList<DataTableDirective>;
  acessoTotalDivisao: string = "Acesso a todas as divisões";
  private _session: SessionService = new SessionService();
  corPrincipal: string = '#000000';
  corSecundaria: string = '#000000';
  urlLogo:string = 'https://intellitouch.s3.sa-east-1.amazonaws.com/imperiumlog/marca_imperium.png';
  urlLogoAcesso:string = 'https://intellitouch.s3.sa-east-1.amazonaws.com/imperiumlog/marca_imperium_login.png';
  themeWrapper = document.querySelector('body');

  //#region [ Construtores ]

  constructor(
    protected alert?: AlertService,
    protected router?: Router,
    protected modalService?: NgbModal
  ) {
    const _$ = this.ngOnDestroy;
    this.ngOnDestroy = () => {
      _$();
      this.unsubscribe$.next();
      this.unsubscribe$.complete();

    }
  }


  //#endregion

  //#region [ onInit, AfterInit ]

  possuiAcessoRecurso(recurso: number): boolean {
    const usuario = JSON.parse(localStorage.getItem('usuario'));
    const empresa = JSON.parse(localStorage.getItem('empresa'));

    /*if (empresa)
      return usuario.recursos.findIndex(x => x.idRecurso === recurso && x.idCliente === empresa.idCliente) > -1;
    */
    return usuario.recursos.findIndex(x => x.idRecurso === recurso) > -1;
  }

  get imagemPadrao(): string {
    return 'http://www.mpambiental.org/site/public/images/not-available.png';
  }

  carregarConfiguracoes(){
    if (window.location.href.indexOf('tanamao') > -1) {
      this.corPrincipal = '#1d7096';
      this.corSecundaria = '#1d7096';
      this.urlLogo = 'https://intellitouch.s3.sa-east-1.amazonaws.com/imperiumlog/marca_tanamao.png';
      this.urlLogoAcesso = 'https://intellitouch.s3.sa-east-1.amazonaws.com/imperiumlog/marca_tanamao.png';
    }

    // carrega as cores do cliente
    this.themeWrapper.style.setProperty('--corPrimaria', this.corPrincipal);
    this.themeWrapper.style.setProperty('primary', this.corPrincipal);
  }

  ngOnInit(): void {
    this.carregarConfiguracoes();

    if ($("table[datatable]").length > 0) {
      for (var i = 0; i < $("table[datatable]").length; i++) {
        if (!$("table[datatable]")[i].id)
          throw new Error('DataTable sem id informado.');

        this.dtTriggers[$("table[datatable]")[i].id] = new Subject<void>();
      }
    }

    this.configDtOptions();

    if (this.router)
      this.router.events
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((event: NavigationEnd) => {
          window.scroll(0, 0);
        });
  }

  configDtOptions(): any {
    let buttons = [];

    if (!this.hideTableButtons) {
      buttons = [
        {
          extend: 'excelHtml5',
          titleAttr: 'Exportar para Excel',
          className: 'btn btn-success',
          text: '<i class="fa fa-file-excel-o"></i> <span class="dt-table-btn"> Exportar Excel</span>',
          exportOptions: {
            stripHtml: true
          }
        },
        {
          extend: 'print',
          titleAttr: 'Imprimir',
          className: 'btn btn-primary',
          text: '<i class="fa fa-print"></i> <span class="dt-table-btn"> Imprimir</span>',
          exportOptions: {
            stripHtml: false,
            orientation: 'landscape',
            columns: ':not(.col-hide)',
          },
          customize: function (win) {
            var css = '@page { size: landscape; }',
              head = win.document.head || win.document.getElementsByTagName('head')[0],
              style = win.document.createElement('style');

            style.type = 'text/css';
            style.media = 'print';

            if (style.styleSheet) {
              style.styleSheet.cssText = css;
            }
            else {
              style.appendChild(win.document.createTextNode(css));
            }

            head.appendChild(style);
          }
        }
      ];
    }

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthMenu: [[5, 10, 25, 50, 100], [5, 10, 25, 50, 100]],
      orderable: false,
      language: {

        emptyTable: "Nenhum registro encontrado",
        info: "Mostrando de _START_ até _END_ de _TOTAL_ registros",
        infoEmpty: "Mostrando 0 até 0 de 0 registros",
        infoFiltered: "(Filtrados de _MAX_ registros)",
        infoPostFix: "",
        //infoThousands: ".",
        lengthMenu: "_MENU_ resultados por página",
        loadingRecords: "Carregando...",
        processing: "Processando...",
        zeroRecords: "Nenhum registro encontrado",
        search: "Pesquisar",
        paginate: {
          next: "Próximo",
          previous: "Anterior",
          first: "Primeiro",
          last: "Último"
        },
        aria: {
          sortAscending: ": Ordenar colunas de forma ascendente",
          sortDescending: ": Ordenar colunas de forma descendente"
        }
      },
      dom: 'lBfrtip',
      //lengthMenu: [
      //  [10, 25, 50, -1],
      //  ['10 linhas', '25 linhas', '50 linhas', 'Todos']
      //],
      buttons: buttons
    };

    return this.dtOptions;
  }


  ngAfterViewInit(): void {
    if (this.dataTables) {
      this.dataTables.forEach(table => {
        if (this.dtTriggers[table['el'].nativeElement.id])
          this.dtTriggers[table['el'].nativeElement.id].next();
      });
    }
  }
  //#endregion

  //#region [ Métodos Protegidos ]

  protected refreshDataTable(id: string) {
    if (this.dataTables) {
      this.dataTables.forEach(table => {
        if (table['el'].nativeElement.id === id) {
          table.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            if (!this.dtTriggers || !this.dtTriggers[table['el'].nativeElement.id])
              this.dtTriggers[table['el'].nativeElement.id] = new Subject<void>();

            this.dtTriggers[table['el'].nativeElement.id].next();
          });
        }
      });
    }
  }

  protected clearDataTable(id: string) {
    if (this.dataTables) {
      this.dataTables.forEach(table => {
        if (table['el'].nativeElement.id === id) {
          table.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.clear();
            dtInstance.draw();
          });
        }
      });
    }
  }

  protected alertarInformacoesInvalidas(): void {
    if (this.alert)
      this.alert.warning('Favor verificar informações inválidas na tela.');
    else
      throw new Error('AlertService não injetado em ComponentBase');
  }

  protected alertarCaptchaNaoIdentificado(): void {
    if (this.alert)
      this.alert.warning('Captcha não identificado. Favor entrar em contato com o administrador do sistema.');
    else
      throw new Error('AlertService não injetado em ComponentBase');
  }

  protected call<T>(service: ServiceBase, action: string, params: any[]): Observable<T> {
    return <Observable<T>>service[action]
      .apply(service, params);
  }

  protected call2<T>(fn: Function): Observable<T> {
    return fn() as Observable<T>;
  }

  protected loadingGestores<T>(service: ServiceBase, action: string, params: any[] | null, button?: HTMLButtonElement): Observable<T> {
    if (button) {
      $(button).addClass('carregando');
      $(button).prop('disabled', true);
    }
    else
      $("#lodingPanel").addClass('carregando');

    return <Observable<T>>service[action]
      .apply(service, params)
      .pipe(finalize(() => {
        if (button) {
          $(button).removeClass('carregando');
          $(button).prop('disabled', false);
        }
        else
          $("#lodingPanel").removeClass('carregando');
      })
      );
  }

  protected loading2<T>(fn: Function, button?: HTMLButtonElement): Observable<T> {
    if (button) {
      $(button).addClass('carregando');
      $(button).prop('disabled', true);
    }
    else
      $("#lodingPanel").addClass('carregando');

    return <Observable<T>>fn()
      .pipe(finalize(() => {
        if (button) {
          $(button).removeClass('carregando');
          $(button).prop('disabled', false);
        }
        else
          $("#lodingPanel").removeClass('carregando');
      }));
  }

  protected showLoading() {
    $("#lodingPanel").addClass('carregando');
  }


  protected hideLoading() {
    $("#lodingPanel").removeClass('carregando');
  }

  updateUrl($event: any, img: any) {
    if (img)
      img.src = "https://cdn.dribbble.com/users/55871/screenshots/2158022/no_photo.jpg";
  }


  exibirFiltro(): boolean {
    const usuario = JSON.parse(localStorage.getItem('usuario'));
    return !usuario.acessoNovo;

    // const empresa = JSON.parse(localStorage.getItem('empresa'));
    // if (empresa)
    //   return empresa.produtoESL;

    // return true;
  }

  forceRoute(uri: string) {
    this.router.navigateByUrl('/cadastro/departamento', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  scrollTop() {
    document.querySelector('.page-wrapper').scrollTo(0, 0);
  }

  redirecionarEmpresa(msg: string): boolean {
    if (!this._session.empresa) {
      this.alert.warning(msg)
        .then(() => {
          this._session.urlReturn = window.location.href;
          this.router.navigate(['/empresa/selecionar']);
        });
      return false;
    }
    else
      return true;
  }


  //#endregion

  //#region [ Destrutores ]

  ngOnDestroy(): void { }

  //#endregion

}
