import { SafeResourceUrl } from "@angular/platform-browser";

export class ProdutoClienteEntity {
  idProduto: number;
  idCliente: number;
  sku: string;
  descricao: string;
  idUnidadeMedida: number;
  pesoEmbalagem: number;
  pesoUnitario: number;
  altura: number;
  largura: number;
  profundidade: number;
  alturaEmbalagem: number;
  larguraEmbalagem: number;
  profundidadeEmbalagem: number;
  ncm: string;
  precoUnitario: number;
  numeroSerie: string;
  identificacaoPatrimonio: string;
  quantidadePallet: number;
  idCategoria: number;
  ativo: boolean;
  imagem: SafeResourceUrl;
}
