import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { DestinatarioService } from '../destinatario/destinatario.service';
import { GravarPedidoRequestEntity } from '../entities/Requests/gravar-pedido-request-entity';
import { AgrupadorPedidoEntity } from '../entities/agrupador-pedido.entity';
import { PessoaEntity } from '../entities/pessoa.entity';
import { UsuarioLogadoEntity } from '../entities/usuario-logado.entity';
import { CarrinhoService } from '../layout/masterpage/topbar/nav-bar/carrinho/carrinho.service';
import { AlertService } from '../shared/alert.service';
import { ComponentBase } from '../shared/component-base';
import { SessionService } from '../shared/session.service';
import { ConferirPedidoComponent } from './conferir-pedido/conferir-pedido.component';
import { FinalizarPedidoService } from './finalizar-pedido.service.';
import { ListaDestinatarioComponent } from './lista-destinatario/lista-destinatario.component';


@Component({
  selector: 'app-finalizar-pedido',
  templateUrl: './finalizar-pedido.component.html',
  styleUrls: ['./finalizar-pedido.component.css']
})
export class FinalizarPedidoComponent extends ComponentBase implements OnInit {
  carrinhoOk: boolean = false;
  modal: NgbModalRef;
  agrupadorPedido: AgrupadorPedidoEntity;
  observacao: string;

  @Output() onPediidoFinalizado: EventEmitter<any> = new EventEmitter();
  @ViewChild(ConferirPedidoComponent, { static: true }) conferirPedido: ConferirPedidoComponent;
  @ViewChild(ListaDestinatarioComponent, { static: true }) listaDestinatario: ListaDestinatarioComponent;

  constructor(
    protected alert: AlertService,
    private carrinho: CarrinhoService,
    private destinatario: DestinatarioService,
    private finalizarPedidoService: FinalizarPedidoService,
    protected route: Router,
    private session: SessionService,
    protected modalService: NgbModal,
  ) { super(); }

  ngOnInit() {
    this.agrupadorPedido = new AgrupadorPedidoEntity();
    this.agrupadorPedido.solicitante = new UsuarioLogadoEntity();
    this.agrupadorPedido.pedidos = [];
  }

  gerarPedidos() {
    this.incluirPedido();
  }

  incluirPedido() {

    let request = new GravarPedidoRequestEntity();
    request.codigoCliente = this.session.usuario['idCliente'];
    request.codigoUsuarioSolicitante = this.session.usuario['idPessoa'];
    request.destinatarios = this.getDestinatarios();
    request.itensCarrinho = this.carrinho.itens;
    request.observacao = this.conferirPedido.observacao;
    request.solicitante = this.session.usuario;

    // Verifica se o usuário selecionou apenas 1 empresa.
    // Caso tenha selecionado produtos de mais de uma empresa, aplica os ids das empresas que tiveram produtos selecionados.

    //GHP BRASILIT
    if (this.session.ajusteBrasilit) {
      request.empresas = this.session.getIdsEmpresa();
    }
    else if (this.session.empresa) {
      if (this.carrinho.possuiProdutosMultiplasEmpresa())
        request.empresas = this.carrinho.obterIdsEmpresasProdutos();
      else
        request.empresas = this.session.empresa.idCliente.toString();
    }
    else
      request.empresas = this.session.usuario.idsEmpresas;

      debugger;
    //this.loadingGestores<any>(this.finalizarPedidoService, "gravarPedido", [request])
    this.loading2<any>(() => this.session.usuario.acessoNovo ? this.finalizarPedidoService.incluirPedido(request) : this.finalizarPedidoService.gravarPedido(request))
      .pipe(take(1))
      .subscribe(resp => {
        console.log(resp);
        this.agrupadorPedido = resp;

        this.alert.info("Pedido gerado com sucesso.");
        (<any>$("#tab3")).removeClass("active");
        (<any>$("#tabContent3")).removeClass("active");
        (<any>$("#tab4")).addClass("active");
        (<any>$("#tabContent4")).addClass("active");

        this.carrinho.itens = [];
        this.destinatario.destinatarios = [];

      });
  }

  meusPedidos() {
    this.route.navigate(['/produtos/'])
  }

  validarCarrinho() {
    if (this.carrinho.itens.length === 0) {
      this.alert.warning(`Seu carrinho está vazio.`);
      this.carrinhoOk = false;
      return;
    }

    for (var x = 0; x < this.carrinho.itens.length; x++) {
      if (this.carrinho.itens[x].quantidade > this.carrinho.itens[x].produto.estoque && !this.carrinho.itens[x].isKit) {
        this.alert.warning(`A quantidade do produto ${this.carrinho.itens[x].produto.nomeProduto} ultrapassa a quantidade disponível em estoque.`);
        this.carrinhoOk = false;
        (<any>$("#tab1")).click();
        return;
      }
    }

    this.carrinhoOk = true;
    (<any>$("#tab1")).removeClass("active");
    (<any>$("#tabContent1")).removeClass("active");
    (<any>$("#tab2")).addClass("active");
    (<any>$("#tabContent2")).addClass("active");

    this.listaDestinatario.abrirListaDestinatarios();

  }

  voltarMeusProdutos() {
    (<any>$("#tab2")).removeClass("active");
    (<any>$("#tabContent2")).removeClass("active");
    (<any>$("#tab1")).addClass("active");
    (<any>$("#tabContent1")).addClass("active");
  }

  voltarDestinatarios() {
    (<any>$("#tab3")).removeClass("active");
    (<any>$("#tabContent3")).removeClass("active");
    (<any>$("#tab2")).addClass("active");
    (<any>$("#tabContent2")).addClass("active");
  }

  irParaConferencia() {

    for (var x = 0; x < this.carrinho.itens.length; x++) {

      let razao = this.listaDestinatario.pessoas.length * this.carrinho.itens[x].quantidade;

      if (razao > this.carrinho.itens[x].produto.estoque && !this.carrinho.itens[x].isKit) {
        this.alert.warning(`O produto ${this.carrinho.itens[x].produto.nomeProduto} não possui saldo suficiente para ser distribuído entre as ${this.listaDestinatario.pessoas.length} divisões.`);
        this.carrinhoOk = false;
        (<any>$("#tab2")).click();
        return;
      }
    }

    for (var x = 0; x < this.listaDestinatario.pessoas.length; x++) {
      if (!this.listaDestinatario.pessoas[x].responsavelPedido) {
        this.alert.warning("Informe o responsável no destinatário " + this.listaDestinatario.pessoas[x].nome);
        return;
      }
    }

    (<any>$("#tab2")).removeClass("active");
    (<any>$("#tabContent2")).removeClass("active");
    (<any>$("#tab3")).addClass("active");
    (<any>$("#tabContent3")).addClass("active");

    this.destinatario.destinatarios = this.listaDestinatario.pessoas;
    this.conferirPedido.pessoas = this.listaDestinatario.pessoas;
  }

  getDestinatarios(): PessoaEntity[] {

    return this.listaDestinatario.pessoas;
  }

  getAgrupadorPedido(): AgrupadorPedidoEntity {
    this.observacao = this.agrupadorPedido.pedidos.length > 0 ? this.agrupadorPedido.pedidos[0].observacao : "";
    return this.agrupadorPedido;
  }
}
