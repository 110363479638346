export class ConsultarEntradaProdutoRequestEntity {
  idCliente: number | null;
  dtIni: Date;
  dtFim: Date;
  sku: string;
  descricao: string;
  idProduto: number;

  toQueryString(): string {
    let queryString = '?';

    if (this.idCliente !== null && typeof this.idCliente !== 'undefined') {
      queryString += `idCliente=${this.idCliente}&`;
    }

    if (this.dtIni) {
      queryString += `dtIni=${this.dtIni.toISOString()}&`;
    }

    if (this.dtFim) {
      queryString += `dtFim=${this.dtFim.toISOString()}&`;
    }

    if (this.sku) {
      queryString += `sku=${encodeURIComponent(this.sku)}&`;
    }

    if (this.descricao) {
      queryString += `descricao=${encodeURIComponent(this.descricao)}&`;
    }

    // Remove the trailing '&' or return the original string if no parameters were appended
    return queryString.endsWith('&') ? queryString.slice(0, -1) : queryString;
  }
}
