import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { take } from 'rxjs/operators';
import { TipoLocalizacaoConstant } from '../../Constants/tipo-localizacao.constant';
import { LocalizacaoEntity } from '../../cadastro/entities/localizacao.entity';
import { LocalizacaoService } from '../../cadastro/localizacao/localizacao.service';
import { LocalizacaoProdutoEntity } from '../../entities/localizacao-produto.entity';
import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { TransferirProdutoLocalizacaoRequestEntity } from '../entities/requests/transferir-produto-localizacao-request.entity';
import { ProdutoService } from '../produto.service';

@Component({
  selector: 'app-produto-transferencia-local',
  templateUrl: './produto-transferencia-local.component.html',
  styleUrls: ['./produto-transferencia-local.component.css']
})
export class ProdutoTransferenciaLocalComponent extends ComponentBase implements OnInit, OnChanges {
  @Input() idProduto: number = 0;
  @Output() onFinalizar: EventEmitter<void> = new EventEmitter();

  localizacao: LocalizacaoProdutoEntity;
  armazens: LocalizacaoEntity[] = [];
  ruas: LocalizacaoEntity[] = [];
  baias: LocalizacaoEntity[] = [];
  niveis: LocalizacaoEntity[] = [];

  loadingArmazens: boolean = true;
  loadingRuas: boolean = true;
  loadingBaia: boolean = true;
  loadingNivel: boolean = true;
  tipoLocalizacaoConstant: TipoLocalizacaoConstant;

  localizacaoes: LocalizacaoProdutoEntity[] = [];
  novaLocalizacao: LocalizacaoProdutoEntity;

  constructor(
    public session: SessionService,
    private produtoService: ProdutoService,
    private localizacaoService: LocalizacaoService,
    protected alert: AlertService
  ) {
    super();
  }

  ngOnInit() {
    this.tipoLocalizacaoConstant = new TipoLocalizacaoConstant();

    this.carregarArmazens();
    this.carregarRuas();
    this.carregarBaias();
    this.carregarNiveis();
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.idProduto.currentValue) {
      this.carregarLocalizacaoProduto();
    }
  }

  carregarLocalizacaoProduto() {
    this.loading2<LocalizacaoProdutoEntity[]>(() => this.produtoService.consultarLocalizacaoPorCodigoProduto(this.idProduto))
      .pipe(take(1))
      .subscribe((resp) => {
        this.localizacaoes = resp;
      });
  }

  selecionar(localizacao: LocalizacaoProdutoEntity) {
    this.novaLocalizacao = new LocalizacaoProdutoEntity();
    this.localizacao = localizacao;
  }

  carregarArmazens() {
    this.call2<LocalizacaoEntity[]>(() => this.localizacaoService.consultarLocalizacaoPorTipo(this.tipoLocalizacaoConstant.Armazem))
      .pipe(take(1))
      .subscribe(resp => {
        this.armazens = resp;
        this.loadingArmazens = false;
      });
  }

  carregarRuas() {
    this.call2<LocalizacaoEntity[]>(() => this.localizacaoService.consultarLocalizacaoPorTipo(this.tipoLocalizacaoConstant.Rua))
      .pipe(take(1))
      .subscribe(resp => {
        this.ruas = resp;
        this.loadingRuas = false;
      });
  }

  carregarBaias() {
    this.baias = [];
    this.call2<LocalizacaoEntity[]>(() => this.localizacaoService.consultarLocalizacaoPorTipo(this.tipoLocalizacaoConstant.BaiaImpar))
      .pipe(take(1))
      .subscribe(respPar => {
        this.baias.push(...respPar);
        this.loadingBaia = false;
      });

    this.call2<LocalizacaoEntity[]>(() => this.localizacaoService.consultarLocalizacaoPorTipo(this.tipoLocalizacaoConstant.BaiaPar))
      .pipe(take(1))
      .subscribe(respImpar => {
        this.baias.push(...respImpar);
        this.loadingBaia = false;
      });
  }

  carregarNiveis() {
    this.call2<LocalizacaoEntity[]>(() => this.localizacaoService.consultarLocalizacaoPorTipo(this.tipoLocalizacaoConstant.Nivel))
      .pipe(take(1))
      .subscribe(resp => {
        this.niveis = resp;
        this.loadingNivel = false;
      });
  }

  validarTransferencia(): boolean {
    if (!this.novaLocalizacao.idArmazem) {
      this.alert.error("Selecione um armazém de destino");
      return false;
    }

    if (!this.novaLocalizacao.idRua) {
      this.alert.error("Selecione uma rua de destino");
      return false;
    }

    if (!this.novaLocalizacao.idBaia) {
      this.alert.error("Selecione uma baia de destino");
      return false;
    }

    if (!this.novaLocalizacao.idNivel) {
      this.alert.error("Selecione um nível de destino");
      return false;
    }

    if (this.localizacao.qtdProduto < this.novaLocalizacao.qtdProduto) {
      this.alert.error("A quantidade de destino não pode ser maior que a quantidade atual");
      return false;
    }

    if (this.localizacao.idArmazem === this.novaLocalizacao.idArmazem &&
      this.localizacao.idRua === this.novaLocalizacao.idRua &&
      this.localizacao.idBaia === this.novaLocalizacao.idBaia &&
      this.localizacao.idNivel === this.novaLocalizacao.idNivel) {
      this.alert.error("A localização de destino deve ser diferente da localização atual");
      return false;
    }

    return true;
  }

  transferir() {
    if (!this.validarTransferencia())
      return;

    this.alert.confirm("Tem certeza que deseja transferir o produto de localização?")
      .then((resp) => {
        if (resp.value)
          this.finalizar();
      });
  }

  finalizar() {
    const request = new TransferirProdutoLocalizacaoRequestEntity();
    request.localizacaoDestino = this.novaLocalizacao;
    request.localizacaoOrigem = this.localizacao;

    request.idProduto = this.idProduto;

    this.loading2(() => this.produtoService.transferirProdutoLocalizacao(request))
      .pipe(take(1))
      .subscribe(() => {
        this.alert.success("Produto transferido com sucesso");
        this.onFinalizar.emit();
      })
  }

  cancelar() {
    this.onFinalizar.emit();
  }
}
