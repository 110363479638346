import { ProdutoBaixaUtilizacaoResponseEntity } from "./Responses/produto-baixa-utilizacao.response.entity";

export class DivisaoESLEntity {
  idPessoaDivisao: number;
  nomeDivisao: string;
  fazPedidoWeb: boolean;
  visualizaPedidoTerceiros: boolean;
  visualizaQuantidadeDisponivel: boolean;
  aprovaPedido: boolean;
  aprovaPedidoTerceiro: boolean;
  usuarioMaster: boolean;
  empresa: string;
  idEmpresa: number;
  produtoBaixaUtilizacao: ProdutoBaixaUtilizacaoResponseEntity[];
}
