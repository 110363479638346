export class ConsultarProdutoRequestEntity {
  divisoes: number[];
  saldoZerado: boolean;
  codigoProduto: string;
  descricaoProduto: string;
  idUsuario: number;
  empresas: string;
  status: number;
  estoqueMinimo: boolean;
  dtValidadeIni: Date;
  dtValidadeFim: Date;
  dtMovimentoIni: Date;
  dtMovimentoFim: Date;
  dtSemMovimentoIni: Date;
  dtSemMovimentoFim: Date;
  grupoProduto: number;
  produtoSemDivisao: boolean;
  somenteComEstoqueVirtual: boolean;
  idTagProduto: number;
  idLinhaProduto: number;
  pesquisaGenerica: string;
  somentoOportunidades: boolean;
  acessoProdutoBackOffice : boolean;
}
