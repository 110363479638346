import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DestinatarioService } from '../../destinatario/destinatario.service';
import { ItemCarrinhoEntity } from '../../entities/item-carrinho.entity';
import { ProdutoEntity } from '../../entities/produto.entity';
import { CarrinhoService } from '../../layout/masterpage/topbar/nav-bar/carrinho/carrinho.service';
import { ProdutoService } from '../../produto/produto.service';
import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';

@Component({
  selector: 'app-itens-carrinho',
  templateUrl: './itens-carrinho.component.html',
  styleUrls: ['./itens-carrinho.component.css']
})
export class ItensCarrinhoComponent extends ComponentBase implements OnInit {
  showMarca: boolean;

  constructor(
    private carrinho: CarrinhoService,
    private produtoService: ProdutoService,
    protected alert: AlertService,
    private toastr: ToastrService,
    private session: SessionService,
    private destintarioService: DestinatarioService
  ) { super(); }
  itens: ItemCarrinhoEntity[] = [];

  ngOnInit() {
    this.showMarca = !this.session.empresa;
    this.itens = this.carrinho.itens;
  }

  getImagemProduto(produto: ProdutoEntity) {
    if (produto.imagemKit)
      return produto.imagemKit;

    if (!this.session.empresa || this.session.empresa.produtoESL)
      return this.produtoService.getImagemProduto(produto.codigoProduto);

    return this.produtoService.consultarImagemProduto(produto.idInterno);
  }


  getImagemMarca(item: ItemCarrinhoEntity) {
    var empresa = this.session.usuario.empresas.find(x => x.idCliente == item.produto.idCliente);
    return this.session.logoClientes + empresa.nomeCliente + ".png";
  }

  validarEstoque(item: ItemCarrinhoEntity) {
    if (item.produto.saldoVirtual > -1) {
      if (item.quantidade > item.produto.saldoVirtual && !item.isKit) {
        item.quantidade = item.produto.saldoVirtual;
        this.alert.warning('A Quantidade solicitada ultrapassa a quantidade disponível em estoque.');
      }
    }
    else {
      if (item.quantidade > item.produto.estoque && !item.isKit) {
        item.quantidade = item.produto.estoque;
        this.alert.warning('A Quantidade solicitada ultrapassa a quantidade disponível em estoque.');
      }
    }

    this.atualizarCarrinho();
  }

  atualizarCarrinho() {
    this.carrinho.itens = this.itens;
    this.destintarioService.addUsuarioLogadoDestinatario();
  }

  excluirProduto(idx: number) {
    this.itens.splice(idx, 1);
    this.atualizarCarrinho();
    this.toastr.success('Produto removido do carrinho.', 'Produto removido', {
      progressBar: true,
      positionClass: 'toast-top-left'
    });
  }

  permiteVisualizarSaldo(produto: ProdutoEntity): boolean {
    if (produto.saldoVirtual > -1)
      return true;

    return this.possuiAcessoRecurso(23);
  }
}
