export class ProdutoKitEntity {
  codigoProdutoKit: number;
  codigoProdutoKitPai: number;
  codigoFaixaKit: number;
  idProduto: number;
  qtdMinimo: number;
  qtdMaximo: number;
  ativo: boolean
  nomeProduto: string;
  valorProduto: number;
  codigoProduto: string;
  imagensProduto: string[];
  selecionado: boolean;
  quantidade: number;
  urlImagemResultado: string;
  imagemResultado: File;
  idDivisaoProduto: number;
  iniciaFluxo: boolean;
  idCliente: number;
}

