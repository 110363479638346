import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { FormsModule } from '@angular/forms';
import { LayoutModule } from '../layout/layout.module';
import { ProdutoModule } from '../produto/produto.module';
import { DestinatarioModule } from '../destinatario/destinatario.module';
import { FinalizarPedidoModule } from '../finalizar-pedido/finalizar-pedido.module';
import { PipesModule } from '../pipes/pipes.module';
import { AccumulationChartAllModule, ChartAllModule } from '@syncfusion/ej2-angular-charts';
import { TotalizadoresComponent } from './totalizadores/totalizadores.component';
import { PedidosMarcaComponent } from './pedidos-marca/pedidos-marca.component';
import { PedidosEstadoComponent } from './pedidos-estado/pedidos-estado.component';
import { Top5PedidosEstadoComponent } from './top5-pedidos-estado/top5-pedidos-estado.component';
import { PedidosCoordenadorComponent } from './pedidos-coordenador/pedidos-coordenador.component';
import { Top5PedidosCoordenadorComponent } from './top5-pedidos-coordenador/top5-pedidos-coordenador.component';
import { PedidosVendedorCoordenadorComponent } from './pedidos-vendedor-coordenador/pedidos-vendedor-coordenador.component';
import { Top5PedidosVendedorCoordenadorComponent } from './top5-pedidos-vendedor-coordenador/top5-pedidos-vendedor-coordenador.component';
import { KmRodadoFreteEstadoComponent } from './km-rodado-frete-estado/km-rodado-frete-estado.component';
import { KmRodadoMesComponent } from './km-rodado-mes/km-rodado-mes.component';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { PedidosMarcaPercentualComponent } from './pedidos-marca-percentual/pedidos-marca-percentual.component';
import { MaterialEstoqueMarcaComponent } from './material-estoque-marca/material-estoque-marca.component';
import { PedidosMarcaCoordenadorComponent } from './pedidos-marca-coordenador/pedidos-marca-coordenador.component';
import { LoaddingComponent } from './loadding/loadding.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LayoutModule,
    ProdutoModule,
    DestinatarioModule,
    FinalizarPedidoModule,
    PipesModule,
    ChartAllModule,
    AccumulationChartAllModule,
    NgbProgressbarModule
  ],
  declarations: [DashboardComponent, TotalizadoresComponent, PedidosMarcaComponent, PedidosEstadoComponent, Top5PedidosEstadoComponent, PedidosCoordenadorComponent, Top5PedidosCoordenadorComponent, PedidosVendedorCoordenadorComponent, Top5PedidosVendedorCoordenadorComponent, KmRodadoFreteEstadoComponent, KmRodadoMesComponent, PedidosMarcaPercentualComponent, MaterialEstoqueMarcaComponent, PedidosMarcaCoordenadorComponent, LoaddingComponent]
})
export class DashboardModule { }
