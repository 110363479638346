import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";

export abstract class ServiceBase {

  protected loading2<T>(fn: Function, button?: HTMLButtonElement): Observable<T> {
    if (button) {
      $(button).addClass('carregando');
      $(button).prop('disabled', true);
    }
    else
      $("#lodingPanel").addClass('carregando');

    return <Observable<T>>fn()
      .pipe(finalize(() => {
        if (button) {
          $(button).removeClass('carregando');
          $(button).prop('disabled', false);
        }
        else
          $("#lodingPanel").removeClass('carregando');
      }));
  }

}
