import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { EmpresaEntity } from '../../entities/empresa.entity';
import { PessoaJuridicaService } from '../../pessoa/services/pessoa-juridica.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';

@Component({
  selector: 'app-selecionar-empresa',
  templateUrl: './selecionar-empresa.component.html',
  styleUrls: ['./selecionar-empresa.component.css']
})
export class SelecionarEmpresaComponent extends ComponentBase implements OnInit {

  empresas: EmpresaEntity[];
  pathLogo: string;

  constructor(
    public session: SessionService,
    private pessoaJuridicaService: PessoaJuridicaService,
    private sanitizer: DomSanitizer,
    protected router: Router

  ) {
    super();
  }

  ngOnInit() {

    this.empresas = this.session.usuario.empresas;

    if (this.session.usuario.acessoNovo) {
      for (let x = 0; x < this.empresas.length; x++) {
        this.consultarImagem(this.empresas[x]);
      }
    } else {
      this.pathLogo = this.session.logoClientes;
      if (this.session.empresa)
        if (this.session.urlReturn)
          window.location.href = this.session.urlReturn;
        else
          window.location.href = '/produtos';
    }
  }

  selecionarEmpresa(empresa: EmpresaEntity) {
    if (empresa == null)
      this.session.logoEmpresaCliente = null;
    else
      this.session.logoEmpresaCliente = this.session.logoClientes + empresa.nomeCliente + ".png";

    this.session.empresa = empresa;
    if (this.session.urlReturn)
      window.location.href = this.session.urlReturn;
    else
      window.location.href = '/produtos';
  }

  consultarImagem(empresa: any): void {
    this.loading2<string>(() => this.pessoaJuridicaService.consultarImagem(empresa.idCliente))
      .pipe(take(1))
      .subscribe(resp => {
        const sanitizer = this.sanitizer;
        if (resp) {
          empresa.imagem = sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + resp);

        }
      });
  }
}
