import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { ProdutoConstant } from '../../Constants/produto.constant';
import { LocalizacaoService } from '../../cadastro/localizacao/localizacao.service';
import { ProdutoEntity } from '../../entities/produto.entity';
import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { StorageService } from '../../shared/storage.service';
import { MovimentoProdutoEntity } from '../entities/movimento-produto.entity';
import { ProdutoService } from '../produto.service';

@Component({
  selector: 'app-produto-ajuste-estoque',
  templateUrl: './produto-ajuste-estoque.component.html',
  styleUrls: ['./produto-ajuste-estoque.component.css']
})
export class ProdutoAjusteEstoqueComponent extends ComponentBase implements OnInit {

  @Input() idProduto: number = 0;
  @Output() onFinalizar: EventEmitter<void> = new EventEmitter();

  tipoMovimento: string = "E";
  tipoMovimentacaoEstoque = ProdutoConstant.tipoMovimentacaoEstoque;
  quantidade: number = 0;
  observacao: string = "";
  constructor(
    public session: SessionService,
    private produtoService: ProdutoService,
    private localizacaoService: LocalizacaoService,
    private storage: StorageService,
    protected alert: AlertService
  ) {
    super();
  }

  ngOnInit() {
  }

  salvar() {

    const entity = new MovimentoProdutoEntity();
    const produto = <ProdutoEntity>this.storage.data.produtoAjusteEstoque;

    entity.idProduto = this.idProduto;
    entity.observacao = this.observacao;
    entity.quantidade = this.quantidade;
    entity.tipoMovimento = this.tipoMovimento;
    entity.valorUnitario = produto.valorUnitario;

    this.loading2(() => this.produtoService.incluirMovimento(entity))
      .pipe(take(1))
      .subscribe(() => {
        this.alert.success("Estoque atualizado com sucesso");
        this.onFinalizar.emit();
      });
  }

  cancelar() {
    this.onFinalizar.emit();
  }
}
