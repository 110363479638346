import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { ConsultarPedidoDeclaracaoTransporteResponseEntity } from '../../entities/Responses/consultar-pedido-declaracao-transporte-response.entity';
import { ComponentBase } from '../../shared/component-base';
import { PedidoService } from '../pedidos.service';

@Component({
  selector: 'app-declaracao-transporte-produto',
  templateUrl: './declaracao-transporte-produto.component.html',
  styleUrls: ['./declaracao-transporte-produto.component.css']
})
export class DeclaracaoTransporteProdutoComponent extends ComponentBase implements OnInit {
  dataAtual: Date;
  pedido: ConsultarPedidoDeclaracaoTransporteResponseEntity = new ConsultarPedidoDeclaracaoTransporteResponseEntity();

  @Input() imprimirAutomaticamente: boolean = true;
  @Output() onDeclaracaoPreenchida: EventEmitter<ConsultarPedidoDeclaracaoTransporteResponseEntity> = new EventEmitter();

  constructor(
    private activatedRoute: ActivatedRoute,
    private pedidoService: PedidoService,
  ) {
    super();
  }

  ngOnInit() {
    this.dataAtual = new Date();
    const idDeclaracao = this.activatedRoute.snapshot.paramMap.get('id');
    this.loading2<ConsultarPedidoDeclaracaoTransporteResponseEntity>(() => this.pedidoService.consultarPedidoDeclaracaoTransporte(idDeclaracao))
      .pipe(take(1))
      .subscribe(resp => {
        console.info(resp);
        this.pedido = resp;
        this.onDeclaracaoPreenchida.emit(resp);

        if (this.imprimirAutomaticamente) {
          setTimeout(() => {
            print();
          }, 3000);
        }
      });
  }
}
