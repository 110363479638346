import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCardModule, MatIconModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { LayoutModule } from '../layout/layout.module';

import { SelecionarEmpresaComponent } from './selecionar-empresa/selecionar-empresa.component';

@NgModule({
  declarations: [SelecionarEmpresaComponent],
  imports: [
    CommonModule,
    FormsModule,
    LayoutModule,
    MatCardModule,
    MatIconModule,
    RouterModule
  ]
})
export class EmpresaModule { }
