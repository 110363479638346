import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';

import { PessoaEntity } from '../entities/pessoa.entity';
import { ComponentBase } from '../shared/component-base';
import { SessionService } from '../shared/session.service';
import { DestinatarioService } from './destinatario.service';

@Component({
  selector: 'app-destinatario',
  templateUrl: './destinatario.component.html',
  styleUrls: ['./destinatario.component.css']
})
export class DestinatarioComponent extends ComponentBase implements OnInit {
  destinatarios: Array<PessoaEntity> = [];
  pessoa: PessoaEntity = new PessoaEntity();

  @Input() showAddRemove: boolean = false;
  @Output() onAddRemoveItem: EventEmitter<PessoaEntity> = new EventEmitter();

  constructor(
    private destinatarioService: DestinatarioService,
    private session: SessionService
  ) { super(); }

  ngOnInit() {
    super.ngOnInit();
    this.pessoa.idCliente = this.session.usuario['idCliente'];    
  }

  consultarPessoa() {
    this.loadingGestores<PessoaEntity[]>(this.destinatarioService, "consultarPessoa", [this.pessoa])
      .pipe(take(1))
      .subscribe(response => {
        this.destinatarios = response;
      });
  }

  onFilterPessoas(pessoas: PessoaEntity[]) {
    this.destinatarios = pessoas;
  }

  addRemoveItem(pessoa: PessoaEntity) {
    console.log(pessoa);
    this.onAddRemoveItem.emit(pessoa);
  }
}
