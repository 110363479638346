export class TipoLocalizacaoConstant {
  get Armazem(): number {
    return 1;
  }

  get Rua(): number {
    return 2;
  }

  get BaiaPar(): number {
    return 3;
  }

  get BaiaImpar(): number {
    return 4;
  }

  get Nivel(): number {
    return 5;
  }

  get Posicao(): number {
    return 6;
  }
}
