import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiltroRecebimentosComponent } from './recebimentos/filtro-recebimentos/filtro-recebimentos.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { LayoutModule } from '../layout/layout.module';
import { DataTablesModule } from 'angular-datatables';
import { DivisaoModule } from '../divisao/divisao.module';
import { UfModule } from '../uf/uf.module';
import { CidadeModule } from '../cidade/cidade.module';
import { EnviosComponent } from './envios/envios.component';
import { RecebimentosComponent } from './recebimentos/recebimentos.component';
import { FiltroEnviosComponent } from './envios/filtro-envios/filtro-envios.component';
import { ReportDynamicComponent } from './report-dynamic/report-dynamic.component';
import { TreeViewModule } from '@syncfusion/ej2-angular-navigations';
import { RelatoriosRoutingModule } from './relatorios-routing.module';
import { CategoriaComponent } from './categoria/categoria.component';
import { ConfigurarRelatorioComponent } from './configurar-relatorio/configurar-relatorio.component';
import { ConfigurarRelatorioFormComponent } from './configurar-relatorio/configurar-relatorio-form/configurar-relatorio-form.component';
import { PipesModule } from '../pipes/pipes.module';
import { MatCheckboxModule } from '@angular/material';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    DropzoneModule,
    LayoutModule,
    DataTablesModule,
    DivisaoModule,
    UfModule,
    CidadeModule,
    TreeViewModule,
    RelatoriosRoutingModule,
    PipesModule,
    MatCheckboxModule
  ],
  declarations: [EnviosComponent, RecebimentosComponent, FiltroRecebimentosComponent, FiltroEnviosComponent, ReportDynamicComponent, CategoriaComponent, ConfigurarRelatorioComponent, ConfigurarRelatorioFormComponent],
  exports: [EnviosComponent, RecebimentosComponent, FiltroRecebimentosComponent, FiltroEnviosComponent, ReportDynamicComponent],
})
export class RelatoriosModule { }
