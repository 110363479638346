import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { FaixaKitEntity } from '../../entities/faixa-kit.entity';
import { ProdutoKitEntity } from '../../entities/produto-kit.entity';
import { ProdutoEntity } from '../../entities/produto.entity';
import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { ProdutoService } from '../produto.service';

@Component({
  selector: 'app-associa-produto-kit',
  templateUrl: './associa-produto-kit.component.html',
  styleUrls: ['./associa-produto-kit.component.css']
})
export class AssociaProdutoKitComponent extends ComponentBase implements OnInit {

  faixa: FaixaKitEntity = new FaixaKitEntity();
  produtos: ProdutoKitEntity[] = [];
  modal: NgbModalRef;
  produtoSelecionado: ProdutoKitEntity;
  produtosAssociados: ProdutoKitEntity[] = [];
  faixasKit: FaixaKitEntity[] = [];
  faixasKitSelecionadas: string[];

  constructor(
    private produtoService: ProdutoService,
    private activatedRoute: ActivatedRoute,
    protected modalService: NgbModal,
    protected alert: AlertService,
    protected router: Router,
    private toast: ToastrService,
    private session: SessionService,
  ) {
    super();
  }

  ngOnInit() {
    let codigoFaixaKit = this.activatedRoute.snapshot.paramMap.get('id');
    this.loadingGestores<FaixaKitEntity>(this.produtoService, "consultarFaixaKitPorCodigo", [codigoFaixaKit])
      .pipe(take(1))
      .subscribe(resp => {
        this.faixa = resp;
        this.produtos = [...this.faixa.produtos];

        this.loadingGestores<FaixaKitEntity[]>(this.produtoService, "consultarFaixaKit", [resp.codigoConfiguracaoKit])
          .pipe(take(1))
          .subscribe(faixas => {
            this.faixasKit = faixas;
          });
      });
  }

  selecionarProduto(modalProduto: any, produto: ProdutoKitEntity) {
    this.produtoSelecionado = produto;
    this.modal = this.modalService.open(modalProduto, { ariaLabelledBy: 'modal-dest', windowClass: "fichaModal" });
  }

  onAddRemove(produto: ProdutoEntity, idx: number) {
    if (produto.selecionado) {
      if (!this.faixa.produtos)
        this.faixa.produtos = [];

      let produtoKit = new ProdutoKitEntity();
      produtoKit.idProduto = produto.idInterno;
      produtoKit.codigoProduto = produto.codigoProduto;
      produtoKit.imagensProduto = [];
      produtoKit.imagensProduto.push(this.getImagemProduto(produto.codigoProduto));
      produtoKit.valorProduto = produto.valorUnitario;
      produtoKit.nomeProduto = produto.nomeProduto;
      produtoKit.codigoProdutoKitPai = this.produtoSelecionado.codigoProdutoKit;
      produtoKit.idDivisaoProduto = produto.idDivisao;

      this.faixa.produtos.push(produtoKit);
      this.toast.success("Produto adicionado com sucesso.");
    }
    else {
      this.removerProduto(produto);
    }
  }

  removerProduto(produto: ProdutoEntity) {
    let idx = this.faixa.produtos.findIndex(x => x.idProduto === produto.idInterno);
    this.faixa.produtos.splice(idx, 1);
    this.toast.success("Produto removido com sucesso.");
  }


  getImagemProduto(codigoProduto: string): string {
    let url = this.session.pathImages;
    return `${url}/${codigoProduto}/0.jpg`;
  }

  associarKit() {
    this.alert.confirm("Tem certeza que deseja associar esses produtos?")
      .then(res => {
        if (res.value) {

          this.loadingGestores<any>(this.produtoService, "associarProdutos", [this.faixa])
            .pipe(take(1))
            .subscribe(resp => {
              this.alert.success("Produtos Associados com sucesso");
              this.router.navigate(['/produtos/configurar-kit/editar/' + this.faixa.codigoConfiguracaoKit])
            });
        }
      });
  }

  visualizarAssociados(produto: ProdutoKitEntity) {
    this.loadingGestores<ProdutoKitEntity[]>(this.produtoService, "consultarProdutoKitPorCodigoPai", [produto.codigoFaixaKit, produto.codigoProdutoKit])
      .pipe(take(1))
      .subscribe(resp => {

        if (resp.length === 0)
          this.alert.info("Não existem produtos associados ao produto " + produto.nomeProduto);

        this.produtosAssociados = resp;
      });
  }
}
