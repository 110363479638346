import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CepPipe } from './cep.pipe';
import { CpfCnpjPipe } from './cpf-cnpj.pipe';
import { OrigemMovimentoEstoque } from './origem-movimento-estoque.pipe';
import { SafePipe } from './safe.pipe';
import { SimNaoPipe } from './sim-nao.pipe';
import { SituacaoSolicitacaoPipe } from './situacao-solicitacao.pipe';

@NgModule({
  declarations: [
    CpfCnpjPipe,
    SimNaoPipe,
    CepPipe,
    SafePipe,
    OrigemMovimentoEstoque,
    SituacaoSolicitacaoPipe
  ],
  exports: [
    CpfCnpjPipe,
    SimNaoPipe,
    CepPipe,
    SafePipe,
    OrigemMovimentoEstoque,
    SituacaoSolicitacaoPipe
  ],
  imports: [
    CommonModule
  ]
})
export class PipesModule { }
