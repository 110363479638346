import { CdkDrag, CdkDragMove, CdkDropList, CdkDropListGroup, moveItemInArray } from '@angular/cdk/drag-drop';
import { ViewportRuler } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { take, takeUntil } from 'rxjs/operators';
import { CadastroService } from '../../../cadastro/cadastro.service';
import { CategoriaProdutoEntity } from '../../../cadastro/entities/categoria-produto.entity';
import { AlertService } from '../../../shared/alert.service';
import { ComponentBase } from '../../../shared/component-base';
import { ImagemProdutoEntity } from '../../entities/imagem-produto.entity';
import { ProdutoClienteEntity } from '../../entities/produto-cliente.entity';
import { AtualizarImagemProdutoRequestEntity } from '../../entities/requests/atualizar-imagem-produto-request.entity';
import { ListarUnidadesMedidasResponseEntity } from '../../entities/response/listar-unidades-medidas-response.entity';
import { ProdutoService } from '../../produto.service';

declare var $: any;

@Component({
  selector: 'app-cadastro-produto-editar',
  templateUrl: './cadastro-produto-editar.component.html',
  styleUrls: ['./cadastro-produto-editar.component.css']
})
export class CadastroProdutoEditarComponent extends ComponentBase implements OnInit {
  categorias: CategoriaProdutoEntity[] = [];
  imagensProduto: ImagemProdutoEntity[] = [];
  imagensProdutosOrdenados: AtualizarImagemProdutoRequestEntity[] = [];
  edicao: boolean;
  modal: NgbModalRef;
  unidadesMedidas: ListarUnidadesMedidasResponseEntity[] = [];
  imegemProduto: string;
  imagemProduto: File;

  @ViewChild("modalImagemProduto", { static: true }) modalImagemProduto: any;
  @ViewChild(CdkDropListGroup, { static: true }) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList, { static: true }) placeholder: CdkDropList;

  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;
  public dragIndex: number;
  public activeContainer;

  @Output() salvarCancelar = new EventEmitter();
  @Input() produto: ProdutoClienteEntity;

  constructor(
    private cadastroService: CadastroService,
    private produtoService: ProdutoService,
    protected alert: AlertService,
    protected router: Router,
    protected modalService: NgbModal,
    private viewportRuler: ViewportRuler,
    private sanitizer: DomSanitizer,
  ) { super(); }

  ngOnInit() {
    super.ngOnInit();

    this.listarCategoriasProdutosMarca();
    this.listarUnidadesMedidas();

    $('#imagem-produto').dropify({
      messages: {
        default: 'Clique aqui ou arraste e solte aqui sua imagem para importação!',
        replace: 'Clique aqui ou arraste e solte aqui sua imagem para para substituir o arquivo atual!',
        remove: 'Remove',
        error: 'Ocorreu algum problema ao tentar gravar o arquivo!'
      },
      error: {
        fileSize: 'O tamanho máximo do arquivo deve ser de 30MB!',
      }
    });
  }

  listarCategoriasProdutosMarca() {
    this.loading2<CategoriaProdutoEntity[]>(() => this.cadastroService.listarCategoriasProdutosMarca(this.produto.idCliente, "familia"))
      .pipe(take(1))
      .subscribe(resp => {
        this.categorias = resp;
        if (resp.length <= 0)
          this.alert.info("Ainda não existe nenhuma categoria de produto cadastrada para essa marca.")
      });
  }

  listarUnidadesMedidas() {
    this.loading2<ListarUnidadesMedidasResponseEntity[]>(() => this.produtoService.listarUnidadesMedidas())
      .pipe(take(1))
      .subscribe(resp => {
        this.unidadesMedidas = resp;
      });
  }

  consultarImagensProduto() {
    this.loading2<ImagemProdutoEntity[]>(() => this.produtoService.consultarImagensProduto(this.produto.idProduto))
      .pipe(take(1))
      .subscribe(resp => {
        const sanitizer = this.sanitizer;
        this.imagensProduto = resp;
        if (resp.length > 0) {
          this.imagensProduto.forEach(function (value) {
            value.imagem = sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + value.arrayImagem);
          });
        }
      });
  }

  excluirImagemProduto(imagemProduto: ImagemProdutoEntity): void {
    this.loading2<void>(() => this.produtoService.excluirImagemProduto(imagemProduto))
      .pipe(take(1))
      .subscribe(() => {
        this.alert.success("Imagem excluída com sucesso!");
        const indexImagem = this.imagensProduto.findIndex(imagem => imagem.sequencia === imagemProduto.sequencia);
        this.imagensProduto.splice(indexImagem, 1);
      });
  }

  ordenarImagensProduto(): void {
    this.loading2<void>(() => this.produtoService.ordenarImagensProduto(this.imagensProdutosOrdenados))
      .pipe(take(1))
      .subscribe(() => {
      });
  }

  onChangeArquivo(event) {
    const selectedFiles = <FileList>event.srcElement.files;
    this.imagemProduto = selectedFiles[0];

    if (this.imagemProduto.type !== 'image/jpeg' && this.imagemProduto.type !== 'image/png') {
      this.alert.warning("São suportados apenas imagens do tipo .jpeg, .jpg e .png");
      return;
    }

    if (this.imagemProduto) {
      this.loading2(() => this.produtoService.importarImagemProduto(this.imagemProduto, this.produto.idProduto))
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(response => {
          if (response && response['status'] === 200) {
            this.alert.success('Imagem do produto importada com sucesso!');
            let drEvent = $('#imagem-produto').dropify();
            drEvent = drEvent.data('dropify');
            drEvent.resetPreview();
            drEvent.clearElement();
            this.imagemProduto = null;

            var imagemProduto = new ImagemProdutoEntity()
            imagemProduto = response['body'];
            imagemProduto.imagem = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + imagemProduto.arrayImagem)

            this.imagensProduto.push(imagemProduto)
          }
        });
    }
    else
      this.alert.warning("Nenhum arquivo foi selecionado.");
  }

  voltar(): void {
    this.salvarCancelar.emit();
  }

  trocarAba(codigoAba: number): void {
    if (this.produto.idProduto) {
      if (codigoAba === 1) {
        (<any>$("#tab0")).addClass("active");
        (<any>$("#tabContent0")).addClass("active");
        (<any>$("#tab1")).removeClass("active");
        (<any>$("#tabContent1")).removeClass("active");
      }
      if (codigoAba === 2) {
        (<any>$("#tab0")).removeClass("active");
        (<any>$("#tabContent0")).removeClass("active");
        (<any>$("#tab1")).addClass("active");
        (<any>$("#tabContent1")).addClass("active");

        this.consultarImagensProduto();
      }
    }
  }

  avancarParaImagens() {
    (<any>$("#tab0")).removeClass("active");
    (<any>$("#tabContent0")).removeClass("active");
    (<any>$("#tab1")).addClass("active");
    (<any>$("#tabContent1")).addClass("active");
  }

  abrirImagen(imagem: string) {
    this.imegemProduto = imagem;
    this.modal = this.modalService.open(this.modalImagemProduto, { ariaLabelledBy: 'modal-dest', windowClass: "fichaModal", size: 'lg' });
  }

  salvar() {
    if (this.produto.idProduto > 0) {
      this.loading2<void>(() => this.produtoService.alterarProduto(this.produto))
        .pipe(take(1))
        .subscribe(() => {
          this.alert.success("Produto alterado com sucesso");
          this.voltar()
        });
    } else {
      this.loading2<number>(() => this.produtoService.inserirProduto(this.produto))
        .pipe(take(1))
        .subscribe((idProduto: number) => {
          this.produto.idProduto = idProduto
          this.alert.success("Produto inserido com sucesso! Agora adicione as imagens!");
          this.avancarParaImagens();
        });
    }
  }

  ngAfterViewInit() {
    let phElement = this.placeholder.element.nativeElement;

    phElement.style.display = 'none';
    phElement.parentElement.removeChild(phElement);
  }

  dragMoved(e: CdkDragMove) {
    let point = this.getPointerPositionOnPage(e.event);

    this.listGroup._items.forEach(dropList => {
      if (__isInsideDropListClientRect(dropList, point.x, point.y)) {
        this.activeContainer = dropList;
        return;
      }
    });
  }

  dropListDropped() {
    if (!this.target)
      return;

    let phElement = this.placeholder.element.nativeElement;
    let parent = phElement.parentElement;

    phElement.style.display = 'none';

    parent.removeChild(phElement);
    parent.appendChild(phElement);
    parent.insertBefore(this.source.element.nativeElement, parent.children[this.sourceIndex]);

    this.target = null;
    this.source = null;

    if (this.sourceIndex != this.targetIndex)
      moveItemInArray(this.imagensProduto, this.sourceIndex, this.targetIndex);

    this.imagensProdutosOrdenados = this.imagensProduto;
    this.ordenarImagensProduto();
  }

  dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList) => {
    if (drop == this.placeholder)
      return true;

    if (drop != this.activeContainer)
      return false;

    let phElement = this.placeholder.element.nativeElement;
    let sourceElement = drag.dropContainer.element.nativeElement;
    let dropElement = drop.element.nativeElement;

    let dragIndex = __indexOf(dropElement.parentElement.children, (this.source ? phElement : sourceElement));
    let dropIndex = __indexOf(dropElement.parentElement.children, dropElement);

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;

      phElement.style.width = sourceElement.clientWidth + 'px';
      phElement.style.height = sourceElement.clientHeight + 'px';

      sourceElement.parentElement.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = drop;

    phElement.style.display = '';
    dropElement.parentElement.insertBefore(phElement, (dropIndex > dragIndex
      ? dropElement.nextSibling : dropElement));

    this.placeholder._dropListRef.enter(drag._dragRef, drag.element.nativeElement.offsetLeft, drag.element.nativeElement.offsetTop);
    return false;
  }

  /** Determines the point of the page that was touched by the user. */
  getPointerPositionOnPage(event: MouseEvent | TouchEvent) {
    // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
    const point = __isTouchEvent(event) ? (event.touches[0] || event.changedTouches[0]) : event;
    const scrollPosition = this.viewportRuler.getViewportScrollPosition();

    return {
      x: point.pageX - scrollPosition.left,
      y: point.pageY - scrollPosition.top
    };
  }
}

function __indexOf(collection, node) {
  return Array.prototype.indexOf.call(collection, node);
};

/** Determines whether an event is a touch event. */
function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return event.type.startsWith('touch');
}

function __isInsideDropListClientRect(dropList: CdkDropList, x: number, y: number) {
  const { top, bottom, left, right } = dropList.element.nativeElement.getBoundingClientRect();
  return y >= top && y <= bottom && x >= left && x <= right;
}
