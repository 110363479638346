export class FichaProdutoEntity {
  codigoProduto: string;
  idProduto: number;
  descricao: string;
  largura: number;
  altura: number;
  comprimento: number;
  peso: number;
  cubagemPalete: number;
  cubagemRodoviario: number;
  cubagem: number;
  dataEntrada: Date;
  fornecedor: string;
  qtdEstoqueMinimo: number;
  valorUnitario: number;
  imagens: string[];
  grupoProduto: string;
  linhaProduto: string;
  dataValidade: Date;
}
