// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  urlImageKit: "http://201.46.27.82:70/ImgImperium/",
  baseUrl: "https://an4f6rcmq8.execute-api.sa-east-1.amazonaws.com/Prod/"
};

