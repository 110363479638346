import { Component, Input, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { FichaProdutoEntity } from '../../entities/ficha-produto.entity';
import { ProdutoService } from '../../produto/produto.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';

@Component({
  selector: 'app-detalhe-produto',
  templateUrl: './detalhe-produto.component.html',
  styleUrls: ['./detalhe-produto.component.css']
})
export class DetalheProdutoComponent extends ComponentBase implements OnInit {

  @Input() idProduto: number = 0;
  fichaProduto: FichaProdutoEntity;

  constructor(
    private produtoService: ProdutoService,
    private session: SessionService
  ) { super(); }

  ngOnInit() {
    this.fichaProduto = new FichaProdutoEntity();
    this.fichaProduto.imagens = [];
    this.loadingGestores<FichaProdutoEntity>(this.produtoService, "consultarFichaProduto", [this.session.usuario['idCliente'], this.idProduto])
      .pipe(take(1))
      .subscribe(resp => {
        this.fichaProduto = resp;
      });
  }

}
