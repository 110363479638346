import { MediaMatcher } from '@angular/cdk/layout';
import {
  AfterViewInit, ChangeDetectorRef, Component, OnDestroy
} from '@angular/core';
import * as $ from 'jquery';
import { MenuItems } from '../../shared/menu-items/menu-items';

import { Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: []
})
export class FullComponent extends ComponentBase implements OnDestroy, AfterViewInit {
  mobileQuery: MediaQueryList;
  dir = 'ltr';
  green: boolean;
  blue: boolean;
  dark: boolean;
  minisidebar: boolean;
  boxed: boolean = true;
  danger: boolean;
  showHide: boolean;
  sidebarOpened;
  term: string;

  public config: PerfectScrollbarConfigInterface = {};
  private _mobileQueryListener: () => void;

  constructor(
    private session: SessionService,
    protected alert: AlertService,
    protected router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems
  ) {
    super();
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    if (this.session.termoPesquisa)
      this.term = this.session.termoPesquisa;
  }

  ngOnDestroy(): void {
    //this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngAfterViewInit() {
    // This is for the topbar search
    (<any>$('.srh-btn, .cl-srh-btn')).on('click', function () {
      (<any>$('.app-search')).toggle(200);
    });

    // This is for the megamenu
  }

  searchTerm() {
    if (!this.term || this.term.length == 0) {
      this.session.termoPesquisa = null;
      window.location.href = "/produtos";
      return;
    }

    if (this.term.length < 3) {
      this.alert.warning("Informe pelo menos 3 caracteres para a pesquisa!");
      return;
    }

    this.session.termoPesquisa = this.term;
    location.href = "/produtos?term=" + this.term;
  }

  // Mini sidebar
}
