import { ProdutoCotacaoFreteRequestEntity } from "./produto-cotacao-frete-request.entity";

export class GerarCotacaoRequestEntity {
  IdCliente: number;
  CodigoModal: string;
  CodigoDestinatario: number;
  CidadeDestino: number;
  Produtos: ProdutoCotacaoFreteRequestEntity[];
  CepDestino: string;
}
