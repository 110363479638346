export class AtualizarDadosPessoaisRequestEntity{
  idPessoa:number;
  nomeCompleto: string;
  email: string;
  telefone: string;
  cep: string;
  endereco: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  uf: string;
  codigoIbge:string;
}
