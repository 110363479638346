import { Component, OnInit, Inject } from '@angular/core';
import { ComponentBase } from '../../shared/component-base';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertService } from '../../shared/alert.service';
import { Router } from '@angular/router';
import { AnonymousService } from '../anonymous.services';
import { take } from 'rxjs/internal/operators/take';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-recuperar-senha',
  templateUrl: './recuperar-senha.component.html',
  styleUrls: ['./recuperar-senha.component.css']
})
export class RecuperarSenhaComponent extends ComponentBase implements OnInit {

  form: FormGroup;

  constructor(
    private http: HttpClient,    
    private anonymousService: AnonymousService,
    private fb: FormBuilder,
    protected alert: AlertService,
    protected router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.form = this.fb.group({
      usuario: [null, Validators.compose([Validators.required])]
    });
  }

  onSubmit() {
    //this.loading<any>(this.anonymousService, "recuperarSenha", ['adriano'])
    this.anonymousService.solicitarRecuperacaoSenha(this.form.controls['usuario'].value)
      .pipe(take(1))
      .subscribe(resp => {
        this.alert.info("Solicitação realizada com sucesso. Um email contendo instruções para alteração da sua senha foi enviado para seu e-mail.")
          .then((res) => {
            this.router.navigate(['/']);
          });
      });
  }
}
