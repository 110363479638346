import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DivisaoComponent } from './divisao.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { LayoutModule } from '../layout/layout.module';
import { DivisaoConfiguracaoComponent } from './divisao-configuracao/divisao-configuracao.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    LayoutModule
  ],
  declarations: [DivisaoComponent, DivisaoConfiguracaoComponent],
  exports: [DivisaoComponent]
})
export class DivisaoModule { }
