import { UsuarioLogadoEntity } from "../usuario-logado.entity";

export class ConsultarPedidoRequestEntity {
  divisoesSelecionadas: number[];
  idPedido: number;
  codigoProduto: string;
  nomeProduto: string;
  statusPedido: number;
  uf: number;
  cidade: number;
  ordemSaida: number;
  nf: string;
  dtIni: Date;
  dtFim: Date;
  destinatario: string;
  usuarioLogado: UsuarioLogadoEntity;
  codigoCampanha: number;
  empresas:string;
}
