import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { CategoriaEntity } from '../../entities/catetoria.entity';
import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { RelatorioCategoriaService } from './relatorio-categoria.service';

@Component({
  selector: 'app-categoria',
  templateUrl: './categoria.component.html',
  styleUrls: ['./categoria.component.css']
})
export class CategoriaComponent extends ComponentBase implements OnInit {
  categoriaSelecionada: CategoriaEntity = new CategoriaEntity();
  categorias: CategoriaEntity[] = [];
  showIncluirAlterar: boolean = false;

  constructor(
    private categoriaRelatorioService: RelatorioCategoriaService,
    protected alert: AlertService,
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.listarCategorias();
  }

  listarCategorias() {
    this.loading2<CategoriaEntity[]>(() => this.categoriaRelatorioService.listarCategorias())
      .pipe(take(1))
      .subscribe(resp => {

        this.categorias = resp;
        if (resp.length > 0)
          this.refreshDataTable('dtCategorias');
        else
          this.clearDataTable('dtCategorias');
      });
  }

  alterarCategoria(categoria: CategoriaEntity) {
    this.categoriaSelecionada = categoria;
    this.showIncluirAlterar = true;
  }

  inserirCategoria() {
    this.showIncluirAlterar = true;
    this.categoriaSelecionada = new CategoriaEntity();
  }

  cancelarInserirCategoria() {
    this.showIncluirAlterar = false;
  }

  salvar() {
    if (this.categoriaSelecionada.idCategoria > 0) {
      this.loading2<CategoriaEntity[]>(() => this.categoriaRelatorioService.alterarCategoria(this.categoriaSelecionada))
        .pipe(take(1))
        .subscribe(() => {
          this.alert.success("Categoria alterada com sucesso");
          this.showIncluirAlterar = false;
          this.listarCategorias();
        });
    } else {
      this.loading2<CategoriaEntity[]>(() => this.categoriaRelatorioService.inserirCategoria(this.categoriaSelecionada))
        .pipe(take(1))
        .subscribe(() => {
          this.alert.success("Categoria inserida com sucesso");
          this.showIncluirAlterar = false;
          this.listarCategorias();
        });
    }
  }

  excluirCategoria(categoria: CategoriaEntity, idx: number) {
    this.alert.confirm("Tem certeza que deseja excluir essa categoria?")
      .then(val => {
        if (val.value) {
          this.loading2<CategoriaEntity[]>(() => this.categoriaRelatorioService.excluirCategoria(categoria.idCategoria))
            .pipe(take(1))
            .subscribe(() => {
              this.categorias.splice(idx, 1);
              this.refreshDataTable('dtCategorias');
            });
        }
      });
  }
}
