import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConsultarPedidoDeclaracaoTransporteResponseEntity } from '../../entities/Responses/consultar-pedido-declaracao-transporte-response.entity';
import { ComponentBase } from '../../shared/component-base';

@Component({
  selector: 'app-visualizar-declaracao-produto',
  templateUrl: './visualizar-declaracao-produto.component.html',
  styleUrls: ['./visualizar-declaracao-produto.component.css']
})
export class VisualizarDeclaracaoProdutoComponent extends ComponentBase implements OnInit {

  permiteImprimir: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {

  }

  onDeclaracaoPreenchida(response: ConsultarPedidoDeclaracaoTransporteResponseEntity) {
    this.permiteImprimir = (response != null && !(!response.numeroDeclaracao));
  }

  imprimir() {
    const idDeclaracao = this.activatedRoute.snapshot.paramMap.get('id');
    window.open("/pedidos/imprimir-declaracao/" + idDeclaracao, "_blank");
  }

  voltar() {
    window.history.back();
  }
}
