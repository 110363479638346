import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { environment } from '../../environments/environment';
import { IndicadorEntity } from '../entities/indicador-entity';
import { ServiceBase } from "../shared/service-base";

@Injectable({
  providedIn: 'root'
})
export class IndicadorService extends ServiceBase {

  constructor(
    private http: HttpClient,

  )
  { super(); }

  consultarIndicador(idCilente: number): Observable<IndicadorEntity[]> {
    return this.http.get<IndicadorEntity[]>(`${environment.baseUrl}api/indicador/consultarindicador/${idCilente}`);
  }
}
