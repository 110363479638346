import { Component, EventEmitter, forwardRef, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { ProdutoConstant } from '../../Constants/produto.constant';
import { ProdutoEntity } from '../../entities/produto.entity';
import { CarrinhoService } from '../../layout/masterpage/topbar/nav-bar/carrinho/carrinho.service';
import { AlertService } from '../../shared/alert.service';
import { ComponentBase } from '../../shared/component-base';
import { SessionService } from '../../shared/session.service';
import { ProdutoClienteEntity } from '../entities/produto-cliente.entity';
import { ProdutoService } from '../produto.service';

const noop = () => {
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CardProdutoComponent),
  multi: true
};


@Component({
  selector: 'app-card-produto',
  templateUrl: './card-produto.component.html',
  styleUrls: ['./card-produto.component.css'],
  providers: [
    CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR
  ]
})
export class CardProdutoComponent extends ComponentBase implements OnInit, ControlValueAccessor {

  constructor(
    public session: SessionService,
    private carrinho: CarrinhoService,
    private produtoService: ProdutoService,
    protected alert: AlertService,
    private sanitizer: DomSanitizer
  ) { super(); }

  ngOnInit() {
    super.ngOnInit();
  }

  @Output() detailClick: EventEmitter<ProdutoEntity> = new EventEmitter();
  @Output() transferirDivisaoClick: EventEmitter<ProdutoEntity> = new EventEmitter();
  @Output() transferirLocalizacaoClick: EventEmitter<ProdutoEntity> = new EventEmitter();

  //Placeholders for the callbacks which are later providesd
  //by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: ProdutoEntity[]) => void = noop;
  produtos: ProdutoEntity[];
  produtosValue: ProdutoEntity[];
  paginaAtual: number;
  paginas: number[];

  //From ControlValueAccessor interface
  // Evento acionado quando ocorre modificação no DOM.
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  //From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  //From ControlValueAccessor interface
  // Evento acionado quando ocorre modificação na model
  writeValue(value: ProdutoEntity[]) {
    if (value !== this.produtosValue) {
      this.produtosValue = value;
      console.log(value);
      this.paginaAtual = 1;
      this.getProdutosPagina(this.paginaAtual);
      if (!this.produtosValue)
        this.paginas = [];
      else
        this.paginas = Array(Math.ceil(this.produtosValue.length / 10)).fill(0).map((x, i) => i + 1);

      this.onChangeCallback(this.produtos);
    }
    this.onTouchedCallback();
  }

  getProdutosPagina(pagina: number) {
    if (!this.produtosValue)
      this.produtos = [];
    else
      this.produtos = this.produtosValue.slice((pagina - 1) * 100, pagina * 100);

    this.paginaAtual = pagina;
  }

  getCorStatus(status: number): string {
    return ProdutoConstant.coresStatusProduto[status - 1].name;
  }

  getTituloStatus(status: number): string {
    return ProdutoConstant.statusProduto[status].name;
  }

  getImagemProduto(codigoProduto: string): string {
    return this.produtoService.getImagemProduto(codigoProduto);
  }

  consultarImagemProduto(produto: ProdutoClienteEntity): void {
    console.log(produto);
    this.loading2<string>(() => this.produtoService.consultarImagemProduto(produto.idProduto))
      .pipe(take(1))
      .subscribe(resp => {
        const sanitizer = this.sanitizer;
        if (resp) {
          produto.imagem = sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + resp);
        }
      });
  }

  addProdutoCarrinho(produto: ProdutoEntity) {
    //
    // if (this.session.empresa) {
    //   if (this.session.empresa.produtoESL) {
    //     var divisao = this.session.consultarDivisaoPorNome(produto.nomeDivisao);

    //     if (!divisao) {
    //       this.alert.error("Não foi possível consultar a divisão atual. Tente novamente em alguns instantes.");
    //       return;
    //     }

    //     if (divisao.fazPedidoWeb == false) {
    //       this.alert.warning(`Seu usuário não pode efetuar pedidos para produtos da divisão ${produto.nomeDivisao}. Contate o seu coordenador.`);
    //       return;
    //     }
    //   }
    // }

    this.carrinho.adicionarProduto(produto);
  }

  detail(produto: ProdutoEntity) {
    if (!this.exibirFiltro())
      return;
    this.detailClick.emit(produto);
  }

  permiteVisualizarSaldo(produto: ProdutoEntity): boolean {
    return this.possuiAcessoRecurso(23);
  }

  transferirDivisao(produto: ProdutoEntity) {
    this.transferirDivisaoClick.emit(produto);
  }

  transferirLocalizacao(produto: ProdutoEntity) {
    this.transferirLocalizacaoClick.emit(produto);
  }

  anterior() {
    this.getProdutosPagina(--this.paginaAtual);
  }

  proximo() {
    this.getProdutosPagina(++this.paginaAtual);
  }
}
