import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LayoutModule } from '../layout/layout.module';
import { ProdutoModule } from '../produto/produto.module';
import { ProdutosComponent } from './produtos.component';
import { PainelInformacaoModule } from '../painel-informacao/painel-informacao.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ProdutoModule,
    LayoutModule,
    PainelInformacaoModule
  ],
  declarations: [ProdutosComponent],
  exports: [ProdutosComponent]
})
export class ProdutosModule { }
