import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProdutosComponent } from '../produtos/produtos.component';
import { AssociaProdutoKitComponent } from './associa-produto-kit/associa-produto-kit.component';
import { CadastroProdutoComponent } from './cadastro-produto/cadastro-produto.component';
import { ConfiguracaoKitProdutoFormularioComponent } from './configuracao-kit-produto/configuracao-kit-produto-formulario/configuracao-kit-produto-formulario.component';
import { ConfiguracaoKitProdutoComponent } from './configuracao-kit-produto/configuracao-kit-produto.component';
import { EntradaProdutoSemNfComponent } from './entrada-produto/entrada-produto-sem-nf/entrada-produto-sem-nf.component';
import { EntradaProdutoComponent } from './entrada-produto/entrada-produto.component';
import { ProdutoBaixaUtilizacaoComponent } from './produto-baixa-utilizacao/produto-baixa-utilizacao.component';
import { ProdutoDetalheComponent } from './produto-detalhe/produto-detalhe.component';
import { ProdutoMovimentoComponent } from './produto-movimento/produto-movimento.component';
import { ProdutoOportunidadeComponent } from './produto-oportunidade/produto-oportunidade.component';
import { ProdutoSolicitacaoEstoqueGestaoComponent } from './produto-solicitacao-estoque-gestao/produto-solicitacao-estoque-gestao.component';
import { ProdutoSolicitacaoEstoqueComponent } from './produto-solicitacao-estoque/produto-solicitacao-estoque.component';
import { ProdutoTagsComponent } from './produto-tags/produto-tags.component';
import { ProdutoTransferenciaSaldoComponent } from './produto-transferencia-saldo/produto-transferencia-saldo.component';
import { SolicitacaoKitProdutoComponent } from './solicitacao-kit-produto/solicitacao-kit-produto.component';
import { DivisaoConfiguracaoComponent } from '../divisao/divisao-configuracao/divisao-configuracao.component';

const routes: Routes = [
  {
    path: 'estoque',
    children: [
      {
        path: 'transferir-saldo-divisao',
        component: ProdutoTransferenciaSaldoComponent,
        data: { label: 'Estoque Virtual da Divisão', url: 'transferir-saldo-divisao', middlePath: 'Estoque', }
      },
      {
        path: 'gerenciar-solicitacao-produto-estoque',
        component: ProdutoSolicitacaoEstoqueGestaoComponent,
        data: { label: 'Gerenciar Solicitação de Produtos', url: 'gerenciar-solicitacao-produto-estoque', middlePath: 'Estoque' }
      },
      {
        path: 'minha-solicitacao-produto-estoque',
        component: ProdutoSolicitacaoEstoqueGestaoComponent,
        data: { label: 'Minhas Solicitações de Produtos', url: 'minha-solicitacao-produto-estoque', middlePath: 'Estoque' }
      },
      {
        path: 'solicitar-produto-estoque',
        component: ProdutoSolicitacaoEstoqueComponent,
        data: { label: 'Solicitação de Produtos', url: 'solicitar-produto-estoque', middlePath: 'Estoque' }
      },
      {
        path: 'transferencia-divisao',
        component: ProdutosComponent,
        data: { label: 'Transferência de produtos entre divisões', url: 'transferencia-divisao', middlePath: 'Estoque', }
      },
      {
        path: 'transferencia-localizacao',
        component: ProdutosComponent,
        data: { label: 'Transferência de produtos entre localizacoes', url: 'transferencia-localizacao', middlePath: 'Estoque', }
      },
      {
        path: 'ajuste-estoque',
        component: ProdutosComponent,
        data: { label: 'Manutenção do estoque', url: 'ajuste-estoque', middlePath: 'Estoque', }
      },
      {
        path: 'configurar-divisao',
        component: DivisaoConfiguracaoComponent,
        data: { label: 'Configurar Divisões', url: 'configurar-divisao', middlePath: 'Estoque', }
      },
    ]
  },
  {
    path: 'produtos',
    children: [
      {
        path: '',
        component: ProdutosComponent,
        data: { label: 'Produtos', url: '/produtos', middlePath: '' }
      },
      {
        path: 'catalogo',
        component: ProdutosComponent,
        data: { label: 'Catálogo de Produtos', url: '/produtos', middlePath: 'Produtos' }
      },
      {
        path: 'solicitar-kit',
        component: SolicitacaoKitProdutoComponent,
        data: { label: 'Solicitar Kit de Produtos', url: '/solicitar-kit', middlePath: 'Produtos' }
      },
      {
        path: 'configurar-kit',
        component: ConfiguracaoKitProdutoComponent,
        data: { label: 'Kits de Produtos', url: '/configurar-kit', middlePath: 'Produtos' }
      },
      {
        path: 'configurar-kit/novo',
        component: ConfiguracaoKitProdutoFormularioComponent,
        data: { label: 'Novo Kit de Produtos', url: '/novo-kit', middlePath: 'Produtos' }
      },
      {
        path: 'configurar-kit/editar/:id',
        component: ConfiguracaoKitProdutoFormularioComponent,
        data: { label: 'Editar Kit de Produtos', url: '/editar-kit', middlePath: 'Produtos' }
      },
      {
        path: 'configurar-kit/associar-produto/:id',
        component: AssociaProdutoKitComponent,
        data: { label: 'Associação entre produtos no Kit', url: '/associar-produto', middlePath: 'Produtos' }
      },
      {
        path: 'entrada-produto',
        component: EntradaProdutoComponent,
        data: { label: 'Entrada de Produtos', url: '/entrada-produto', middlePath: 'Produtos' }
      },
      {
        path: 'nova-entrada-produto',
        component: EntradaProdutoSemNfComponent,
        data: { label: 'Entrada de Produtos', url: '/entrada-produto', middlePath: 'Produtos' }
      },
      {
        path: 'movimentacao/:cliente/:id',
        component: ProdutoMovimentoComponent,
        data: { label: 'Movimentação de Produtos', url: '/movimentacao', middlePath: 'Produtos' }
      },
      {
        path: 'gerenciar-produto',
        component: CadastroProdutoComponent,
        data: { label: 'Gerenciar produtos', url: '/gerenciar-produto', middlePath: 'Produtos' }
      },
      {
        path: 'baixa-utilizacao',
        component: ProdutoBaixaUtilizacaoComponent,
        data: { label: 'Produtos a mais de 90 dias sem movimentação', url: '/baixa-utilizacao', middlePath: 'Relatórios' }
      },
      {
        path: 'oportunidades',
        component: ProdutoOportunidadeComponent,
        data: { label: 'Oportunidade Única', url: '/baixa-utilizacao', middlePath: 'Relatórios' }
      },
      {
        path: 'produto-detalhe/:id',
        component: ProdutoDetalheComponent,
        data: { label: 'Detalhes', url: 'detalhe-produto', middlePath: 'Produtos' }
      },
      {
        path: 'tags',
        component: ProdutoTagsComponent,
        data: { label: 'Associar Características Adicionais', url: 'tags', middlePath: 'Produtos' }
      }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule]
})
export class ProdutoRoutingModule { }
