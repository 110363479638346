import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { RelatorioRecebimentosRequestEntity } from '../../../entities/Requests/relatorio-recebimentos-request.entity';
import { RelatorioRecebimentosResponseEntity } from '../../../entities/Responses/relatorio-recebimentos-response.entity';
import { ComponentBase } from '../../../shared/component-base';
import { SessionService } from '../../../shared/session.service';
import { RelatorioService } from '../../relatorios.service';

@Component({
  selector: 'app-filtro-recebimentos',
  templateUrl: './filtro-recebimentos.component.html',
  styleUrls: ['./filtro-recebimentos.component.css']
})
export class FiltroRecebimentosComponent extends ComponentBase implements OnInit {

  consulta: RelatorioRecebimentosRequestEntity;
  @Output() onFilter: EventEmitter<RelatorioRecebimentosResponseEntity[]> = new EventEmitter();

  constructor(
    private relatorioService: RelatorioService,
    protected session: SessionService
  ) {
    super();
  }

  ngOnInit() {
    this.consulta = new RelatorioRecebimentosRequestEntity();
  }

  limpar() {
    this.consulta = new RelatorioRecebimentosRequestEntity();
  }

  pesquisar() {

    this.consulta.cliente = this.session.usuario['idCliente'];
    this.loadingGestores<RelatorioRecebimentosResponseEntity[]>(this.relatorioService, "relatorioRecebimentos", [this.consulta])
      .pipe(take(1))
      .subscribe(resp => {

        this.onFilter.emit(resp);
      });
  }
}
