import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../../../../shared/alert.service';
import { ComponentBase } from '../../../../../shared/component-base';
import { SessionService } from '../../../../../shared/session.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent extends ComponentBase implements OnInit {
  usuario: string;
  email: string;
  permiteTrocarEmpresa: boolean;
  constructor(
    private session: SessionService,
    protected alert: AlertService,
    protected route: Router
  ) {
    super();
  }

  ngOnInit() {
    this.usuario = this.session.usuario.nomePessoa.toLowerCase();
    this.email = this.session.usuario.email;
    this.permiteTrocarEmpresa = this.session.usuario.empresas.length > 1 && this.possuiAcessoRecurso(100);
  }

  logoff() {
    this.alert.confirm("Tem deseja que deseja encerrar o aplicativo?")
      .then((res) => {
        if (res.value) {
          this.session.clear();
          window.location.href = "/";
        }
      });
  }

  alterarSenha() {
    this.route.navigate(['recuperarsenha'])
  }

  selecionarEmpresa() {
    this.session.empresa = null;
    this.session.urlReturn = window.location.href;
    this.route.navigate(['empresa/selecionar']);
  }

  alterarDadosPessoais() {
    this.route.navigate(['alterar-dados-pessoais']);
  }
}
