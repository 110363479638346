import { Injectable } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { DistribuirSaldoVirtualRequestEntity } from '../entities/Requests/distrbuir-produto-saldo-virtual-request.entity';
import { IncluirEntradaProdutoSemNfRequestEntity } from '../entities/Requests/incluir-entrada-produto-sem-nf-request.entity';
import { ConsultarPedidoResponseEntity } from '../entities/Responses/consultar-pedido.response.entity';
import { DivisaoESLEntity } from '../entities/divisao-esl.entity';
import { EmpresaEntity } from '../entities/empresa.entity';
import { FichaProdutoEntity } from '../entities/ficha-produto.entity';
import { ProdutoEntity } from '../entities/produto.entity';
import { UsuarioLogadoEntity } from '../entities/usuario-logado.entity';
import { FacetProdutoEntity } from '../produto/entities/facet-produto.entity';
import { ConsultarProdutosRelacionadosResponseEntity } from '../produto/entities/response/consultar-produto-relacionados-response.entity';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  constructor() { }

  set teste(obj: any[]) {
    localStorage.setItem('teste', JSON.stringify(obj));
  }

  get teste(): any[] {
    return JSON.parse(localStorage.getItem('teste'));
  }

  get usuario(): UsuarioLogadoEntity {
    return JSON.parse(localStorage.getItem('usuario'));
  }

  set usuario(usuario: UsuarioLogadoEntity) {
    localStorage.setItem('usuario', JSON.stringify(usuario));
  }

  get divisoes(): DivisaoESLEntity[] {
    var divisoes: DivisaoESLEntity[] = JSON.parse(localStorage.getItem('divisoes'));

    // GHP Brasilit
    if (this.ajusteBrasilit) {
      return divisoes.filter(x => x.empresa.toUpperCase().indexOf("BRASILIT") > -1);
    }
    if (this.empresa)
      return divisoes.filter(x => x.idEmpresa === this.empresa.idCliente);

    return divisoes;
  }

  set divisoes(divisoes: DivisaoESLEntity[]) {
    localStorage.setItem('divisoes', JSON.stringify(divisoes));
  }

  getIdDivisoes(): number[] {
    return this.divisoes.map<number>((divisao: DivisaoESLEntity) => divisao.idPessoaDivisao);
  }

  get isMaster(): boolean {
    return this.divisoes.findIndex(x => x.aprovaPedidoTerceiro || x.aprovaPedido) > -1;
  }

  get divisoesConsultaPedido(): DivisaoESLEntity[] {
    return this.divisoes;
  }

  get divisoesAprovaPedido(): DivisaoESLEntity[] {
    let ret = new Array<DivisaoESLEntity>();
    for (let x = 0; x < this.divisoes.length; x++) {
      if (this.divisoes[x].aprovaPedidoTerceiro)
        ret.push(this.divisoes[x]);
    }

    return ret;
  }

  get divisoesMaster(): DivisaoESLEntity[] {
    let ret = new Array<DivisaoESLEntity>();
    for (let x = 0; x < this.divisoes.length; x++) {
      if (this.divisoes[x].usuarioMaster)
        ret.push(this.divisoes[x]);
    }

    return ret;
  }

  get divisoesConsultaAprovaPedido(): DivisaoESLEntity[] {
    let ret = new Array<DivisaoESLEntity>();
    for (let x = 0; x < this.divisoes.length; x++) {
      if (this.divisoes[x].aprovaPedidoTerceiro && this.divisoes[x].visualizaPedidoTerceiros)
        ret.push(this.divisoes[x]);
    }

    return ret;
  }

  set pathImages(pathImages: string) {
    localStorage.setItem('pathImages', pathImages);
  }

  get pathImages(): string {
    return localStorage.getItem('pathImages');
  }

  get logoClientes(): string {
    return localStorage.getItem('logoClientes');
  }

  set logoClientes(logoClientes: string) {
    localStorage.setItem('logoClientes', logoClientes);
  }

  set pathImagesPDF(pathImagesPDF: string) {
    localStorage.setItem('pathImagesPDF', pathImagesPDF);
  }

  get pathImagesPDF(): string {
    return localStorage.getItem('pathImagesPDF');
  }

  set logoClientesPDF(logoClientesPDF: string) {
    localStorage.setItem('logoClientesPDF', logoClientesPDF);
  }

  get logoClientesPDF(): string {
    return localStorage.getItem('logoClientesPDF');
  }

  clear(): void {
    const notificacoes = this.notificacoesOcultas;
    localStorage.clear();
    this.notificacoesOcultas = notificacoes;
  }

  consultarDivisaoPorNome(nomeDivisao: string): DivisaoESLEntity {
    return this.divisoes.find(x => x.nomeDivisao === nomeDivisao);
  }

  get logoEmpresaCliente(): string {
    return localStorage.getItem('logoEmpresaCliente');
  }

  set logoEmpresaCliente(logoEmpresaCliente: string) {
    localStorage.setItem('logoEmpresaCliente', logoEmpresaCliente);
  }

  get empresa(): EmpresaEntity {
    return JSON.parse(localStorage.getItem('empresa'));
  }

  set empresa(empresa: EmpresaEntity) {
    var usuario = { ...this.usuario };
    if (empresa)
      usuario.idCliente = empresa.idCliente;
    else
      usuario.idCliente = null;
    this.usuario = usuario;
    localStorage.setItem('empresa', JSON.stringify(empresa));
  }

  getIdsEmpresa(): string {
    if (this.empresa)
      return this.empresa.idCliente.toString();

    return this.usuario.idsEmpresas;
  }


  doSelecionado(pedido: ConsultarPedidoResponseEntity) {
    localStorage.setItem('pedidoSelecionado', JSON.stringify(pedido));
  }

  get pedidoSelecionado(): ConsultarPedidoResponseEntity {
    return JSON.parse(localStorage.getItem('pedidoSelecionado'));
  }

  set pedidoSelecionado(pedido: ConsultarPedidoResponseEntity) {
    localStorage.setItem('pedidoSelecionado', JSON.stringify(pedido));
  }

  get data(): any {
    return JSON.parse(localStorage.getItem('data'));
  }

  set ajusteBrasilit(ajuste: boolean) {
    localStorage.setItem('ajusteBrasilit', ajuste.toString());
  }

  get ajusteBrasilit(): boolean {
    if (!this.empresa)
      return false;

    return localStorage.getItem('ajusteBrasilit') === "true" && this.empresa.idCliente === 2876;
  }

  possuiAcessoRecurso(recurso: number): boolean {

    if (this.usuario.idPessoa === 19660)
      return true;

    if (this.empresa && !this.usuario.acessoNovo)
      return this.usuario.recursos.findIndex(x => x.idRecurso === recurso && x.idCliente === this.empresa.idCliente) > -1;

    return this.usuario.recursos.findIndex(x => x.idRecurso === recurso) > -1;
  }

  getIdsDivisoes(): string {
    if (!this.divisoes)
      return "";

    let result = "";
    for (let x = 0; x < this.divisoes.length; x++)
      result = result + `${this.divisoes[x].idPessoaDivisao},`;

    return result.substring(0, result.length - 1);
  }

  get produtosSelecionados(): IncluirEntradaProdutoSemNfRequestEntity[] {
    return JSON.parse(localStorage.getItem("produtos-selecionados"));
  }

  set produtosSelecionados(produtos: IncluirEntradaProdutoSemNfRequestEntity[]) {
    localStorage.setItem("produtos-selecionados", JSON.stringify(produtos));
  }

  set distribuicaoProdutosSaldoVirtual(entity: DistribuirSaldoVirtualRequestEntity) {
    localStorage.setItem("distribuicaoProdutosSaldoVirtual", JSON.stringify(entity));
  }

  get distribuicaoProdutosSaldoVirtual(): DistribuirSaldoVirtualRequestEntity {
    return JSON.parse(localStorage.getItem("distribuicaoProdutosSaldoVirtual"));
  }

  set notificacoesOcultas(notificacoes: any[]) {
    localStorage.setItem("notificacoesOcultas", JSON.stringify(notificacoes));
  }

  get notificacoesOcultas(): any[] {
    return JSON.parse(localStorage.getItem("notificacoesOcultas"));
  }

  set produtoDetalhe(produto: ProdutoEntity) {
    if (!produto)
      localStorage.removeItem("produtoDetalhe");

    localStorage.setItem("produtoDetalhe", JSON.stringify(produto));
  }

  get produtoDetalhe(): ProdutoEntity {
    return JSON.parse(localStorage.getItem("produtoDetalhe"));
  }

  set fichaProdutoDetalhe(ficha: FichaProdutoEntity) {
    if (!ficha)
      localStorage.removeItem("fichaProdutoDetalhe");

    localStorage.setItem("fichaProdutoDetalhe", JSON.stringify(ficha));
  }

  get fichaProdutoDetalhe(): FichaProdutoEntity {
    return JSON.parse(localStorage.getItem("fichaProdutoDetalhe"));
  }

  set produtosRelacionados(produtos: ConsultarProdutosRelacionadosResponseEntity[]) {
    if (!produtos)
      localStorage.removeItem("produtosRelacionados");

    localStorage.setItem("produtosRelacionados", JSON.stringify(produtos));
  }

  get produtosRelacionados(): ConsultarProdutosRelacionadosResponseEntity[] {
    return JSON.parse(localStorage.getItem("produtosRelacionados"));
  }

  set facetProduto(facet: FacetProdutoEntity[]) {
    debugger;
    if (!facet)
      localStorage.removeItem("facetProduto");

    localStorage.setItem("facetProduto", JSON.stringify(facet));
  }

  get facetProduto(): FacetProdutoEntity[] {
    return JSON.parse(localStorage.getItem("facetProduto"));
  }

  set termoPesquisa(termo: string) {
    if (!termo)
      localStorage.removeItem("termoPesquisa");
    else
      localStorage.setItem("termoPesquisa", termo);
  }

  get termoPesquisa(): string {
    return localStorage.getItem("termoPesquisa");
  }

  set urlReturn(url: string) {
    if (!url)
      localStorage.removeItem("urlReturn");
    //else if (url.indexOf('empresa/selecionar') > -1)
    localStorage.setItem("urlReturn", url);
  }

  get urlReturn(): string {
    return localStorage.getItem("urlReturn");
  }

  set logoCliente(logo: SafeResourceUrl) {
    if (!logo)
      localStorage.removeItem("logoCliente");
    else
      localStorage.setItem("logoCliente", logo.toString());
  }

  get logoCliente(): SafeResourceUrl {
    return <SafeResourceUrl>(localStorage.getItem("logoCliente"));
  }
}
