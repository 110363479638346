import { ListaEntity } from "./lista.entity";

export class RelatorioFiltroEntity {
  codigoCampo: number;
  codigoRelatorio: number;
  nomeCampo: string;
  labelCampo: string;
  tipoCampo: string;
  qtdCaracteres: number;
  mascara: string;
  ordem: number;
  valor: any;
  defaultMask: string;
  lista: ListaEntity[];

  constructor(init?: Partial<RelatorioFiltroEntity>) {
    Object.assign(this, init);
  }
}
