import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-produto-estatistica',
  templateUrl: './produto-estatistica.component.html',
  styleUrls: ['./produto-estatistica.component.css']
})
export class ProdutoEstatisticaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
