import { Component, OnInit, ViewChild } from '@angular/core';
import { ProdutoEntity } from '../entities/produto.entity';
import { ProdutoComponent } from '../produto/produto.component';
import { AlertService } from '../shared/alert.service';
import { ComponentBase } from '../shared/component-base';
import { SessionService } from '../shared/session.service';

@Component({
  selector: 'app-produtos',
  templateUrl: './produtos.component.html',
  styleUrls: ['./produtos.component.css']
})
export class ProdutosComponent extends ComponentBase implements OnInit {

  carregamentoAutomatico: boolean;
  produtos: ProdutoEntity[] = [];

  @ViewChild(ProdutoComponent, { static: true }) produtoComponent: ProdutoComponent;


  constructor(
    private session: SessionService,
    protected alert: AlertService
  ) {
    super();
  }

  ngOnInit() {

    this.carregamentoAutomatico = window.location.href.indexOf("produtos/catalogo") === -1 && window.location.href.indexOf("estoque/transferencia-divisao") === -1;// && this.session.empresa && this.session.empresa.produtoESL;
    this.session.produtoDetalhe = null;
    this.session.fichaProdutoDetalhe = null;

    if (window.location.href.indexOf("term") === -1) {
      this.session.termoPesquisa = null;
    }
  }

  modoCompleto(): boolean {
    return !this.session.produtoDetalhe;
  }

  onFilterProducts(obj: any) {

    const produtos = obj.item2;

    // if (produtos.length === 0) {
    //   this.alert.warning("Nenhum produto foi localizado!");
    // }

     if (this.session.facetProduto.length === 0)
       this.session.facetProduto = obj.item1;

    this.produtos = produtos;
  }
}
