export class DetalhePedidoEntity {
  codigoPedido: number;
  idOrdemSaida: number;
  peso: number;
  cubagem: number;
  volumes: number;
  valorNF: number;
  dataPedido: Date;
  dataEmissao: Date;
  idPessoaDivisao: number;
  pessoaDivisao: string;
  dataPrevisaoEntrega: Date;
  dataEntrega: Date;
  recebedor: string;
  status: string;
  frete: number;
  observacao: string;
  codigoStatus: number;
  modal:string;
}
